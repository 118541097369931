import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ThirdwebProvider,
  // import the wallets you want
  metamaskWallet,
  walletConnect,
  coinbaseWallet,
  okxWallet,
  en,
} from "@thirdweb-dev/react";
import {
  // OpSepoliaTestnet,
  Base,
  BaseSepoliaTestnet,
} from "@thirdweb-dev/chains";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

//antd
import { ConfigProvider } from "antd";

// This is the chain your dApp will work on.
// Change this to the chain your app is built for.
// You can also import additional chains from `@thirdweb-dev/chains` and pass them directly.
let activeChain = Base;
activeChain.rpc.unshift(process.env.REACT_APP_RPC_URL);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ThirdwebProvider
    activeChain={activeChain}
    autoConnect
    autoConnectTimeout={5000}
    dAppMeta={{
      name: "MixNoggle",
      description: "MixNoggle",
      url: "https://mixnoggle.xyz",
      logoUrl: process.env.REACT_APP_S3_PREFIX + "/other/logo/logo2-black.png",
    }}
    clientId={process.env.REACT_APP_THIRDWEB_CLIENT_ID}
    locale={en()}
    supportedWallets={[
      walletConnect({
        projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
      }),
      metamaskWallet(),
      coinbaseWallet(),
      okxWallet(),
      // embeddedWallet({
      //   auth: {
      //     options: [
      //       "email",
      //       "google",
      //       "apple",
      //       "facebook",
      //     ],
      //   },
      // }),
    ]}
  >
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Silkscreen", sans-serif, "Apple Color Emoji"',
        },
        components: {
          Badge: {
            indicatorHeight: 30,
            textFontSize: "1.25rem",
          },
        },
      }}
    >
      <App />
    </ConfigProvider>
  </ThirdwebProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
