import React from "react";

const Accessory = ({ fill, stroke }) => {
  return (
    // <?xml version="1.0" encoding="UTF-8" standalone="no"?>
    // <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.00 0.00 1440.00 815.00"
    >
      <path
        fill={fill}
        d="
      M 724.65 335.19
      Q 743.52 336.47 762.19 337.77
      Q 762.73 337.81 763.15 337.46
      Q 766.24 334.88 766.56 330.40
      Q 769.16 294.26 771.79 257.23
      A 2.03 1.99 33.5 0 0 771.56 256.15
      C 769.30 251.98 769.20 245.62 772.67 241.84
      A 1.28 1.25 -23.9 0 0 773.02 240.97
      C 773.06 237.36 774.54 232.58 778.58 231.84
      Q 786.52 230.38 794.35 231.22
      Q 891.99 241.58 991.07 252.08
      Q 993.07 252.29 994.75 253.69
      Q 998.05 256.45 998.07 261.60
      Q 998.57 398.16 999.29 533.79
      C 999.31 537.70 998.03 542.95 993.72 543.99
      Q 993.13 544.13 988.26 544.15
      Q 894.63 544.47 801.00 544.79
      C 783.53 544.85 774.18 527.51 771.90 512.29
      Q 771.48 509.52 770.68 507.04
      Q 769.81 504.34 770.32 501.36
      Q 770.73 498.97 770.55 496.38
      Q 767.05 445.87 763.37 393.86
      Q 763.05 389.30 762.33 387.11
      C 760.92 382.81 757.61 378.12 752.61 377.79
      Q 740.08 376.97 728.32 376.48
      Q 727.78 376.45 727.35 376.77
      Q 724.73 378.74 723.60 380.85
      C 721.35 385.05 721.15 389.01 720.81 393.84
      Q 717.23 444.69 713.48 497.82
      C 712.64 509.77 712.12 517.19 707.81 526.24
      C 704.18 533.86 697.64 541.31 689.36 543.94
      Q 686.02 545.00 679.02 545.02
      Q 559.58 545.44 444.86 545.83
      Q 441.35 545.84 439.72 545.19
      C 435.58 543.55 433.54 538.47 433.56 534.27
      Q 434.25 378.76 434.86 223.20
      C 434.87 220.17 433.79 217.20 434.45 214.29
      C 435.77 208.46 433.50 202.61 438.56 197.97
      Q 440.30 196.37 442.75 196.18
      Q 446.32 195.90 449.85 195.34
      Q 452.27 194.96 455.61 195.31
      Q 579.14 208.42 702.28 221.47
      C 703.78 221.63 705.47 221.92 706.63 222.96
      Q 710.48 226.41 710.86 231.49
      Q 714.22 277.01 717.31 320.45
      C 717.56 323.97 717.65 327.34 719.01 330.48
      C 719.92 332.58 722.14 335.02 724.65 335.19
      Z
      M 671.94 252.38
      Q 671.31 251.99 670.57 251.92
      Q 572.28 242.62 475.68 233.46
      Q 474.04 233.31 473.14 234.73
      Q 470.72 238.58 470.70 243.44
      Q 470.26 372.07 469.90 500.00
      Q 469.88 506.21 474.65 510.19
      C 475.85 511.20 478.23 511.41 479.75 511.42
      Q 573.54 512.04 669.41 512.90
      Q 671.91 512.93 673.43 511.77
      Q 677.65 508.57 677.66 502.75
      Q 677.78 395.10 677.81 262.60
      Q 677.81 256.10 671.94 252.38
      Z
      M 970.16 290.74
      C 970.16 287.35 969.39 284.06 967.17 281.48
      Q 965.83 279.93 963.30 279.67
      Q 959.74 279.30 956.25 278.97
      Q 882.37 271.99 808.36 264.96
      Q 807.50 264.88 806.98 265.56
      C 805.16 267.95 804.57 270.19 804.58 273.30
      Q 804.83 385.32 804.99 502.95
      Q 804.99 503.49 804.93 504.02
      C 804.63 506.86 806.32 510.70 808.32 512.53
      Q 809.91 513.99 813.17 514.01
      Q 866.46 514.32 921.94 514.89
      Q 942.15 515.09 966.03 515.06
      Q 966.53 515.06 966.93 514.74
      Q 970.92 511.51 970.89 506.38
      Q 970.37 394.57 970.16 290.74
      Z"
      />
    </svg>
  );
};

export default Accessory;
