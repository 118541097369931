import { Grid, Tag } from "antd";
const { useBreakpoint } = Grid;

const T = () => {
  const screens = useBreakpoint();
  const ui = () => {
    return (
      <>
        Current break point:
        {Object.entries(screens)
          .filter((screen) => !!screen[1])
          .map((screen) => (
            <Tag color="blue" key={screen[0]}>
              {screen[0]}
            </Tag>
          ))}
      </>
    );
  };
  return [getCurrentSize(screens), ui];
};

const getCurrentSize = (breakpoints) => {
  // Check the breakpointsConfig object to determine the current screen size
  if (breakpoints.xxl) {
    return "xxl";
  } else if (breakpoints.xl) {
    return "xl";
  } else if (breakpoints.lg) {
    return "lg";
  } else if (breakpoints.md) {
    return "md";
  } else if (breakpoints.sm) {
    return "sm";
  } else if (breakpoints.xs) {
    return "xs";
  } else {
    // Default case, handle if needed
    return "sm";
  }
};

export default T;
