export default {
	black: {
		black: {
			black: 'https://my.spline.design/blackblackblack-aae65207df048bc0edd137d66e06af03',
			red: 'https://my.spline.design/blackblackred-bdd369aa0889aa7faded1a532994b567',
			rgb: 'https://my.spline.design/blackblackrgb-9e9f2edce9e46cc2ba761c2730535626',
		},
		red: {
			black: 'https://my.spline.design/blackredblack-e4077889e947d31c7095e994c9e2ae29',
			red: 'https://my.spline.design/blackredred-b846e2f26b4b927361b3ca0cd9f96466',
			rgb: 'https://my.spline.design/blackredrgb-8b49905fb421470c53234ad8c68d1489',
		},
		gold: {
			black: 'https://my.spline.design/blackgoldblack-872d3584d0b7d15f3786ac8234874786',
			red: 'https://my.spline.design/blackgoldred-7e36dffc43402b6fae8512d18d1085ee',
			rgb: 'https://my.spline.design/blackgoldrgb-f88b90bc4943139b580793dfce88f94d',
		},
	},
	red: {
		black: {
			black: 'https://my.spline.design/redblackblack-fec7820518d8db34981de30d101a6673',
			red: 'https://my.spline.design/redblackred-5cb3759e6f156614954dd112894951c5',
			rgb: 'https://my.spline.design/redblackrgb-21a6d39d63641f97ff65f31448a3504f',
		},
		red: {
			black: 'https://my.spline.design/redredblack-05ebf34f1bfc60a4a24fc58dd4f6ec0c',
			red: 'https://my.spline.design/redredred-e0866ed2acd5e41fc99ce3a67916788a',
			rgb: 'https://my.spline.design/redredrgb-9478d39c019012cf953acc699fd11073',
		},
		gold: {
			black: 'https://my.spline.design/redgoldblack-e6d7a0582484bd7a813c098109391a43',
			red: 'https://my.spline.design/redgoldred-1bc093c12ef3590f952208a814abc8e3',
			rgb: 'https://my.spline.design/redgoldrgb-302b54544e924d4466d7d853db9d0cd5',
		},
	},
	gold: {
		black: {
			black: 'https://my.spline.design/goldblackblack-bed9d02dd361882ec6142579e505d203',
			red: 'https://my.spline.design/goldblackred-9e0d4e6e8e0a080fcd58a696482b965d',
			rgb: 'https://my.spline.design/goldblackrgb-d24055b068cbb9aaf72a32fa27b2b5d6',
		},
		red: {
			black: 'https://my.spline.design/goldredblack-d8439ce89b3e4a5d7c134db398340187',
			red: 'https://my.spline.design/goldredred-1f37689aecf10001e0a527fa1de3e59e',
			rgb: 'https://my.spline.design/goldredrgb-f063f3d5fd288520acc31bc0cc3e7458',
		},
		gold: {
			black: 'https://my.spline.design/goldgoldblack-92b9c90020396e20626162d7999116cb',
			red: 'https://my.spline.design/goldgoldred-2b5b8c5402e2ca05b20a1362661a8a10',
			rgb: 'https://my.spline.design/goldgoldrgb-48780a45581b07ca6f70161e3d1ececd',
		},
	},
};
