import { useEffect, useState, useRef, useMemo } from "react";
import { useContract } from "@thirdweb-dev/react";
import { useHistory } from "react-router-dom";
import { useAddress } from "@thirdweb-dev/react";

import { IonPage, IonContent } from "@ionic/react";
import { Row, Col, Flex, Card, Modal } from "antd";
import { ExclamationCircleFilled, CloseOutlined } from "@ant-design/icons";
import Spline from "@splinetool/react-spline";
import { motion } from "framer-motion";
import useBreakPoint from "../hooks/useBreakPoint";
import FrameSVG from "../components/FrameSVG";
import AccessorySVG from "../components/AccessorySVG";
import LenseSVG from "../components/LenseSVG";
import BaseSVG from "../components/BaseSVG";

//hooks
import usePrice from "../hooks/usePrice";

//utils
import log from "../utils/log";
import { uuid } from "../utils/id";
import { sleep, getDiscountedPrice } from "../utils/general";

//Global State
import useStore from "../states/useStore";

import deepcopy from "deepcopy";
import { ethers } from "ethers";
import axios from "axios";

import "../styles/global.css";
import useLogin from "../hooks/useLogin";

const { confirm } = Modal;

const options = {
  frame: [
    {
      id: "black",
      title: "Black",
      type: "frame",
      src: `${process.env.REACT_APP_S3_PREFIX}/parts/frame/black-remove-dot.png`,
      selected: false,
    },
    {
      id: "red",
      title: "Red",
      type: "frame",
      src: `${process.env.REACT_APP_S3_PREFIX}/parts/frame/red-remove-dot.png`,
      selected: false,
    },
    {
      id: "honey",
      title: "Honey",
      type: "frame",
      src: `${process.env.REACT_APP_S3_PREFIX}/parts/frame/honey-remove-dot.png`,
      selected: false,
    },
  ],
  accessory: [
    {
      id: "black",
      title: "Black",
      type: "accessory",
      src: `${process.env.REACT_APP_S3_PREFIX}/parts/accessory/black.png`,
      selected: false,
    },
    {
      id: "red",
      title: "Red",
      type: "accessory",
      src: `${process.env.REACT_APP_S3_PREFIX}/parts/accessory/red.png`,
      selected: false,
    },
    {
      id: "honey",
      title: "Honey",
      type: "accessory",
      src: `${process.env.REACT_APP_S3_PREFIX}/parts/accessory/honey.png`,
      selected: false,
    },
  ],
  lense: [
    {
      id: "black",
      title: "Black",
      type: "lense",
      src: `${process.env.REACT_APP_S3_PREFIX}/parts/lense/black.png`,
      selected: false,
    },
    {
      id: "red",
      title: "Red",
      type: "lense",
      src: `${process.env.REACT_APP_S3_PREFIX}/parts/lense/red.png`,
      selected: false,
    },
    {
      id: "rgb",
      title: "RGB",
      type: "lense",
      src: `${process.env.REACT_APP_S3_PREFIX}/parts/lense/rgb.png`,
      selected: false,
    },
  ],
};

const T = () => {
  const history = useHistory();

  const [currentSize, BreakPoint] = useBreakPoint();

  // const [tab, setTab] = useState("frame");
  const [step, setStep] = useState(1);
  const [frame, setFrame] = useState("black");
  const [accessory, setAccessory] = useState("red");
  const [lense, setLense] = useState();
  const { items, addItem } = useStore();
  const [isModalLeft, setIsModalLeft] = useState(false);
  const [animating, setAnimating] = useState(false);

  const [parts, setParts] = useState([]);

  const modalRef = useRef();

  const zoomInCamera = async () => {
    log.debug("zoomInCamera");
    log.debug(modalRef);
    // transite state
    if (modalRef.current != null) {
      modalRef.current.emitEvent("keyDown", "glasses testing");
    }
  };

  const resetCamera = async () => {
    log.debug("resetCamera");

    // transite state
    if (modalRef.current != null) {
      modalRef.current.emitEvent("keyUp", "glasses testing");
    }
  };

  const clearState = () => {
    setFrame("black");
    setAccessory("red");
    setLense();
    // setStep(1);
    setIsModalLeft(false);
    setAnimating(false);
    setParts([]);
  };

  const showConfirmModal = () => {
    confirm({
      title: "Confirm Update?",
      icon: <ExclamationCircleFilled />,
      content: "Accessory will be reset after update MixNoggle",
      onOk() {
        //update item
        let item = {
          id: history.location?.state?.id,
          frame: frame,
          accessory: accessory,
          lense: lense,
          parts: [],
        };

        addItem(item);

        clearState();
        history.push({
          pathname: "/cart",
          state: item,
        });
      },
      onCancel() {
        log.debug("Cancel");
      },
    });
  };

  useEffect(() => {
    log.debug(history.location?.state);

    if (history?.location?.state?.editStep2) {
      setStep(2);
      //pre-select
      setFrame(history.location?.state?.frame);
      setAccessory(history.location?.state?.accessory);
      setLense(history.location?.state?.lense);
    } else if (history?.location?.state?.editStep1) {
      setStep(1);
      //pre-select
      setFrame(history.location?.state?.frame);
      setAccessory(history.location?.state?.accessory);
      setLense(history.location?.state?.lense);
    } else {
      setStep(1);
      //init
      log.debug("init");
      setFrame("black");
      setAccessory("red");
      setLense();
      modalRef?.current?.setVariables({
        [`frame_black`]: uuid(),
        [`accessory_red`]: uuid(),
        color_frame_opacity: 100,
        gold_frame_opacity: 0,
        color_clip_opacity: 100,
        gold_clip_opacity: 0,
        accessory_opacity: 100,
        basic_lense_opacity: 0,
        rgb_opacity: 0,
        logo_white_opacity: 0,
        logo_black_opacity: 0,
        logo_honey_opacity: 100,
      });
    }

    setParts(history.location?.state?.parts || []);
  }, [history?.location?.state]);

  const startFrameAnimation = async () => {
    if (modalRef.current != null && frame != null) {
      setAnimating(true);

      modalRef.current.setVariables({
        [`frame_${frame}`]: uuid(),
        logo_white_opacity: frame == "red" ? 100 : 0,
        logo_black_opacity: frame == "honey" ? 100 : 0,
        logo_honey_opacity: frame == "black" ? 100 : 0,
      });

      await sleep(1100);

      setAnimating(false);
    }
  };

  const startAccessoryAnimation = async () => {
    if (modalRef.current != null && accessory != null) {
      setAnimating(true);

      modalRef.current.setVariables({
        [`accessory_${accessory}`]: uuid(),
        color_clip_opacity: accessory == "honey" ? 0 : 100,
        gold_clip_opacity: accessory == "honey" ? 100 : 0,
      });

      await sleep(1100);

      setAnimating(false);
    }
  };

  const startLenseAnimation = async () => {
    if (modalRef.current != null && lense != null) {
      modalRef.current.setVariables({
        [`lense_${lense}`]: uuid(),
      });
    }
  };

  useEffect(() => {
    startFrameAnimation();
  }, [frame]);

  useEffect(() => {
    startAccessoryAnimation();
  }, [accessory]);

  useEffect(() => {
    startLenseAnimation();
  }, [lense]);

  return (
    <IonPage>
      <IonContent
        // className="show-scroll"
        style={{
          "--ion-background-color": "#f8f8f8",
          "--overflow": "hidden",
          width: "100%",
          height: "100vh",
        }}
      >
        <Row style={{ width: "100%" }}>
          {/* Preview */}
          <Col span={24} lg={16}>
            <Row
              style={{
                position: "sticky",
                top: 0,
                height: ["lg", "xl", "xxl"].includes(currentSize)
                  ? "100vh"
                  : "50vh",
                width: ["lg", "xl", "xxl"].includes(currentSize)
                  ? "100%"
                  : "100%",
              }}
            >
              {/* Hints */}
              <Row
                justify="space-between"
                align="middle"
                style={{
                  position: "absolute",
                  top: 0,
                  zIndex: 1000,
                  width: "100%",
                  padding: "1rem",
                }}
              >
                <Col>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    style={{
                      fontSize: "2rem",
                      textAlign: "start",
                      backgroundColor: "rgb(0,0,0,0)",
                      borderRadius: "1rem",
                      border: "1px solid gray",
                      color: "black",
                      padding: "0.5rem",
                    }}
                    onClick={() => {
                      history.goBack();
                    }}
                  >
                    <CloseOutlined />
                  </motion.button>
                </Col>

                {/* <Col>Design</Col> */}

                <Col span={6} style={{ textAlign: "right" }}>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    style={{
                      width: ["lg", "xl", "xxl"].includes(currentSize)
                        ? "7rem"
                        : "5rem",
                      fontSize: "1rem",
                      textAlign: "start",
                      backgroundColor: "rgb(0,0,0,0)",
                      borderRadius: "1rem",
                      border: "1px solid gray",
                      padding: "0.5rem",
                    }}
                    onClick={() => {
                      if (isModalLeft == false) {
                        setIsModalLeft(true);
                        zoomInCamera();
                      } else {
                        setIsModalLeft(false);
                        resetCamera();
                      }
                    }}
                  >
                    <FrameSVG
                      fill="gray"
                      // stroke="black"
                      isLeft={!isModalLeft}
                    />
                  </motion.button>
                </Col>
              </Row>
              <Spline
                //design
                // scene="https://prod.spline.design/kpDguDvDOw4uxhr2/scene.splinecode"
                scene="https://prod.spline.design/8-vTsAhi5nHvqPeA/scene.splinecode"
                style={{ cursor: "grab" }}
                onLoad={(modal) => {
                  modalRef.current = modal;
                  modalRef.current.setVariables({
                    accessory_opacity: 100,
                    basic_lense_opacity: 0,
                    rgb_opacity: 0,
                    logo_white_opacity:
                      history.location?.state?.frame == "red" ? 100 : 0,
                    logo_black_opacity:
                      history.location?.state?.frame == "honey" ? 100 : 0,
                    logo_honey_opacity:
                      history.location?.state?.frame == "black" ||
                      history.location?.state?.frame == null
                        ? 100
                        : 0,
                    [`frame_${history.location?.state?.frame}`]: uuid(),
                    [`accessory_${history.location?.state?.accessory}`]: uuid(),
                    color_clip_opacity:
                      history.location?.state?.accessory == "honey" ? 0 : 100,
                    gold_clip_opacity:
                      history.location?.state?.accessory == "honey" ? 100 : 0,
                    [`lense_${history.location?.state?.lense}`]: uuid(),
                  });

                  zoomInCamera();
                  setIsModalLeft(true);
                }}
              />
              {/* Navigation Btn */}
              {lense == null ? null : (
                <Row justify="center" style={{ width: "100%" }}>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    style={
                      ["lg", "xl", "xxl"].includes(currentSize)
                        ? {
                            position: "absolute",
                            bottom: "2rem",
                            width: "80%",
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "1.25rem",
                            padding: "1.25rem",
                            borderRadius: "0.5rem",
                          }
                        : {
                            position: "absolute",
                            bottom: "2rem",
                            width: "80%",
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "1.25rem",
                            padding: "0.75rem",
                            borderRadius: "0.5rem",
                          }
                    }
                    onClick={() => {
                      if (step == 1 && parts.length > 0) {
                        showConfirmModal();
                      } else {
                        //add item if id not exist
                        let item = {
                          id: history.location?.state?.id,
                          frame: frame,
                          accessory: accessory,
                          lense: lense,
                          parts: parts,
                        };

                        addItem(item);

                        clearState();
                        history.push({
                          pathname: "/cart",
                          state: item,
                        });
                      }
                    }}
                  >
                    {"save to cart"}
                  </motion.button>
                </Row>
              )}
            </Row>
          </Col>
          {/* Panel */}
          <Col
            span={24}
            lg={8}
            style={{
              backgroundColor: "white",
            }}
          >
            {step == 1 ? (
              <Step1
                currentSize={currentSize}
                animating={animating}
                frame={frame}
                accessory={accessory}
                lense={lense}
                onSelectFrame={(id) => {
                  setFrame(id);
                }}
                onSelectAccessory={(id) => {
                  setAccessory(id);
                }}
                onSelectLense={(id) => {
                  setLense(id);
                }}
              />
            ) : (
              <Step2
                currentSize={currentSize}
                animating={animating}
                frame={frame}
                accessory={accessory}
                lense={lense}
                parts={parts}
                onSelectFrame={async (id) => {
                  if (modalRef?.current == null) {
                    return;
                  }

                  setAnimating(true);

                  modalRef.current.setVariables({
                    logo_white_opacity: id == "red" ? 100 : 0,
                    logo_black_opacity: id == "honey" ? 100 : 0,
                    logo_honey_opacity: id == "black" ? 100 : 0,
                    [`switch_frame_${id}`]: uuid(),
                  });

                  await sleep(2000);

                  setAnimating(false);
                }}
                onSelectAccessory={async (id) => {
                  setAnimating(true);

                  modalRef.current.setVariables({
                    color_clip_opacity: id == "honey" ? 0 : 100,
                    gold_clip_opacity: id == "honey" ? 100 : 0,
                    [`switch_accessory_${id}`]: uuid(),
                  });

                  await sleep(2000);

                  setAnimating(false);
                }}
                onSelectLense={async (id) => {
                  setAnimating(true);

                  modalRef.current.setVariables({
                    [`switch_lense_black`]: uuid(),
                    [`switch_lense_rgb`]: uuid(),
                  });

                  await sleep(1000);

                  modalRef.current.setVariables({
                    [`lense_${id}`]: uuid(),
                  });

                  await sleep(1000);

                  setAnimating(false);
                }}
                onUpdateParts={(type, id) => {
                  log.debug("onUpdateParts...");
                  let updated = [];
                  let _parts = deepcopy(parts); // Create a shallow copy of each object

                  const index = _parts.findIndex(
                    (e) => e.type === type && e.id === id
                  );
                  if (index == -1) {
                    // Element not found, add a new entry
                    updated = [..._parts, { type, id }];
                  } else {
                    // Element found, delete it
                    updated = _parts.filter(
                      (e) => (e.type == type && e.id == id) == false
                    );
                  }
                  setParts(updated);
                }}
              />
            )}
          </Col>
        </Row>
      </IonContent>
    </IonPage>
  );
};

const Step1 = ({
  currentSize,
  animating,
  frame,
  accessory,
  lense,
  onSelectFrame,
  onSelectAccessory,
  onSelectLense,
}) => {
  return (
    <Flex
      vertical
      style={{
        height: ["lg", "xl", "xxl"].includes(currentSize) ? "100vh" : "50vh",
        overflowY: "auto",
      }}
    >
      {/* Header */}
      <Row
        justify="space-between"
        align="middle"
        className="ion-padding"
        style={{
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Row
          justify="center"
          style={
            ["lg", "xl", "xxl"].includes(currentSize)
              ? {
                  width: "7rem",
                  height: "auto",
                }
              : {
                  width: "6rem",
                  height: "auto",
                }
          }
        >
          <Col style={{ width: "100%" }}>
            <FrameSVG
              fill="lightgray"
              // stroke="black"
            />
          </Col>
          <Col>Frame</Col>
        </Row>

        <Row
          justify="center"
          style={
            ["lg", "xl", "xxl"].includes(currentSize)
              ? {
                  width: "7rem",
                  height: "auto",
                }
              : {
                  width: "6rem",
                  height: "auto",
                }
          }
        >
          <Col style={{ width: "100%" }}>
            <AccessorySVG
              fill="lightgray"
              // stroke="black"
            />
          </Col>
          <Col>Clip</Col>
        </Row>

        <Row
          justify="center"
          style={
            ["lg", "xl", "xxl"].includes(currentSize)
              ? {
                  width: "7rem",
                  height: "auto",
                }
              : {
                  width: "6rem",
                  height: "auto",
                }
          }
        >
          <Col style={{ width: "100%" }}>
            <LenseSVG
              fill="lightgray"
              // stroke="black"
            />
          </Col>
          <Col>Lenses</Col>
        </Row>
      </Row>

      {/* Option List */}
      <Row justify="space-around" gutter={[0, 16]} style={{ width: "100%" }}>
        {/* Frame Options */}
        <Col span={8}>
          {[
            { id: "black", color: "black" },
            { id: "red", color: "red" },
            { id: "honey", color: "gold" },
          ].map((e, i) => (
            <Row
              key={i}
              justify="center"
              style={{
                width: "100%",
                marginTop: "1rem",
                marginBottom: "2rem",
              }}
            >
              <Row
                style={{
                  borderRadius: 1000,
                  border: frame == e.id ? "3px solid white" : "",
                  outline: frame == e.id ? "3px solid blue" : "",
                  backgroundColor: e.color,
                  color: "rgb(0,0,0,0)",
                  width: "2.5rem",
                  aspectRatio: 1,
                  cursor: animating ? "not-allowed" : "pointer",
                }}
                onClick={(event) => {
                  log.debug(frame);
                  if (animating) {
                    event.stopPropagation();
                    return;
                  }
                  onSelectFrame(e.id);
                }}
              >
                .
              </Row>
            </Row>
          ))}
        </Col>
        {/* Accessory Options */}
        <Col span={8}>
          {[
            { id: "black", color: "black" },
            { id: "red", color: "red" },
            { id: "honey", color: "gold" },
          ].map((e, i) => (
            <Row
              key={i}
              justify="center"
              style={{
                width: "100%",
                marginTop: "1rem",
                marginBottom: "2rem",
              }}
            >
              <Row
                style={{
                  borderRadius: 1000,
                  border: accessory == e.id ? "3px solid white" : "",
                  outline: accessory == e.id ? "3px solid blue" : "",
                  backgroundColor: e.color,
                  color: "rgb(0,0,0,0)",
                  width: "2.5rem",
                  aspectRatio: 1,
                  cursor: animating ? "not-allowed" : "pointer",
                }}
                onClick={(event) => {
                  log.debug(accessory);
                  if (animating) {
                    event.stopPropagation();
                    return;
                  }
                  onSelectAccessory(e.id);
                }}
              >
                .
              </Row>
            </Row>
          ))}
        </Col>
        {/* Lense Options */}
        <Col span={8}>
          {[
            { id: "black", color: "black" },
            { id: "red", color: "red" },
            { id: "rgb", color: "green" },
          ].map((e, i) => (
            <Row
              key={i}
              justify="center"
              style={{
                width: "100%",
                marginTop: "1rem",
                marginBottom: "2rem",
              }}
            >
              <Row
                style={
                  e.id == "rgb"
                    ? {
                        backgroundImage:
                          "linear-gradient(to right, red 33.33%, #228B22 33.33%, #228B22 66.66%, blue 66.66%)",
                        borderRadius: 1000,
                        border: lense == e.id ? "3px solid white" : "",
                        outline: lense == e.id ? "3px solid blue" : "",
                        // backgroundColor: e.color,
                        color: "rgb(0,0,0,0)",
                        width: "2.5rem",
                        aspectRatio: 1,
                        cursor: animating ? "not-allowed" : "pointer",
                      }
                    : {
                        backgroundImage: `linear-gradient(to right, #f7f7f7 50%, ${e.id} 50%)`,
                        borderRadius: 1000,
                        border: lense == e.id ? "3px solid white" : "",
                        outline: lense == e.id ? "3px solid blue" : "",
                        color: "rgb(0,0,0,0)",
                        width: "2.5rem",
                        aspectRatio: 1,
                        cursor: animating ? "not-allowed" : "pointer",
                      }
                }
                onClick={(event) => {
                  log.debug(lense);
                  if (animating) {
                    event.stopPropagation();
                    return;
                  }
                  onSelectLense(e.id);
                }}
              >
                .
              </Row>
            </Row>
          ))}
        </Col>
      </Row>

      {/* Placeholder */}
      <Row style={{ marginTop: "4rem" }}></Row>
    </Flex>
  );
};

const Step2 = ({
  currentSize,
  animating,
  frame,
  accessory,
  lense,
  parts,
  onSelectFrame,
  onSelectAccessory,
  onSelectLense,
  onUpdateParts,
}) => {
  const history = useHistory();
  const { items, paymentMethod, config, whitelist, referral, updateWhitelist } =
    useStore();
  const { contract } = useContract(process.env.REACT_APP_CONTRACT_ADDRESS);
  const address = useAddress();
  const [usd, eth, nog] = usePrice(
    "set",
    { parts },
    contract,
    paymentMethod,
    whitelist,
    referral,
    config
  );
  const [isOriginal, setIsOriginal] = useState(true);
  const [frameOptionList, setFrameOptionList] = useState([]);
  const [accessoryOptionList, setAccessoryOptionList] = useState([]);
  const [lenseOptionList, setLenseOptionList] = useState([]);

  const getWhitelist = async () => {
    try {
      log.debug(address);
      let res = await axios.get(
        process.env.REACT_APP_SERVER_URL + "api/whitelists?address=" + address
      );
      log.debug(res.data);
      updateWhitelist(res.data);
    } catch (error) {
      log.debug(error?.response?.data?.error?.message);
      updateWhitelist();
    }
  };

  const setNumber = useMemo(() => {
    let number = items.findIndex((e) => e.id == history?.location?.state?.id);
    return number == -1 ? 0 : number + 1;
  }, [items, history?.location?.state]);

  const reset = () => {
    //animate
    onSelectFrame(frame);
    onSelectAccessory(accessory);
    onSelectLense(lense);
    //
    setIsOriginal(true);
  };

  const selectOption = (type, id) => {
    if (type == "frame") {
      if (frameOptionList.some((e) => e.id == id && !e.selected)) {
        // only animate if selected is false
        onSelectFrame(id);
        setIsOriginal(false);
      }

      const updated = frameOptionList.map((option) => {
        if (option.id === id) {
          return { ...option, selected: !option.selected };
        } else {
          return option;
        }
      });
      setFrameOptionList(updated);
    } else if (type == "accessory") {
      if (accessoryOptionList.some((e) => e.id == id && !e.selected)) {
        // only animate if selected is false
        onSelectAccessory(id);
        setIsOriginal(false);
      }

      const updated = accessoryOptionList.map((option) => {
        if (option.id === id) {
          return { ...option, selected: !option.selected };
        } else {
          return option;
        }
      });
      setAccessoryOptionList(updated);
    } else if (type == "lense") {
      if (lenseOptionList.some((e) => e.id == id && !e.selected)) {
        // only animate if selected is false
        onSelectLense(id);
        setIsOriginal(false);
      }

      const updated = lenseOptionList.map((option) => {
        if (option.id === id) {
          return { ...option, selected: !option.selected };
        } else {
          return option;
        }
      });
      setLenseOptionList(updated);
    }
  };

  const preSelect = (e) => {
    // pre-select base on props
    let selected = false;
    if (
      history?.location?.state?.parts?.some(
        (x) => x.id == e.id && x.type == e.type
      )
    ) {
      selected = true;
    }

    return { ...e, selected: selected };
  };

  useEffect(() => {
    let arr = options.frame
      .filter((e) => e.id != frame) // filter to only 2 option
      .map(preSelect);
    setFrameOptionList(arr);
  }, [frame, history?.location?.state]);

  useEffect(() => {
    let arr = options.accessory
      .filter((e) => e.id != accessory) // filter to only 2 option
      .map(preSelect);
    setAccessoryOptionList(arr);
  }, [accessory, history?.location?.state]);

  useEffect(() => {
    let arr = options.lense
      .filter((e) => e.id != lense) // filter to only 2 option
      .map(preSelect);
    setLenseOptionList(arr);
  }, [lense, history?.location?.state]);

  useEffect(() => {
    if (address != null) {
      getWhitelist();
    }
  }, [address]);

  return (
    <IonContent
      className="show-scroll"
      style={{
        "--ion-background-color": "white",
        width: "100%",
        height: ["lg", "xl", "xxl"].includes(currentSize) ? "100vh" : "50vh",
      }}
    >
      <Row
        style={{
          marginLeft: "2rem",
          marginRight: "2rem",
        }}
      >
        {/* Header */}
        <Row
          justify="space-between"
          align="middle"
          className="ion-padding-vertical"
          style={{
            width: "100%",
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
          }}
        >
          <Row
            justify="start"
            align="middle"
            style={{ fontSize: "1.25rem", width: "100%" }}
          >
            Set {setNumber}
            <img
              style={{ marginLeft: "1rem", width: "3rem" }}
              src={`${process.env.REACT_APP_S3_PREFIX}/other/icon-black-1600px.png`}
            />
          </Row>

          <Row style={{ width: "100%", fontSize: "2rem" }}>
            <USDPrice usd={usd} />
          </Row>
          <Row style={{ width: "100%", fontSize: "1.25rem", color: "gray" }}>
            <TokenPrice
              contract={contract}
              paymentMethod={paymentMethod}
              eth={eth}
              nog={nog}
              config={config}
            />
          </Row>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={animating}
            style={{
              fontSize: "0.8rem",
              textAlign: "start",
              backgroundColor: "black",
              color: "white",
              borderRadius: 1000,
              border: "1px solid gray",
              padding: "0.75rem",
              cursor: animating ? "not-allowed" : "pointer",
              display: isOriginal ? "none" : "block",
            }}
            onClick={() => {
              reset();
            }}
          >
            show original
          </motion.button>
        </Row>

        {/* <Row style={{ width: "100%", marginBottom: "1rem", marginTop: "2rem" }}>
          <span style={{ fontSize: "1.25rem", width: "100%" }}>Frame</span>
        </Row> */}
        <OptionList
          animating={animating}
          options={frameOptionList}
          whitelist={whitelist}
          onSelectOption={(type, id) => {
            selectOption(type, id);
            onUpdateParts(type, id);
          }}
        />

        {/* <Row style={{ width: "100%", marginBottom: "1rem" }}>
          <span style={{ fontSize: "1.25rem", width: "100%" }}>Clip</span>
        </Row> */}
        <OptionList
          animating={animating}
          options={accessoryOptionList}
          whitelist={whitelist}
          onSelectOption={(type, id) => {
            selectOption(type, id);
            onUpdateParts(type, id);
          }}
        />

        {/* <Row style={{ width: "100%", marginBottom: "1rem" }}>
          <span style={{ fontSize: "1.25rem", width: "100%" }}>Lense</span>
        </Row> */}
        <OptionList
          animating={animating}
          options={lenseOptionList}
          whitelist={whitelist}
          onSelectOption={(type, id) => {
            selectOption(type, id);
            onUpdateParts(type, id);
          }}
          parts={parts}
        />

        {/* Placeholder */}
        <Row style={{ marginTop: "4rem", color: "white" }}>.</Row>
      </Row>
    </IonContent>
  );
};

const USDPrice = ({ usd }) => {
  let _usd = usd || "";
  return `${_usd} USD`;
};

const TokenPrice = ({ contract, paymentMethod, eth, nog }) => {
  const [currentSize, BreakPoint] = useBreakPoint();
  if (paymentMethod == "ETH") {
    return (
      <Row
        justify="end"
        style={{
          fontSize: ["lg", "xl", "xxl"].includes(currentSize)
            ? "1.25rem"
            : "1rem",
          color: "gray",
        }}
      >
        {contract == null || eth == null ? "..." : `≈ ${eth}Ξ`}
      </Row>
    );
  } else if (paymentMethod == "NOG") {
    return (
      <Row
        justify="end"
        align="middle"
        style={{
          fontSize: ["lg", "xl", "xxl"].includes(currentSize)
            ? "1.25rem"
            : "1rem",
          color: "gray",
        }}
      >
        ≈&nbsp;{contract == null || nog == null ? 0 : nog}
        &nbsp;
        <img
          style={{ width: "2rem", height: "2rem" }}
          src={`${process.env.REACT_APP_S3_PREFIX}/other/nogCoin.png`}
        />
        <div style={{ margin: "-1rem 0 0 -0.75rem" }}>
          <BaseSVG />
        </div>
      </Row>
    );
  }
};

const OptionList = ({ animating, options, onSelectOption, parts }) => {
  const { paymentMethod, whitelist, referral } = useStore();
  const [currentSize, BreakPoint] = useBreakPoint();

  return (
    <Row gutter={[0, 16]} style={{ width: "100%", marginBottom: "1rem" }}>
      {options.map((e, i) => (
        <Col key={i} span={24}>
          <Row>
            <Card
              hoverable
              bordered
              style={{
                border: e.selected ? "2px solid #55A3FF" : "1px solid gray",
                cursor: animating ? "not-allowed" : "pointer",
              }}
              bodyStyle={{ padding: "1rem" }}
              onClick={(event) => {
                if (animating) {
                  event.stopPropagation();
                  return;
                }
                onSelectOption(e.type, e.id);
              }}
            >
              <Row justify="space-between" align="middle">
                <Col span={6}>
                  <img
                    src={e.src}
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      aspectRatio: 16 / 9,
                    }}
                  />
                </Col>

                <Col
                  span={6}
                  style={{
                    fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                      ? "1.1rem"
                      : "1rem",
                  }}
                >
                  {e.type == "frame"
                    ? `+ $${getDiscountedPrice(
                        paymentMethod,
                        whitelist?.discount || referral?.discount,
                        process.env.REACT_APP_FRAME_PRICE
                      )}`
                    : e.type == "accessory"
                    ? `+ $${getDiscountedPrice(
                        paymentMethod,
                        whitelist?.discount || referral?.discount,
                        process.env.REACT_APP_ACCESSORY_PRICE
                      )}`
                    : e.type == "lense"
                    ? parts?.length > 0 &&
                      parts.find((e) => e.type == "lense")?.id != e.id
                      ? `+ $${getDiscountedPrice(
                          paymentMethod,
                          whitelist?.discount || referral?.discount,
                          process.env.REACT_APP_LENSE_PRICE
                        )}`
                      : "free"
                    : 0}
                </Col>

                <Col span={12} style={{ textAlign: "end" }}>
                  {e.selected ? (
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      style={{
                        backgroundColor: "#55A3FF",
                        color: "white",
                        borderRadius: 1000,
                        padding: "0.75rem",
                      }}
                      //disabled={isMinting}
                    >
                      added
                    </motion.button>
                  ) : (
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: 1000,
                        padding: "0.75rem",
                      }}
                      //disabled={isMinting}
                    >
                      add to cart
                    </motion.button>
                  )}
                </Col>
              </Row>
            </Card>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default T;
