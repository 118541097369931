import { useHistory } from "react-router-dom";

import { IonPage, IonContent } from "@ionic/react";
import { Row, Col, Flex } from "antd";

import { motion } from "framer-motion";

import useBreakPoint from "../hooks/useBreakPoint";
import useLogin from "../hooks/useLogin";

//utils
import log from "../utils/log";

import "../styles/global.css";

const T = () => {
  const history = useHistory();
  const [currentSize, BreakPoint] = useBreakPoint();
  const [auth] = useLogin();

  return (
    <IonPage>
      <IonContent
        style={{
          "--ion-background-color": "white",
          width: "100%",
          height: "100vh",
        }}
      >
        <Row justify="center" style={{ width: "100%", height: "100%" }}>
          <Col span={20} lg={12}>
            <Row justify="center" align="middle" style={{ height: "100%" }}>
              <img
                style={
                  ["lg", "xl", "xxl"].includes(currentSize)
                    ? {
                        width: "75%",
                        aspectRatio: 1,
                        borderRadius: "1rem",
                        objectFit: "cover",
                      }
                    : {
                        marginTop: "2rem",
                        width: "75%",
                        aspectRatio: 1,
                        borderRadius: "1rem",
                        objectFit: "cover",
                      }
                }
                src={
                  process.env.REACT_APP_S3_PREFIX + "/other/box-transparent.png"
                }
              />
            </Row>
          </Col>
          <Col span={24} lg={12}>
            <Flex
              justify="center"
              style={{
                height: "100%",
                paddingLeft: "3rem",
                paddingRight: "3rem",
              }}
              vertical
            >
              <Row
                style={
                  ["lg", "xl", "xxl"].includes(currentSize)
                    ? { width: "100%", fontSize: "2rem" }
                    : {
                        width: "100%",
                        fontSize: "1.6rem",
                        marginTop: "2rem",
                      }
                }
              >
                {auth?.email ? "Order Placed" : "Here is your NFT"}
              </Row>

              <Row
                style={{
                  marginTop: "4rem",
                  width: "100%",
                  fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                    ? "1.25rem"
                    : "1rem",
                  fontFamily: "BlinkMacSystemFont, sans-serif",
                }}
              >
                Thank you for ordering MixNoggle!
              </Row>

              <Row
                style={{
                  marginTop: "2rem",
                  width: "100%",
                  fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                    ? "1.25rem"
                    : "1rem",
                  fontFamily: "BlinkMacSystemFont, sans-serif",
                }}
              >
                <span>
                  Once we confirm your order, you will receive a confirmation
                  email at&nbsp;
                  <span style={{ fontWeight: "bold" }}>
                    {auth?.email ? auth.email : history.location?.state?.email}
                  </span>
                </span>
              </Row>

              {auth?.email ? (
                <Row
                  style={{
                    marginTop: "2rem",
                    width: "100%",
                    fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                      ? "1.25rem"
                      : "1rem",
                    fontFamily: "BlinkMacSystemFont, sans-serif",
                  }}
                >
                  A redemption link will be included in the email in case
                  you want to transfer the NFT to your wallet address.
                </Row>
              ) : null}

              <Row
                style={{
                  marginTop: "2rem",
                  width: "100%",
                  fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                    ? "1.25rem"
                    : "1rem",
                  fontFamily: "BlinkMacSystemFont, sans-serif",
                }}
              >
                You will get another email once your MixNoggles are delivered.
              </Row>

              <Row
                style={{
                  marginTop: "2rem",
                  width: "100%",
                  fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                    ? "1.25rem"
                    : "1rem",
                  fontFamily: "BlinkMacSystemFont, sans-serif",
                }}
              >
                The delivery is expected to be within&nbsp;
                {process.env.REACT_APP_EXPECTED_DELIVERY_DATE}&nbsp;days after
                the successful placement of your order. Your NFT will be
                revealed as a random 3D MixNoggle upon delivery.
              </Row>

              {auth?.email ? null : (
                <Row
                  justify="start"
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginTop: "4rem",
                    marginBottom: "2rem",
                  }}
                  onClick={() => {
                    let txHash = history.location?.state?.txHash;

                    let url =
                      process.env.REACT_APP_NODE_ENV == "production"
                        ? `${process.env.REACT_APP_MAINNET_EXPLORER_PREFIX}${txHash}`
                        : `${process.env.REACT_APP_TESTNET_EXPLORER_PREFIX}${txHash}`;
                    window.open(url, "_blank", "noopener,noreferrer");
                  }}
                >
                  view transaction on block explorer
                </Row>
              )}

              {auth?.email ? null : (
                <Row
                  justify="start"
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    marginBottom: "2rem",
                  }}
                  onClick={() => {
                    let url = "https://opensea.io/account/collected";
                    window.open(url, "_blank", "noopener,noreferrer");
                  }}
                >
                  view nft on OpenSea
                </Row>
              )}

              <Row justify="end">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    padding: "1rem",
                    borderRadius: "1rem",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: "/market",
                      state: { scrollToOrder: true },
                    });
                  }}
                >
                  <Row
                    style={{
                      fontSize: "1rem",
                      padding: "0.5rem",
                    }}
                  >
                    {`Track your order >`}
                  </Row>
                </motion.button>
              </Row>
            </Flex>
          </Col>
        </Row>
      </IonContent>
    </IonPage>
  );
};

export default T;
