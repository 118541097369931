import { useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MediaRenderer, ThirdwebNftMedia } from "@thirdweb-dev/react";

import { IonPage, IonContent, IonIcon, IonModal } from "@ionic/react";
import { informationCircle, closeOutline } from "ionicons/icons";

import {
  Row,
  Col,
  Steps,
  Empty,
  Drawer,
  Divider,
  Form,
  Input,
  Tooltip,
} from "antd";
import { ExportOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import { motion } from "framer-motion";

//components
import PhoneInput from "react-phone-input-2";

//hooks
import useBreakPoint from "../hooks/useBreakPoint";
import useNotification from "../hooks/useNotification";
import useVerify from "../hooks/useVerify";

//utils
import log from "../utils/log";
import { parsePhoneFromObj, parseQueryToJSON } from "../utils/general";

import axios from "axios";
import moment from "moment";
import { diff } from "deep-object-diff";

//const
import splineMapping from "../const/spline";

import "../styles/global.css";

const T = () => {
  const history = useHistory();
  const location = useLocation();
  const [openNotification, contextHolder] = useNotification();
  const [contactForm] = Form.useForm();
  const [currentSize, BreakPoint] = useBreakPoint();
  const [id, setId] = useState();
  const [order, setOrder] = useState();
  const [meta, setMeta] = useState();
  const [isShowResendEmailTips, setIsShowResendEmailTips] = useState(false);
  const [isLoadingResendEmail, setIsLoadingResendEmail] = useState(false);
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const [isShowOpenSeaDrawer, setIsShowOpenSeaDrawer] = useState(false);

  const splineURL = useMemo(() => {
    if (meta?.raw?.metadata?.attributes == null) {
      return "";
    }
    let frame;
    let clip;
    let lense;
    for (let attr of meta.raw.metadata.attributes) {
      if (attr.trait_type == "Frame") {
        frame = attr.value;
      }
      if (attr.trait_type == "Clip") {
        clip = attr.value;
      }
      if (attr.trait_type == "Lenses") {
        lense = attr.value;
      }
    }
    return splineMapping[frame][clip][lense];
  }, [meta]);

  const getOrder = async (id) => {
    try {
      let res = await axios.get(
        process.env.REACT_APP_SERVER_URL + "api/orders?id=" + id
      );
      log.debug(res.data);
      setOrder(res.data);
      return res.data;
    } catch (error) {
      log.error(error);
      setOrder(false);
      return null;
    }
  };

  const getMeta = async (order) => {
    try {
      let offset = order.tokenId;
      let query = `contractAddress=${process.env.REACT_APP_CONTRACT_ADDRESS}&withMetadata=true&startToken=${offset}&limit=1&tokenUriTimeoutInMs=100000`;
      let url =
        process.env.REACT_APP_NODE_ENV == "production"
          ? `https://${process.env.REACT_APP_ALCHEMY_MAINNET_PREFIX}.g.alchemy.com/nft/v3/${process.env.REACT_APP_ALCHEMY_MAINNET_API_KEY}/getNFTsForContract?${query}`
          : `https://${process.env.REACT_APP_ALCHEMY_TESTNET_PREFIX}.g.alchemy.com/nft/v3/${process.env.REACT_APP_ALCHEMY_SEPOLIA_API_KEY}/getNFTsForContract?${query}`;

      let res = await axios.get(url);
      log.debug(res.data);
      if (res.data.nfts.length > 0) {
        setMeta(res.data.nfts[0]);
      }
    } catch (error) {
      log.error(error);
      openNotification("error", "Fail to fetch metadata");
    }
  };

  const getData = async (id) => {
    let order = await getOrder(id);
    if (order != null && order.tokenId != null) {
      getMeta(order);
    }
  };

  const getAddress = (address) => {
    if (address == null) {
      return "";
    }
    let building = address.building == null ? "" : address.building + ",";
    let zipcode = address.zipcode == null ? "" : address.zipcode + ",";
    return `${building} ${address.street}, ${address.city}, ${address.state}, ${zipcode} ${address.country}`;
  };

  const resendEmail = async () => {
    try {
      setIsLoadingResendEmail(true);
      let res = await axios.post(
        process.env.REACT_APP_SERVER_URL + "api/resend/order/email",
        { id: order.id }
      );
      log.debug(res.data);
      setIsShowResendEmailTips(true);
      setIsLoadingResendEmail(false);
      setTimeout(() => {
        setIsShowResendEmailTips(false);
      }, 1000 * 60);
    } catch (error) {
      log.error(error);
      setIsLoadingResendEmail(false);
      openNotification("error", "Email not sent");
    }
  };

  useEffect(() => {
    const queryParams = parseQueryToJSON(location.search);
    const { id } = queryParams;
    console.log(id);
    setId(id);
    getData(id);
  }, []);

  return (
    <IonPage>
      {isShowDrawer ? (
        <ContactFormDrawer
          form={contactForm}
          currentSize={currentSize}
          order={order}
          onClose={() => {
            setIsShowDrawer(false);
          }}
          onUpdateOrderState={(order) => {
            setOrder(order);
          }}
          openNotification={openNotification}
        />
      ) : null}

      {isShowOpenSeaDrawer ? (
        <OpenSeaDrawer
          onClose={() => {
            setIsShowOpenSeaDrawer(false);
          }}
        />
      ) : null}

      <IonContent
        style={
          ["lg", "xl", "xxl"].includes(currentSize)
            ? {
                "--ion-background-color": "white",
                width: "100%",
                height: "100vh",
                "--padding-start": "10%",
                "--padding-end": "10%",
              }
            : {
                "--ion-background-color": "white",
                width: "100%",
                height: "100vh",
              }
        }
      >
        {contextHolder}
        <div style={{ width: "100%", height: "100%", padding: "2rem" }}>
          {/* Header */}
          <Row justify="start" align="middle" style={{ width: "100%" }}>
            <Col span={6} lg={2}>
              <img
                style={{ width: "100%", cursor: "pointer" }}
                src={`${process.env.REACT_APP_S3_PREFIX}/other/logo/logo-black-wide.png`}
                onClick={() => {
                  history.push("/market");
                }}
              />
            </Col>

            <Col style={{ fontSize: "2rem", marginLeft: "2rem" }}>
              <Col span={24}>Your Order</Col>
              <Col
                span={24}
                style={{ fontSize: "2rem" }}
                onClick={() => {
                  log.debug(order);
                }}
              >
                {id}
              </Col>
            </Col>
          </Row>

          {/* Placeholder */}
          {order == false ? (
            <Empty
              description="order not found"
              style={{ marginTop: "2rem" }}
            />
          ) : (
            <>
              {/* IPFS Viewer */}
              {meta?.raw?.metadata != null ? (
                <Row
                  justify="center"
                  className="ion-margin-vertical"
                  style={{
                    width: "100%",
                    border: "1px solid black",
                    backgroundColor: "#f7f7f7",
                  }}
                >
                  {meta?.raw?.metadata?.attributes != null ? (
                    <iframe
                      src={splineURL}
                      style={{ width: "100%", height: 300 }}
                    />
                  ) : (
                    <MediaRenderer
                      // src={meta.raw.metadata.animation_url || meta.raw.metadata.image}
                      src={meta.raw.metadata.image}
                      style={{ width: "100%", height: 300 }}
                    />
                  )}
                </Row>
              ) : null}
              {order ? (
                <Steps
                  style={{
                    marginTop: "2rem",
                    marginBottom: "4rem",
                  }}
                  items={[
                    {
                      title: "Order Received",
                      description: moment(order.time.place).format(
                        "YYYY-MM-DD HH:mm"
                      ),
                      status: "finish",
                    },
                    {
                      title: "Building Your MixNoggle",
                      description:
                        order.time.deliver == null
                          ? "usually take 60 days"
                          : moment(order.time.deliver).format(
                              "YYYY-MM-DD HH:mm"
                            ),
                      status:
                        order.status == "delivered" ? "finish" : "process",
                    },
                    {
                      title: "Delivered",
                      description:
                        order.time.deliver == null
                          ? ""
                          : moment(order.time.deliver).format(
                              "YYYY-MM-DD HH:mm"
                            ),
                      status: order.status == "delivered" ? "finish" : "wait",
                    },
                  ]}
                />
              ) : null}
              {order?.redeemlink != null ? <RedeemLink order={order} /> : null}

              {/* External Link */}
              {order != null && order.txHash != null ? (
                <Row
                  justify="start"
                  align="middle"
                  style={{
                    marginTop: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Col>block explorer</Col>
                  <Col>
                    <Col style={{ marginLeft: "1rem" }}>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        style={{
                          textAlign: "start",
                          backgroundColor: "#1677FF",
                          borderRadius: "1rem",
                          border: "1px solid gray",
                          color: "white",
                          padding: "0.75rem 1rem 0.75rem 1rem",
                          fontSize: "1rem",
                        }}
                        onClick={() => {
                          let txHash = order.txHash;

                          let url =
                            process.env.REACT_APP_NODE_ENV == "production"
                              ? `${process.env.REACT_APP_MAINNET_EXPLORER_PREFIX}${txHash}`
                              : `${process.env.REACT_APP_TESTNET_EXPLORER_PREFIX}${txHash}`;
                          window.open(url, "_blank", "noopener,noreferrer");
                        }}
                      >
                        view
                      </motion.button>
                    </Col>
                  </Col>
                </Row>
              ) : null}

              {order != null && order?.tokenId != null ? (
                <Row
                  justify="start"
                  align="middle"
                  style={{
                    marginTop: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Col>
                    <Row align="middle">
                      OpenSea
                      <img
                        style={{ height: "2rem" }}
                        src={`${process.env.REACT_APP_S3_PREFIX}/other/openseaLogo.png`}
                      />
                    </Row>
                  </Col>
                  <Col style={{}}>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      style={{
                        textAlign: "start",
                        backgroundColor: "#1677FF",
                        borderRadius: "1rem",
                        border: "1px solid gray",
                        color: "white",
                        padding: "0.75rem 1rem 0.75rem 1rem",
                        fontSize: "1rem",
                      }}
                      onClick={() => {
                        if (meta?.raw?.metadata?.attributes != null) {
                          //IPFS updated to 3D, open guide drawer
                          setIsShowOpenSeaDrawer(true);
                        } else {
                          // IPFS stil is MixNoggle Box
                          let url = "https://opensea.io/account/collected";
                          window.open(url, "_blank", "noopener,noreferrer");
                        }
                      }}
                    >
                      view
                    </motion.button>
                  </Col>
                </Row>
              ) : null}

              {/* Cards */}
              <Row
                justify="space-between"
                style={{
                  marginTop: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Col
                  span={24}
                  lg={11}
                  style={{
                    borderRadius: "0.5rem",
                    border: "1px solid lightgray",
                    padding: "1.5rem",
                    // backgroundColor: "#f7f7f7",
                  }}
                >
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{
                      fontSize: "1.25rem",
                    }}
                  >
                    <Col style={{ fontSize: "1rem" }}>Contact </Col>
                    {order?.status == "placed" ? (
                      <Col style={{ color: "blue", cursor: "pointer" }}>
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          style={{
                            textAlign: "start",
                            backgroundColor: "#1677FF",
                            borderRadius: "1rem",
                            border: "1px solid gray",
                            color: "white",
                            padding: "0.75rem 1rem 0.75rem 1rem",
                            fontSize: "1rem",
                          }}
                          onClick={() => {
                            setIsShowDrawer(true);
                          }}
                        >
                          edit
                        </motion.button>
                      </Col>
                    ) : null}
                  </Row>
                  <Divider />

                  <Row
                    style={{
                      marginTop: "2rem",
                      fontFamily: "BlinkMacSystemFont, sans-serif",
                      wordWrap: "break-word",
                    }}
                  >
                    <Col
                      span={24}
                      style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                    >
                      Email
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: "1.25rem",
                        fontFamily: "BlinkMacSystemFont, sans-serif",
                      }}
                    >
                      {order?.buyer?.email}
                    </Col>
                    <Col
                      span={24}
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        disabled={isLoadingResendEmail || isShowResendEmailTips}
                        style={{
                          textAlign: "start",
                          backgroundColor:
                            isLoadingResendEmail || isShowResendEmailTips
                              ? "gray"
                              : "#1677FF",
                          borderRadius: "1rem",
                          border: "1px solid gray",
                          color: "white",
                          padding: "0.75rem 1rem 0.75rem 1rem",
                          fontSize: "1rem",
                        }}
                        onClick={() => {
                          resendEmail();
                        }}
                      >
                        resend order email
                      </motion.button>
                    </Col>
                    {isShowResendEmailTips ? (
                      <Col
                        span={24}
                        style={{
                          fontFamily: "BlinkMacSystemFont, sans-serif",
                          marginTop: "1rem",
                          color: "#1677FF",
                        }}
                      >
                        Email sent! please check your mailbox.
                      </Col>
                    ) : null}
                  </Row>

                  <Row
                    style={{
                      marginTop: "2rem",
                      fontSize: "1.25rem",
                      wordWrap: "break-word",
                    }}
                  >
                    <Col
                      span={24}
                      style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                    >
                      Address
                    </Col>
                    <Col
                      span={24}
                      style={{
                        fontSize: "1.25rem",
                        fontFamily: "BlinkMacSystemFont, sans-serif",
                      }}
                    >
                      {getAddress(order?.address)}
                    </Col>
                  </Row>
                </Col>
                <Col
                  span={24}
                  lg={11}
                  style={{
                    marginTop: ["lg", "xl", "xxl"].includes(currentSize)
                      ? ""
                      : "1rem",
                    borderRadius: "0.5rem",
                    border: "1px solid lightgray",
                    padding: "1.5rem",
                    // backgroundColor: "#f7f7f7",
                  }}
                >
                  <Row
                    justify="space-between"
                    style={{
                      fontSize: "1.25rem",
                    }}
                  >
                    <Col style={{ fontSize: "1rem" }}>Shipping Info </Col>
                  </Row>
                  <Divider />
                  {order?.shipping?.trackNum ? (
                    <>
                      <Row style={{ marginTop: "2rem", fontSize: "0.75rem" }}>
                        <Col
                          span={24}
                          style={{
                            fontFamily: "BlinkMacSystemFont, sans-serif",
                          }}
                        >
                          Tracking Number
                        </Col>
                        <Col
                          span={24}
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "BlinkMacSystemFont, sans-serif",
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "#1677FF",
                          }}
                          onClick={() => {
                            let url = order?.shipping?.link;
                            if (url != null) {
                              window.open(url, "_blank", "noopener,noreferrer");
                            }
                          }}
                        >
                          {order?.shipping?.trackNum}
                          <ExportOutlined
                            style={{ marginLeft: "0.5rem", fontSize: "1rem" }}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "2rem", fontSize: "0.75rem" }}>
                        <Col
                          span={24}
                          style={{
                            fontFamily: "BlinkMacSystemFont, sans-serif",
                          }}
                        >
                          Provider
                        </Col>
                        <Col
                          span={24}
                          style={{
                            fontSize: "1.5rem",
                            fontFamily: "BlinkMacSystemFont, sans-serif",
                          }}
                        >
                          {order?.shipping?.provider}
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Empty />
                  )}
                </Col>
              </Row>
            </>
          )}

          {/* Back Btn */}
          <Row justify="end">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{
                backgroundColor: "black",
                color: "white",
                padding: "0.75rem 1rem 0.75rem 1rem",
                borderRadius: "0.5rem",
                marginTop: "2rem",
              }}
              onClick={() => {
                history.push("/market");
              }}
            >
              <Row
                style={{
                  fontSize: "1rem",
                  padding: "0.5rem",
                }}
              >
                {`Back to Home >`}
              </Row>
            </motion.button>
          </Row>
          <Row style={{ height: "7rem" }}></Row>
        </div>
      </IonContent>
    </IonPage>
  );
};

const RedeemLink = ({ order }) => {
  const history = useHistory();
  const [currentSize, BreakPoint] = useBreakPoint();

  return (
    <Row
      justify="start"
      align="middle"
      style={{
        width: "100%",
        borderRadius: "0.5rem",
        border: "1px solid lightgray",
        padding: "1.5rem",
      }}
    >
      {order?.redeemlink?.address == null ? (
        <Row justify="start" align="middle" gutter={[16, 16]}>
          <Col
            style={{
              marginRight: "1rem",
              fontSize: "1rem",
            }}
          >
            Ready to redeem your NFT?
          </Col>
          <Col>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{
                textAlign: "start",
                backgroundColor: "#1677FF",
                borderRadius: "1rem",
                border: "1px solid gray",
                color: "white",
                padding: "0.75rem 1rem 0.75rem 1rem",
                fontSize: "1rem",
              }}
              onClick={() => {
                history.push(`/redeemlink?orderId=${order?.id}`);
              }}
            >
              Redeem NFT
            </motion.button>
          </Col>
        </Row>
      ) : (
        <>
          <Row
            style={{
              width: "100%",
              fontSize: "1.5rem",
              marginBottom: "2rem",
            }}
          >
            Redeem Your NFT
          </Row>
          <Row
            style={{
              width: "100%",
              color: "gray",
              fontSize: "1rem",
            }}
          >
            Wallet Address
          </Row>
          <Row
            style={{
              width: "100%",
              fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                ? "1.25rem"
                : "1rem",
              fontFamily: "BlinkMacSystemFont, sans-serif",
              marginBottom: "1rem",
              display: "block",
            }}
          >
            {order.redeemlink.address}
          </Row>
          <Divider style={{ margin: "1rem" }} />

          <Row align="middle">
            <IonIcon
              style={{
                color: "#1677FF",
                fontSize: "1.5rem",
                marginRight: "0.5rem",
              }}
              icon={informationCircle}
            />
            <span
              style={{
                fontSize: "1rem",
                color: "gray",
              }}
            >
              The NFT will be minted to your wallet address once the order is
              delivered
            </span>
          </Row>
        </>
      )}
    </Row>
  );
};

const OpenSeaDrawer = ({ onClose }) => {
  const [currentSize, BreakPoint] = useBreakPoint();

  return (
    <Drawer
      placement={["lg", "xl", "xxl"].includes(currentSize) ? "right" : "bottom"}
      width="45%"
      height="80%"
      onClose={() => {
        onClose();
      }}
      open={true}
      key="drawer"
    >
      <Row>
        <Row style={{ width: "100%" }}>
          <Row>
            <Row
              style={{
                width: "100%",
                marginBottom: "2rem",
                fontSize: "1.5rem",
              }}
            >
              Reveal NFT on OpenSea
            </Row>

            {/* Btn */}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{
                textAlign: "start",
                backgroundColor: "#1677FF",
                borderRadius: "1rem",
                border: "1px solid gray",
                color: "white",
                padding: "0.75rem 1rem 0.75rem 1rem",
                fontSize: "1rem",
              }}
              onClick={() => {
                onClose();
                let url = "https://opensea.io/account/collected";
                window.open(url, "_blank", "noopener,noreferrer");
              }}
            >
              <Row align="middle">
                reveal on OpenSea
                <img
                  style={{ height: "2rem" }}
                  src={`${process.env.REACT_APP_S3_PREFIX}/other/openseaLogo.png`}
                />
              </Row>
            </motion.button>
          </Row>
          {/* Info */}
          <Row style={{ width: "100%", marginTop: "2rem" }}>
            {/* Step1 */}
            <Row align="middle" style={{ marginBottom: "1rem" }}>
              <strong style={{ fontSize: "1.25rem" }}>Step 1</strong>
              <Row style={{ width: "100%", fontSize: "1rem" }}>
                Go to OpenSea Profile, Select your NFT
              </Row>
            </Row>
            <img
              style={{ marginBottom: "2rem" }}
              src={`${process.env.REACT_APP_S3_PREFIX}/other/openseaGuideStep1.png`}
            />
            {/* Step2 */}
            <Row align="middle" style={{ marginBottom: "1rem" }}>
              <strong style={{ fontSize: "1.25rem" }}>Step 2</strong>
              <Row style={{ width: "100%", fontSize: "1rem" }}>
                Click Refresh metadata
              </Row>
            </Row>
            <img
              style={{ marginBottom: "2rem" }}
              src={`${process.env.REACT_APP_S3_PREFIX}/other/openseaGuideStep2.png`}
            />
            {/* Step3 */}
            <Row align="middle" style={{ marginBottom: "4rem" }}>
              <strong style={{ fontSize: "1.25rem" }}>Step 3</strong>
              <Row style={{ width: "100%", fontSize: "1rem" }}>
                Done~ Your Random MixNoggle will be revealed in no time!
              </Row>
            </Row>
          </Row>
        </Row>
      </Row>
    </Drawer>
  );
};

const ContactFormDrawer = ({
  form,
  currentSize,
  order,
  onClose,
  onUpdateOrderState,
  openNotification,
}) => {
  const [isVerified, Verify] = useVerify(
    order?.payment == null
      ? { method: "wallet", walletAddress: order?.buyer?.address }
      : { method: "email", email: order?.buyer?.email },
    openNotification
  );

  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [phoneInput, setPhoneInput] = useState();
  const [phone, setPhone] = useState();
  const [twitterId, setTwitterId] = useState();
  const [street, setStreet] = useState();
  const [apartment, setApartment] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [zipcode, setZipCode] = useState();
  const [country, setCountry] = useState();

  const [isUpdating, setIsUpdating] = useState(false);

  const initialFormValues = useMemo(() => {
    return {
      name: order?.buyer?.name,
      email: order?.buyer?.email,
      phone: order?.buyer?.phone,
      twitterId: order?.buyer?.twitterId,
      street: order?.address?.street,
      apartment: order?.address?.apartment,
      state: order?.address?.state,
      city: order?.address?.city,
      zipcode: order?.address?.zipcode,
      country: order?.address?.country,
    };
  }, [order]);

  const updateOrder = async (difference) => {
    setIsUpdating(true);

    log.debug(difference);
    //parse
    let data = {};
    for (let key in difference) {
      if (["name", "email", "twitterId", "phone"].includes(key)) {
        if (data.buyer == null) {
          data.buyer = {};
        }

        if (key == "twitterId" && difference[key] == "") {
          data.buyer[key] = null;
        } else {
          data.buyer[key] = difference[key];
        }

        // countryCode is null means countryCode is same, use old countryCode
        // countryCode is NOT null, use new countryCode
        if (key == "phone" && data.buyer.phone.countryCode == null) {
          data.buyer.phone.countryCode = order.buyer.phone.countryCode;
        }
      } else {
        if (data.address == null) {
          data.address = {};
        }
        data.address[key] = difference[key];
      }
    }

    log.debug(data);

    try {
      let res = await axios.patch(
        process.env.REACT_APP_SERVER_URL + "api/orders?id=" + order?.id,
        data
      );
      setIsUpdating(false);
      log.debug(res.data);
      onUpdateOrderState(res.data);
      onClose();
      openNotification("success", "Updated");
    } catch (error) {
      // handling api error
      setIsUpdating(false);
      log.error(error);
      openNotification("error", error);
    }
  };

  useEffect(() => {
    setEmail(order?.buyer?.email);
    setName(order?.buyer?.name);
    console.log("pre:", order?.buyer?.phone);
    if (order?.buyer?.phone != null) {
      setPhoneInput(order.buyer.phone.countryCode + order.buyer.phone.number);
      setPhone(order.buyer.phone);
    }
    setTwitterId(order?.buyer?.twitterId);
    setStreet(order?.address?.street);
    setApartment(order?.address?.apartment);
    setCity(order?.address?.city);
    setState(order?.address?.state);
    setZipCode(order?.address?.zipcode);
    setCountry(order?.address?.country);
  }, [order]);

  return (
    // <Drawer
    //   placement={["lg", "xl", "xxl"].includes(currentSize) ? "right" : "bottom"}
    //   width="45%"
    //   height="80%"
    //   onClose={() => {
    //     onClose();
    //   }}
    //   open={true}
    //   key="drawer"
    //   extra={
    //     <Space>
    //       <Button
    //         disabled={isUpdating}
    //         type="default"
    //         onClick={() => {
    //           onClose();
    //         }}
    //       >
    //         cancel
    //       </Button>
    //       <Button
    //         type="primary"
    //         disabled={isUpdating}
    //         onClick={async () => {
    //           try {
    //             await form.validateFields();
    //             if (phone?.number?.length > 0 == false) {
    //               throw new Error("Phone Incomplete");
    //             }

    //             let lhs = initialFormValues;
    //             let rhs = {
    //               name,
    //               email,
    //               phone,
    //               twitterId,
    //               street,
    //               apartment,
    //               state,
    //               city,
    //               zipcode,
    //               country,
    //             };

    //             let difference = diff(lhs, rhs);
    //             if (Object.getOwnPropertyNames(difference).length > 0) {
    //               //has diff
    //               updateOrder(difference);
    //             }
    //           } catch (error) {
    //             openNotification("error", error.message || "Form Incomplete");
    //           }
    //         }}
    //       >
    //         save
    //       </Button>
    //     </Space>
    //   }
    // >

    <IonModal
      isOpen={true}
      cssClass={
        isVerified
          ? `center-round-modal stack-modal ${
              ["lg", "xl", "xxl"].includes(currentSize) == false
                ? "vw90 vh85"
                : "vw50"
            }`
          : `center-round-modal auto-height stack-modal ${
              ["lg", "xl", "xxl"].includes(currentSize) == false
                ? "vw90"
                : "vw50"
            }`
      }
      onDidDismiss={() => {
        onClose();
      }}
      mode="ios"
      swipeToClose
    >
      <Row style={{ overflow: "auto" }}>
        {/* Header */}
        <Row
          justify="space-between"
          align="middle"
          style={{
            width: "100%",
            marginBottom: 20,
            padding: "2rem 2rem 0 2rem",
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 1,
          }}
        >
          <Col>
            {isVerified ? (
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                style={{
                  textAlign: "start",
                  backgroundColor: "#1677FF",
                  borderRadius: "1rem",
                  border: "1px solid gray",
                  color: "white",
                  padding: "0.75rem 1rem 0.75rem 1rem",
                  fontSize: "1rem",
                }}
                onClick={async () => {
                  try {
                    await form.validateFields();
                    if (phone?.number?.length > 0 == false) {
                      throw new Error("Phone Incomplete");
                    }

                    let lhs = initialFormValues;
                    let rhs = {
                      name,
                      email,
                      phone,
                      twitterId,
                      street,
                      apartment,
                      state,
                      city,
                      zipcode,
                      country,
                    };

                    let difference = diff(lhs, rhs);
                    if (Object.getOwnPropertyNames(difference).length > 0) {
                      //has diff
                      updateOrder(difference);
                    }
                  } catch (error) {
                    openNotification(
                      "error",
                      error.message || "Form Incomplete"
                    );
                  }
                }}
              >
                Save
              </motion.button>
            ) : null}
          </Col>
          <Col
            onClick={() => {
              onClose();
            }}
          >
            <IonIcon
              style={{ fontSize: 28, cursor: "pointer" }}
              icon={closeOutline}
            ></IonIcon>
          </Col>
        </Row>

        {!isVerified ? (
          <Row style={{ padding: "0 2rem 2rem 2rem" }}>
            <Verify />
          </Row>
        ) : (
          <>
            <Row style={{ width: "100%", padding: "2rem 2rem 0 2rem" }}>
              <Col span={24}>You may update info below anytime until</Col>
              <Col
                style={{
                  color: "#1677FF",
                  fontFamily: "initial",
                  fontWeight: "bold",
                  fontSize: "1.25rem",
                  marginTop: "0.5rem",
                }}
              >
                {moment(order?.time?.place)
                  .add(
                    parseInt(process.env.REACT_APP_UPDATE_ADDRESS_DEADLINE),
                    "days"
                  )
                  .format("YYYY-MM-DD")}
              </Col>
            </Row>

            <Divider />

            <Form
              form={form}
              layout="vertical"
              className="ion-margin-top"
              style={{ width: "100%", padding: "2rem" }}
              initialValues={initialFormValues}
            >
              {/* Name */}
              <Form.Item
                name={["name"]}
                label="Name / Nickname"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "Cannot be empty",
                  },
                ]}
              >
                <Input
                  size="large"
                  value={name}
                  placeholder="Name / Nickname"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
              {/* Email */}
              <Form.Item
                name={["email"]}
                label="Email"
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Invalid email format",
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "Cannot be empty",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
              {/* Phone */}
              <Form.Item
                name={["phone"]}
                label="Phone"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "Cannot be empty",
                  },
                ]}
              >
                <Row align="middle">
                  <Col>
                    <PhoneInput
                      country="us" // default country is us
                      countryCodeEditable={false}
                      enableSearch
                      inputStyle={{
                        height: "3rem",
                      }}
                      value={phoneInput}
                      onChange={(value, data) => {
                        setPhoneInput(value);
                        let { countryCode, number } = parsePhoneFromObj(
                          value,
                          data
                        );
                        setPhone({ countryCode, number });
                      }}
                    />
                  </Col>
                  <Col style={{ marginLeft: "0.5rem" }}>
                    <Tooltip title="In case we need to contact you about your order">
                      <QuestionCircleOutlined
                        style={{ fontSize: "1rem", color: "gray" }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </Form.Item>
              {/* Twitter */}
              <Form.Item
                name={["twitterId"]}
                label="Twitter ID"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Twitter ID (optional)"
                  value={twitterId}
                  onChange={(e) => {
                    setTwitterId(e.target.value);
                  }}
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>

              {/* Street */}
              <Form.Item
                name={["street"]}
                label="Street Address"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "Cannot be empty",
                  },
                ]}
              >
                <Input
                  size="large"
                  value={street}
                  placeholder="Street Address"
                  onChange={(e) => {
                    setStreet(e.target.value);
                  }}
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
              {/* Apartment */}
              <Form.Item
                name={["apartment"]}
                label="Apt, Building (Optional)"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input
                  size="large"
                  value={apartment}
                  placeholder="Apt, Building (Optional)"
                  onChange={(e) => {
                    setApartment(e.target.value);
                  }}
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
              {/* City & State */}
              <Form.Item
                name={["city"]}
                label="City"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "Cannot be empty",
                  },
                ]}
              >
                <Input
                  size="large"
                  value={city}
                  placeholder="City"
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
              <Form.Item
                name={["state"]}
                label="State"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "Cannot be empty",
                  },
                ]}
              >
                <Input
                  size="large"
                  value={state}
                  placeholder="State"
                  onChange={(e) => {
                    setState(e.target.value);
                  }}
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
              {/* Postal Code & Country */}
              <Form.Item
                name={["zipcode"]}
                label="Zip Code (Optional)"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input
                  size="large"
                  value={zipcode}
                  placeholder="Zip Code (Optional)"
                  onChange={(e) => {
                    setZipCode(e.target.value);
                  }}
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
              <Form.Item
                name={["country"]}
                label="Country"
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "Cannot be empty",
                  },
                ]}
              >
                <Input
                  size="large"
                  value={country}
                  placeholder="Country"
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
            </Form>
          </>
        )}
      </Row>
    </IonModal>
  );
};

export default T;
