import React from "react";

export const Thumb = (props) => {
  const { options, selected, index, onClick } = props;

  return (
    <div
      className={"embla-thumbs__slide".concat(
        selected ? " embla-thumbs__slide--selected" : ""
      )}
    >
      <button
        onClick={onClick}
        type="button"
        className="embla-thumbs__slide__number"
      >
        <img
          className="embla__slide__img"
          style={{objectFit: "contain"}}
          src={`${options?.srcPrefix}${index}.${options?.extension}`}
          // src={`https://picsum.photos/600/350?v=${index}`}
          alt="MixNoggle"
        />
      </button>
    </div>
  );
};
