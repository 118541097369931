import React from "react";

const Frame = ({ fill, stroke, isLeft }) => {
  return (
    // <?xml version="1.0" encoding="UTF-8" standalone="no"?>
    // <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0.00 0.00 1440.00 815.00" transform={isLeft ? "scale(-1, 1)" : "scale(1)"}>
    <g strokeWidth="2.00" fill="none" strokeLinecap="butt">
    <path stroke={stroke} vectorEffect="non-scaling-stroke" d="
      M 964.45 329.39
      L 975.12 328.41
      A 1.84 1.82 12.2 0 0 976.53 327.51
      Q 977.63 325.60 977.77 323.49
      Q 980.70 277.89 983.48 232.34
      C 983.55 231.31 984.08 230.55 983.85 229.44
      A 0.24 0.24 0.0 0 0 983.50 229.28
      Q 977.67 232.59 972.04 231.15"
    />
    <path stroke={stroke} vectorEffect="non-scaling-stroke" d="
      M 1007.05 538.39
      Q 1004.64 538.03 1004.06 537.50
      Q 1003.21 536.73 1002.09 536.67
      Q 1001.58 536.64 1001.15 536.39
      C 990.88 530.38 985.14 518.82 983.55 507.55
      Q 982.44 499.70 981.72 487.00
      Q 981.17 477.40 980.45 467.03
      Q 977.86 429.79 975.41 393.42
      C 974.95 386.63 975.33 377.53 969.13 372.98
      Q 966.75 371.24 963.38 371.07
      Q 950.55 370.44 937.60 369.57
      C 931.22 369.15 927.43 375.81 926.96 381.25
      Q 926.56 386.06 925.58 402.21"
    />
    <path stroke={stroke} vectorEffect="non-scaling-stroke" d="
      M 676.11 538.64
      Q 669.97 534.96 669.82 528.00
      Q 669.56 515.27 669.60 493.46
      Q 669.91 341.35 669.73 215.28
      Q 669.72 207.07 670.14 198.26
      Q 670.34 194.26 671.12 193.38
      Q 672.59 191.72 673.71 189.62"
    />
    <path stroke={stroke} vectorEffect="non-scaling-stroke" d="
      M 691.48 407.78
      L 691.50 433.93"
    />
    <path stroke={stroke} vectorEffect="non-scaling-stroke" d="
      M 881.88 502.63
      Q 884.21 501.71 887.16 502.28
      C 888.21 502.48 888.56 502.15 889.52 502.11
      Q 892.17 502.02 892.58 499.71
      C 892.79 498.48 893.19 497.00 893.19 495.99
      Q 893.27 475.95 893.07 450.00
      Q 893.05 447.32 893.04 443.75
      Q 892.67 351.54 892.41 255.13
      C 892.40 251.99 890.36 247.68 887.35 246.19
      Q 886.09 245.56 883.04 245.25
      Q 791.76 235.80 700.50 226.23
      Q 696.38 225.80 694.04 228.70"
    />
    <path stroke={stroke} vectorEffect="non-scaling-stroke" d="
      M 896.01 244.25
      Q 895.61 256.81 895.94 270.00
      Q 896.22 280.94 896.19 290.01
      Q 895.99 353.34 896.34 418.50
      Q 896.60 465.50 896.55 511.44
      A 0.73 0.73 0.0 0 0 897.25 512.17
      L 898.75 512.24
      A 0.74 0.74 0.0 0 0 899.52 511.50
      L 898.68 242.49
      Q 898.68 241.83 898.13 241.46
      Q 897.24 240.87 895.88 240.79
      Q 894.35 240.71 895.29 241.92
      Q 896.06 242.92 896.01 244.25"
    />
    <path stroke={stroke} vectorEffect="non-scaling-stroke" d="
      M 1116.10 347.92
      Q 1116.49 349.21 1115.81 349.91
      Q 1115.53 350.18 1115.15 350.13
      Q 1114.96 350.10 1114.79 350.00
      Q 1114.33 349.74 1113.80 349.76
      Q 1110.69 349.86 1107.99 350.10
      Q 1107.87 350.11 1107.87 350.23
      Q 1107.86 350.38 1107.74 350.51
      Q 1107.63 350.64 1107.46 350.63
      Q 1102.99 350.44 1098.71 351.24
      Q 1095.64 351.81 1092.09 351.92
      Q 1088.41 352.03 1084.34 352.76
      Q 1079.05 353.70 1073.66 353.95
      Q 1071.80 354.04 1069.90 354.64
      Q 1068.27 355.15 1066.37 355.16
      Q 1064.97 355.17 1063.90 355.50
      Q 1062.73 355.86 1061.56 355.65
      Q 1061.03 355.55 1060.52 355.76
      Q 1059.46 356.21 1058.32 356.13
      Q 1054.64 355.90 1051.43 356.66
      Q 1048.24 357.41 1044.95 356.99
      C 1043.54 356.81 1042.34 357.26 1040.91 357.10
      Q 1040.49 357.06 1040.12 357.27
      C 1038.60 358.17 1037.01 357.73 1035.34 358.10
      C 1033.10 358.61 1030.84 359.13 1028.53 359.14
      Q 1024.50 359.15 1020.49 359.57
      Q 1020.19 359.61 1020.06 359.88
      Q 1019.96 360.07 1019.75 360.08
      C 1017.20 360.19 1014.43 359.75 1012.00 360.74
      C 1010.25 361.44 1008.52 360.91 1006.74 361.27
      Q 1004.50 361.73 1002.10 361.60
      Q 1001.55 361.57 1001.55 362.13
      L 1001.61 398.92"
    />
    <path stroke={stroke} vectorEffect="non-scaling-stroke" d="
      M 1147.77 503.94
      Q 1149.43 503.49 1151.43 503.55
      C 1155.33 503.66 1158.99 503.25 1162.90 503.76
      Q 1164.66 503.99 1164.92 502.23
      Q 1165.48 498.51 1165.19 495.20
      Q 1164.96 492.60 1164.94 489.29
      Q 1164.56 417.01 1163.96 343.25
      Q 1163.94 341.28 1163.94 339.75
      Q 1163.93 307.52 1163.37 281.29
      Q 1163.30 277.86 1160.58 275.33
      Q 1159.14 274.00 1157.21 273.80
      Q 1083.29 266.20 1010.02 258.52
      C 1008.08 258.31 1005.16 258.12 1003.77 259.73"
    />
    <path stroke={stroke} vectorEffect="non-scaling-stroke" d="
      M 1169.0410 270.4733
      A 0.67 0.67 0.0 0 0 1168.3663 269.8080
      L 1166.9263 269.8180
      A 0.67 0.67 0.0 0 0 1166.2610 270.4927
      L 1167.9590 513.7067
      A 0.67 0.67 0.0 0 0 1168.6337 514.3720
      L 1170.0737 514.3620
      A 0.67 0.67 0.0 0 0 1170.7390 513.6873
      L 1169.0410 270.4733"
    />
    <path stroke={stroke} vectorEffect="non-scaling-stroke" d="
      M 1140.91 358.85
      Q 1141.03 367.18 1141.10 375.83
      A 0.92 0.92 0.0 0 0 1141.98 376.74
      L 1143.69 376.81
      Q 1144.47 376.84 1144.47 376.06
      L 1144.49 357.27
      A 0.25 0.24 4.1 0 0 1144.27 357.03
      L 1139.07 356.47
      Q 1138.58 356.42 1138.11 356.58
      Q 1136.39 357.19 1134.51 357.13
      Q 1130.05 357.01 1126.02 357.27
      Q 1116.10 357.93 1105.75 358.43
      Q 1098.30 358.80 1091.78 359.65
      Q 1091.60 359.68 1091.61 359.85
      L 1091.62 360.12
      Q 1091.63 360.48 1092.00 360.46
      L 1137.90 358.03
      Q 1138.42 358.00 1138.87 357.74
      C 1139.83 357.17 1140.89 357.76 1140.91 358.85"
    />
    </g>
    <path fill={fill} d="
      M 964.45 329.39
      L 975.12 328.41
      A 1.84 1.82 12.2 0 0 976.53 327.51
      Q 977.63 325.60 977.77 323.49
      Q 980.70 277.89 983.48 232.34
      C 983.55 231.31 984.08 230.55 983.85 229.44
      A 0.24 0.24 0.0 0 0 983.50 229.28
      Q 977.67 232.59 972.04 231.15
      Q 973.60 226.86 977.01 226.51
      Q 981.25 226.06 985.50 225.68
      Q 989.74 225.30 993.90 225.78
      Q 1085.96 236.48 1176.73 246.98
      C 1182.24 247.62 1183.20 254.23 1183.23 258.54
      Q 1184.06 391.74 1185.08 529.03
      C 1185.10 532.26 1183.48 538.18 1179.25 538.18
      Q 1093.14 538.27 1007.05 538.39
      Q 1004.64 538.03 1004.06 537.50
      Q 1003.21 536.73 1002.09 536.67
      Q 1001.58 536.64 1001.15 536.39
      C 990.88 530.38 985.14 518.82 983.55 507.55
      Q 982.44 499.70 981.72 487.00
      Q 981.17 477.40 980.45 467.03
      Q 977.86 429.79 975.41 393.42
      C 974.95 386.63 975.33 377.53 969.13 372.98
      Q 966.75 371.24 963.38 371.07
      Q 950.55 370.44 937.60 369.57
      C 931.22 369.15 927.43 375.81 926.96 381.25
      Q 926.56 386.06 925.58 402.21
      Q 922.48 452.30 919.23 501.74
      C 918.32 515.57 911.52 532.73 897.56 537.42
      Q 894.24 538.53 890.00 538.53
      Q 782.97 538.52 676.11 538.64
      Q 669.97 534.96 669.82 528.00
      Q 669.56 515.27 669.60 493.46
      Q 669.91 341.35 669.73 215.28
      Q 669.72 207.07 670.14 198.26
      Q 670.34 194.26 671.12 193.38
      Q 672.59 191.72 673.71 189.62
      C 675.44 189.35 676.80 189.02 678.59 189.23
      Q 788.02 202.03 909.58 216.12
      Q 911.27 216.32 912.94 217.66
      Q 916.36 220.43 916.74 225.82
      Q 920.05 272.67 923.41 319.90
      C 923.72 324.19 926.13 329.04 931.00 329.41
      Q 946.61 330.59 962.34 331.79
      A 0.92 0.92 0.0 0 0 963.20 331.36
      L 964.45 329.39
      Z
      M 694.04 228.70
      Q 691.63 232.40 691.62 237.00
      Q 691.58 322.31 691.48 407.78
      L 691.50 433.93
      Q 691.48 463.88 691.49 493.75
      Q 691.50 499.50 696.37 503.54
      Q 696.96 504.03 697.73 504.14
      Q 698.70 504.27 699.60 504.28
      Q 774.28 505.37 876.61 506.30
      Q 878.44 506.32 879.56 505.47
      Q 880.88 504.47 881.88 502.63
      Q 884.21 501.71 887.16 502.28
      C 888.21 502.48 888.56 502.15 889.52 502.11
      Q 892.17 502.02 892.58 499.71
      C 892.79 498.48 893.19 497.00 893.19 495.99
      Q 893.27 475.95 893.07 450.00
      Q 893.05 447.32 893.04 443.75
      Q 892.67 351.54 892.41 255.13
      C 892.40 251.99 890.36 247.68 887.35 246.19
      Q 886.09 245.56 883.04 245.25
      Q 791.76 235.80 700.50 226.23
      Q 696.38 225.80 694.04 228.70
      Z
      M 896.01 244.25
      Q 895.61 256.81 895.94 270.00
      Q 896.22 280.94 896.19 290.01
      Q 895.99 353.34 896.34 418.50
      Q 896.60 465.50 896.55 511.44
      A 0.73 0.73 0.0 0 0 897.25 512.17
      L 898.75 512.24
      A 0.74 0.74 0.0 0 0 899.52 511.50
      L 898.68 242.49
      Q 898.68 241.83 898.13 241.46
      Q 897.24 240.87 895.88 240.79
      Q 894.35 240.71 895.29 241.92
      Q 896.06 242.92 896.01 244.25
      Z
      M 1003.77 259.73
      Q 1001.02 263.20 1001.04 266.75
      Q 1001.27 313.25 1001.46 359.66
      Q 1001.46 360.06 1001.87 360.02
      L 1116.10 347.92
      Q 1116.49 349.21 1115.81 349.91
      Q 1115.53 350.18 1115.15 350.13
      Q 1114.96 350.10 1114.79 350.00
      Q 1114.33 349.74 1113.80 349.76
      Q 1110.69 349.86 1107.99 350.10
      Q 1107.87 350.11 1107.87 350.23
      Q 1107.86 350.38 1107.74 350.51
      Q 1107.63 350.64 1107.46 350.63
      Q 1102.99 350.44 1098.71 351.24
      Q 1095.64 351.81 1092.09 351.92
      Q 1088.41 352.03 1084.34 352.76
      Q 1079.05 353.70 1073.66 353.95
      Q 1071.80 354.04 1069.90 354.64
      Q 1068.27 355.15 1066.37 355.16
      Q 1064.97 355.17 1063.90 355.50
      Q 1062.73 355.86 1061.56 355.65
      Q 1061.03 355.55 1060.52 355.76
      Q 1059.46 356.21 1058.32 356.13
      Q 1054.64 355.90 1051.43 356.66
      Q 1048.24 357.41 1044.95 356.99
      C 1043.54 356.81 1042.34 357.26 1040.91 357.10
      Q 1040.49 357.06 1040.12 357.27
      C 1038.60 358.17 1037.01 357.73 1035.34 358.10
      C 1033.10 358.61 1030.84 359.13 1028.53 359.14
      Q 1024.50 359.15 1020.49 359.57
      Q 1020.19 359.61 1020.06 359.88
      Q 1019.96 360.07 1019.75 360.08
      C 1017.20 360.19 1014.43 359.75 1012.00 360.74
      C 1010.25 361.44 1008.52 360.91 1006.74 361.27
      Q 1004.50 361.73 1002.10 361.60
      Q 1001.55 361.57 1001.55 362.13
      L 1001.61 398.92
      Q 1001.90 448.91 1002.09 498.00
      C 1002.11 501.60 1004.23 507.70 1008.54 507.75
      C 1054.65 508.25 1099.33 508.91 1144.17 509.13
      Q 1144.69 509.13 1144.95 508.68
      L 1147.77 503.94
      Q 1149.43 503.49 1151.43 503.55
      C 1155.33 503.66 1158.99 503.25 1162.90 503.76
      Q 1164.66 503.99 1164.92 502.23
      Q 1165.48 498.51 1165.19 495.20
      Q 1164.96 492.60 1164.94 489.29
      Q 1164.56 417.01 1163.96 343.25
      Q 1163.94 341.28 1163.94 339.75
      Q 1163.93 307.52 1163.37 281.29
      Q 1163.30 277.86 1160.58 275.33
      Q 1159.14 274.00 1157.21 273.80
      Q 1083.29 266.20 1010.02 258.52
      C 1008.08 258.31 1005.16 258.12 1003.77 259.73
      Z
      M 1169.0410 270.4733
      A 0.67 0.67 0.0 0 0 1168.3663 269.8080
      L 1166.9263 269.8180
      A 0.67 0.67 0.0 0 0 1166.2610 270.4927
      L 1167.9590 513.7067
      A 0.67 0.67 0.0 0 0 1168.6337 514.3720
      L 1170.0737 514.3620
      A 0.67 0.67 0.0 0 0 1170.7390 513.6873
      L 1169.0410 270.4733
      Z"
    />
    <path fill={fill} d="
      M 673.71 189.62
      Q 672.59 191.72 671.12 193.38
      Q 670.34 194.26 670.14 198.26
      Q 669.72 207.07 669.73 215.28
      Q 669.91 341.35 669.60 493.46
      Q 669.56 515.27 669.82 528.00
      Q 669.97 534.96 676.11 538.64
      Q 667.57 538.75 659.08 538.59
      Q 655.84 538.53 654.14 537.32
      C 650.68 534.87 649.40 530.72 649.41 526.59
      Q 649.50 498.22 649.50 471.95
      A 0.23 0.23 0.0 0 0 649.15 471.75
      Q 641.74 476.28 632.67 482.39
      C 629.93 484.23 626.72 484.44 623.62 483.79
      Q 620.18 483.07 618.66 479.09
      Q 616.59 473.67 614.23 467.05
      C 612.73 462.86 613.81 458.58 617.51 456.22
      Q 633.33 446.14 649.10 435.80
      Q 649.53 435.52 649.53 435.00
      Q 649.48 404.76 649.65 376.55
      Q 649.69 370.46 646.25 365.44
      Q 645.97 365.03 645.47 365.02
      Q 636.66 364.87 627.18 364.08
      C 626.02 363.98 625.02 364.34 623.87 364.39
      Q 596.08 365.61 567.00 367.18
      Q 477.52 372.01 425.51 375.07
      Q 328.70 380.75 229.25 387.00
      Q 209.59 388.24 194.57 400.36
      C 189.89 404.13 185.54 407.83 181.35 412.16
      C 175.26 418.45 172.73 425.91 169.95 434.14
      C 169.01 436.91 167.13 438.18 165.13 440.12
      Q 147.80 456.81 130.39 473.51
      Q 128.43 475.39 125.77 475.51
      C 120.98 475.73 116.84 475.10 115.61 469.56
      Q 113.78 461.32 112.31 453.93
      C 111.42 449.46 112.96 445.59 116.23 442.37
      C 128.35 430.42 140.28 418.09 152.85 406.22
      Q 167.09 392.79 180.68 379.93
      Q 185.56 375.32 190.42 371.69
      Q 200.01 364.53 210.89 361.07
      Q 216.71 359.22 230.41 357.45
      Q 330.55 344.50 432.50 331.25
      Q 501.87 322.24 617.86 309.44
      Q 618.35 309.39 618.64 308.98
      Q 619.68 307.52 621.51 307.37
      Q 631.23 306.59 639.81 305.94
      C 644.53 305.57 647.89 300.58 648.93 296.36
      Q 649.68 293.31 649.68 288.68
      Q 649.70 245.93 649.81 203.18
      C 649.83 196.58 651.78 192.25 658.59 191.37
      Q 666.16 190.40 673.71 189.62
      Z"
    />
    <path fill={fill} d="
      M 881.88 502.63
      Q 883.58 498.62 883.40 493.90
      Q 883.32 491.79 883.31 489.94
      Q 883.21 447.64 883.13 404.58
      Q 883.13 403.95 882.49 403.97
      Q 817.75 406.80 752.17 409.49
      Q 741.17 409.94 733.68 411.74
      Q 721.04 414.76 711.12 420.46
      Q 703.52 424.84 695.18 430.67
      Q 693.15 432.09 691.50 433.93
      L 691.48 407.78
      Q 703.22 399.72 708.02 396.99
      Q 724.96 387.34 744.12 385.47
      Q 812.81 378.77 882.50 372.00
      Q 883.05 371.94 883.05 371.39
      Q 883.01 314.60 882.69 256.70
      C 882.67 252.54 880.13 246.87 875.20 246.36
      Q 791.15 237.66 698.49 229.14
      Q 696.25 228.93 694.04 228.70
      Q 696.38 225.80 700.50 226.23
      Q 791.76 235.80 883.04 245.25
      Q 886.09 245.56 887.35 246.19
      C 890.36 247.68 892.40 251.99 892.41 255.13
      Q 892.67 351.54 893.04 443.75
      Q 893.05 447.32 893.07 450.00
      Q 893.27 475.95 893.19 495.99
      C 893.19 497.00 892.79 498.48 892.58 499.71
      Q 892.17 502.02 889.52 502.11
      C 888.56 502.15 888.21 502.48 887.16 502.28
      Q 884.21 501.71 881.88 502.63
      Z"
    />
    <path fill={fill} d="
      M 964.45 329.39
      Q 965.37 326.46 965.58 323.00
      Q 968.31 279.37 971.07 235.75
      Q 971.28 232.48 972.04 231.15
      Q 977.67 232.59 983.50 229.28
      A 0.24 0.24 0.0 0 1 983.85 229.44
      C 984.08 230.55 983.55 231.31 983.48 232.34
      Q 980.70 277.89 977.77 323.49
      Q 977.63 325.60 976.53 327.51
      A 1.84 1.82 12.2 0 1 975.12 328.41
      L 964.45 329.39
      Z"
    />
    <path fill={fill} d="
      M 896.01 244.25
      Q 896.06 242.92 895.29 241.92
      Q 894.35 240.71 895.88 240.79
      Q 897.24 240.87 898.13 241.46
      Q 898.68 241.83 898.68 242.49
      L 899.52 511.50
      A 0.74 0.74 0.0 0 1 898.75 512.24
      L 897.25 512.17
      A 0.73 0.73 0.0 0 1 896.55 511.44
      Q 896.60 465.50 896.34 418.50
      Q 895.99 353.34 896.19 290.01
      Q 896.22 280.94 895.94 270.00
      Q 895.61 256.81 896.01 244.25
      Z"
    />
    <path fill={fill} d="
      M 1147.77 503.94
      Q 1147.51 423.36 1147.18 401.50
      Q 1147.11 396.90 1142.91 392.79
      A 1.25 1.23 65.5 0 0 1142.01 392.45
      L 1001.61 398.92
      L 1001.55 362.13
      Q 1001.55 361.57 1002.10 361.60
      Q 1004.50 361.73 1006.74 361.27
      C 1008.52 360.91 1010.25 361.44 1012.00 360.74
      C 1014.43 359.75 1017.20 360.19 1019.75 360.08
      Q 1019.96 360.07 1020.06 359.88
      Q 1020.19 359.61 1020.49 359.57
      Q 1024.50 359.15 1028.53 359.14
      C 1030.84 359.13 1033.10 358.61 1035.34 358.10
      C 1037.01 357.73 1038.60 358.17 1040.12 357.27
      Q 1040.49 357.06 1040.91 357.10
      C 1042.34 357.26 1043.54 356.81 1044.95 356.99
      Q 1048.24 357.41 1051.43 356.66
      Q 1054.64 355.90 1058.32 356.13
      Q 1059.46 356.21 1060.52 355.76
      Q 1061.03 355.55 1061.56 355.65
      Q 1062.73 355.86 1063.90 355.50
      Q 1064.97 355.17 1066.37 355.16
      Q 1068.27 355.15 1069.90 354.64
      Q 1071.80 354.04 1073.66 353.95
      Q 1079.05 353.70 1084.34 352.76
      Q 1088.41 352.03 1092.09 351.92
      Q 1095.64 351.81 1098.71 351.24
      Q 1102.99 350.44 1107.46 350.63
      Q 1107.63 350.64 1107.74 350.51
      Q 1107.86 350.38 1107.87 350.23
      Q 1107.87 350.11 1107.99 350.10
      Q 1110.69 349.86 1113.80 349.76
      Q 1114.33 349.74 1114.79 350.00
      Q 1114.96 350.10 1115.15 350.13
      Q 1115.53 350.18 1115.81 349.91
      Q 1116.49 349.21 1116.10 347.92
      Q 1116.15 344.94 1119.24 344.73
      Q 1128.24 344.13 1137.25 343.57
      C 1143.73 343.17 1146.81 336.47 1146.77 330.57
      Q 1146.64 305.76 1146.43 285.06
      Q 1146.38 279.81 1142.82 275.40
      Q 1142.51 275.01 1142.01 274.95
      L 1003.77 259.73
      C 1005.16 258.12 1008.08 258.31 1010.02 258.52
      Q 1083.29 266.20 1157.21 273.80
      Q 1159.14 274.00 1160.58 275.33
      Q 1163.30 277.86 1163.37 281.29
      Q 1163.93 307.52 1163.94 339.75
      Q 1163.94 341.28 1163.96 343.25
      Q 1164.56 417.01 1164.94 489.29
      Q 1164.96 492.60 1165.19 495.20
      Q 1165.48 498.51 1164.92 502.23
      Q 1164.66 503.99 1162.90 503.76
      C 1158.99 503.25 1155.33 503.66 1151.43 503.55
      Q 1149.43 503.49 1147.77 503.94
      Z
      M 1140.91 358.85
      Q 1141.03 367.18 1141.10 375.83
      A 0.92 0.92 0.0 0 0 1141.98 376.74
      L 1143.69 376.81
      Q 1144.47 376.84 1144.47 376.06
      L 1144.49 357.27
      A 0.25 0.24 4.1 0 0 1144.27 357.03
      L 1139.07 356.47
      Q 1138.58 356.42 1138.11 356.58
      Q 1136.39 357.19 1134.51 357.13
      Q 1130.05 357.01 1126.02 357.27
      Q 1116.10 357.93 1105.75 358.43
      Q 1098.30 358.80 1091.78 359.65
      Q 1091.60 359.68 1091.61 359.85
      L 1091.62 360.12
      Q 1091.63 360.48 1092.00 360.46
      L 1137.90 358.03
      Q 1138.42 358.00 1138.87 357.74
      C 1139.83 357.17 1140.89 357.76 1140.91 358.85
      Z"
    />
    <rect fill={fill} x="-1.39" y="-122.28" transform="translate(1168.50,392.09) rotate(-0.4)" width="2.78" height="244.56" rx="0.67"/>
    <path fill={fill} d="
      M 1140.91 358.85
      C 1140.89 357.76 1139.83 357.17 1138.87 357.74
      Q 1138.42 358.00 1137.90 358.03
      L 1092.00 360.46
      Q 1091.63 360.48 1091.62 360.12
      L 1091.61 359.85
      Q 1091.60 359.68 1091.78 359.65
      Q 1098.30 358.80 1105.75 358.43
      Q 1116.10 357.93 1126.02 357.27
      Q 1130.05 357.01 1134.51 357.13
      Q 1136.39 357.19 1138.11 356.58
      Q 1138.58 356.42 1139.07 356.47
      L 1144.27 357.03
      A 0.25 0.24 4.1 0 1 1144.49 357.27
      L 1144.47 376.06
      Q 1144.47 376.84 1143.69 376.81
      L 1141.98 376.74
      A 0.92 0.92 0.0 0 1 1141.10 375.83
      Q 1141.03 367.18 1140.91 358.85
      Z"
    />
    <path fill={fill} d="
      M 1007.05 538.39
      Q 1001.65 538.68 996.32 538.23
      Q 991.35 537.81 988.11 535.97
      Q 981.73 532.34 977.58 525.40
      C 972.44 516.78 970.76 507.94 970.10 497.79
      Q 966.93 449.43 963.53 400.94
      A 0.39 0.38 -2.3 0 0 963.13 400.58
      L 925.58 402.21
      Q 926.56 386.06 926.96 381.25
      C 927.43 375.81 931.22 369.15 937.60 369.57
      Q 950.55 370.44 963.38 371.07
      Q 966.75 371.24 969.13 372.98
      C 975.33 377.53 974.95 386.63 975.41 393.42
      Q 977.86 429.79 980.45 467.03
      Q 981.17 477.40 981.72 487.00
      Q 982.44 499.70 983.55 507.55
      C 985.14 518.82 990.88 530.38 1001.15 536.39
      Q 1001.58 536.64 1002.09 536.67
      Q 1003.21 536.73 1004.06 537.50
      Q 1004.64 538.03 1007.05 538.39
      Z"
    />
    </svg>
  );
};

export default Frame;
