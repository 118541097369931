const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const shortenAddress = (address, chars = 4) => {
  if (!address || typeof address !== "string" || address.length < chars) {
    return address;
  }

  const prefix = address.slice(0, 2 + chars);
  const suffix = address.slice(-4);

  return `${prefix}...${suffix}`;
};

const parseHexToFloat = (hex) => {
  const contractDecimal = 18;
  return parseInt(hex, 16) / Math.pow(10, contractDecimal);
};

const getDiscountedPrice = (paymentMethod, discount, price) => {
  const discountedPrice =
    paymentMethod == "CARD" || discount == null ? price : price * discount;

  // Round the result to avoid floating-point issues
  return Math.round(discountedPrice * 100) / 100;
};

const getDisplayDiscount = (discount) => {
  return 100 - parseInt(discount * 100);
};

const parseQueryToJSON = (queryString) => {
  return Object.fromEntries(new URLSearchParams(queryString).entries());
};

const parsePhoneFromObj = (unformattedPhone, obj) => {
  let number;
  if (obj?.dialCode != null) {
    number = unformattedPhone.slice(obj.dialCode.length);
  }

  return {
    countryCode: obj?.dialCode || null,
    number: number.length > 0 ? number : null,
  };
};

const isAddressSame = (addr1, addr2) => {
  return (
    addr1 != null && addr2 != null && addr1.toLowerCase() == addr2.toLowerCase()
  );
};

const generateOTP = () => {
  return shuffle("0123456789".split("")).join("").substring(0, 6);
  function shuffle(o) {
    for (
      var j, x, i = o.length;
      i;
      j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x
    );
    return o;
  }
};

export {
  sleep,
  shortenAddress,
  parseHexToFloat,
  getDiscountedPrice,
  getDisplayDiscount,
  parseQueryToJSON,
  parsePhoneFromObj,
  isAddressSame,
  generateOTP,
};
