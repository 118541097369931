import React from "react";

const Lense = ({ fill, stroke }) => {
  return (
    // <?xml version="1.0" encoding="UTF-8" standalone="no"?>
    // <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0.00 0.00 1440.00 815.00"
    >
      <path
        fill="#e8e8e8"
        fillOpacity="0.953"
        d="
      M 579.13 243.34
      L 578.49 521.20
      L 460.08 520.92
      Q 460.81 517.44 460.88 513.75
      Q 460.98 508.19 460.88 502.92
      Q 460.88 502.50 460.72 502.14
      Q 460.52 501.69 460.88 501.29
      A 0.87 0.86 -24.8 0 0 461.11 500.68
      L 460.96 286.47
      L 461.23 232.84
      Q 461.23 232.53 461.54 232.56
      L 579.13 243.34
      Z"
      />
      <path
        fill="lightgray"
        fillOpacity="0.953"
        d="
      M 579.13 243.34
      L 688.66 253.34
      Q 689.50 253.42 689.50 254.26
      L 689.28 521.70
      A 0.32 0.31 0.0 0 1 688.96 522.01
      L 578.49 521.20
      L 579.13 243.34
      Z"
      />
      <path
        fill="#e8e8e8"
        fillOpacity="0.953"
        d="
      M 889.22 272.78
      L 889.88 522.97
      L 794.45 522.44
      L 794.32 522.01
      Q 794.25 521.78 794.25 521.54
      Q 794.14 393.77 794.10 266.00
      Q 794.10 265.12 793.54 264.54
      Q 793.46 264.30 793.52 264.24
      A 0.76 0.76 0.0 0 1 794.16 264.00
      Q 836.95 267.87 879.65 271.64
      Q 884.52 272.07 889.22 272.78
      Z"
      />
      <path
        fill="lightgray"
        fillOpacity="0.953"
        d="
      M 793.54 264.54
      Q 794.10 265.12 794.10 266.00
      Q 794.14 393.77 794.25 521.54
      Q 794.25 521.78 794.32 522.01
      L 794.45 522.44
      Q 794.06 522.66 794.05 521.65
      Q 793.69 393.10 793.54 264.54
      Z"
      />
      <path
        fill="lightgray"
        fillOpacity="0.953"
        d="
      M 889.22 272.78
      L 977.92 280.78
      Q 978.43 280.82 978.43 281.34
      L 979.38 522.70
      Q 979.38 523.32 978.76 523.32
      L 889.88 522.97
      L 889.22 272.78
      Z"
      />
      <path
        fill="lightgray"
        fillOpacity="0.953"
        d="
      M 460.96 286.47
      L 461.11 500.68
      A 0.87 0.86 -24.8 0 1 460.88 501.29
      Q 460.52 501.69 460.72 502.14
      Q 460.88 502.50 460.88 502.92
      Q 460.98 508.19 460.88 513.75
      Q 460.81 517.44 460.08 520.92
      L 460.96 286.47
      Z"
      />
    </svg>
  );
};

export default Lense;
