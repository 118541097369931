import {
  ConnectWallet,
  //wallet connect UI
  lightTheme,
} from "@thirdweb-dev/react";

import { Row, Col, Input, Button } from "antd";
import "../styles/global.css";

const WalletV3 = ({}) => {
  return (
    <ConnectWallet
      theme={lightTheme({
        colors: {
          accentText: "#dd55cb",
          accentButtonBg: "#dd55cb",
          separatorLine: "#EEEEEE",
          borderColor: "#EEEEEE",
        },
      })}
      modalTitle={"Welcome to MixNoggle"}
      switchToActiveChain={true}
      modalSize={"wide"}
      className="connect-btn"
      welcomeScreen={() => {
        return (
          <Row style={{ width: "100%", height: "100%" }}>
            <img
              src={`${process.env.REACT_APP_S3_PREFIX}/other/walletModel.png`}
              height="100%"
              width="100%"
              style={{
                objectFit: "cover",
                objectPosition: "center top",
              }}
            />
          </Row>
        );
      }}
      showThirdwebBranding={false}
      modalTitleIconUrl={"/android-chrome-192x192.png"}
    />
  );
};

export default WalletV3;
