import { useState, useMemo, useEffect } from "react";

import {
  //hook
  useAddress,
} from "@thirdweb-dev/react";

import { Row, Col, Input, Button, Divider, Form } from "antd";

import { IonModal, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import WalletV3 from "../components/Wallet";
import useBreakPoint from "../hooks/useBreakPoint";
import { shortenAddress } from "../utils/general";

//global state
import useStore from "../states/useStore";

import { motion } from "framer-motion";

const useLogin = () => {
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);
  const [isShowEmailModal, setIsShowEmailModal] = useState(false);
  const address = useAddress();
  const { loginEmail, config, updateLoginEmail, updateReferral, clearItems } = useStore();
  const [loginForm] = Form.useForm();

  const auth = useMemo(() => {
    return {
      isLoggedIn: address != null || loginEmail != null,
      wallet: address,
      email: loginEmail,
    };
  }, [address, loginEmail]);

  const LoginModal = ({isReferral}) => {
    const [currentSize, BreakPoint] = useBreakPoint();

    return (
      <IonModal
        isOpen={isShowLoginModal}
        cssClass={
          ["lg", "xl", "xxl"].includes(currentSize) == false
            ? "center-round-modal auto-height stack-modal vw90"
            : "center-round-modal auto-height stack-modal"
        }
        onDidDismiss={() => {
          setIsShowLoginModal(false);
        }}
        mode="ios"
        swipeToClose
      >
        <Row
          style={{ width: "100%", padding: 20, height: "auto" }}
          className="inner-content"
        >
          {/* Header */}
          <Row
            justify="space-between"
            style={{ width: "100%", marginBottom: 20 }}
          >
            <Col style={{ fontSize: 22 }}></Col>
            <Col
              onClick={() => {
                setIsShowLoginModal(false);
              }}
            >
              <IonIcon
                style={{ fontSize: 28, cursor: "pointer" }}
                icon={closeOutline}
              ></IonIcon>
            </Col>
          </Row>

          <Row justify="center" style={{ width: "100%" }}>
            <img
              src={`${process.env.REACT_APP_S3_PREFIX}/other/logo/logo-black-wide.png`}
              style={{
                objectFit: "contain",
                width: "7rem",
                aspectRatio: 16 / 9,
              }}
            />
          </Row>

          {/* <Row
            justify="center"
            style={{ width: "100%", marginTop: "2rem", fontSize: "1.25rem" }}
          >
            Welcome to MixNoggle
          </Row> */}

          <Row
            justify="center"
            style={{ width: "100%", marginTop: "3rem" }}
            onClick={() => {
              setIsShowLoginModal(false);
            }}
          >
            <WalletV3 />
          </Row>

          {isReferral || config?.hideEmailLogin == null || config?.hideEmailLogin ? null : (
            <>
              <Divider plain>or</Divider>

              <Row justify="center" style={{ width: "100%" }}>
                <Row>
                  <Col span={24}>
                    <Form form={loginForm}>
                      <Form.Item
                        name={["email"]}
                        rules={[
                          {
                            required: true,
                            type: "email",
                            message: "Invalid email format",
                          },
                          {
                            pattern: /^(?!\s*$).+/,
                            message: "Cannot be empty",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Continue with email"
                          style={{
                            width: "100%",
                            fontFamily: "BlinkMacSystemFont, sans-serif",
                          }}
                        />
                      </Form.Item>
                    </Form>
                  </Col>

                  <Col span={24} style={{ textAlign: "end" }}>
                    <Button
                      size="large"
                      style={{
                        width: "100%",
                        marginTop: "1rem",
                        fontSize: "1rem",
                      }}
                      type="primary"
                      onClick={async () => {
                        try {
                          await loginForm.validateFields();
                          setIsShowLoginModal(false);
                          updateLoginEmail(
                            loginForm.getFieldValue("email").toLowerCase()
                          );

                          loginForm.resetFields();
                          //email user cannot use referral code
                          updateReferral();
                        } catch (error) {
                          console.log(error);
                        }
                      }}
                    >
                      continue
                    </Button>
                  </Col>
                </Row>
              </Row>
            </>
          )}
        </Row>
      </IonModal>
    );
  };

  const LoginButton = () => {
    return (
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        style={{
          backgroundColor: "black",
          color: "white",
          padding: "1rem 3rem 1rem 3rem",
          borderRadius: "0.5rem",
          fontSize: "1rem",
        }}
        onClick={() => {
          setIsShowLoginModal(true);
        }}
      >
        Connect
      </motion.button>
    );
  };

  const Login = ({isReferral}) => {
    return (
      <>
        <LoginModal isReferral={isReferral}/>

        <EmailModal />

        {auth?.isLoggedIn ? (
          auth?.email == null ? (
            <div style={{ marginTop: "1rem" }}>
              <WalletV3 />
            </div>
          ) : auth?.address == null ? (
            <div style={{ marginTop: "1rem" }}>
              <Email />
            </div>
          ) : null
        ) : (
          <LoginButton />
        )}
      </>
    );
  };

  const Email = () => {
    return (
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        style={{
          backgroundColor: "white",
          padding: "1rem",
          borderRadius: "0.5rem",
          border: "1px solid lightgray",
          fontSize: "1rem",
        }}
        onClick={() => {
          setIsShowEmailModal(true);
        }}
      >
        <Row align="middle">
          <img
            style={{ width: "2rem", marginRight: "1rem" }}
            src="android-chrome-192x192.png"
          />
          {shortenAddress(auth?.email)}
        </Row>
      </motion.button>
    );
  };

  const EmailModal = () => {
    const [currentSize, BreakPoint] = useBreakPoint();

    return (
      <IonModal
        isOpen={isShowEmailModal}
        cssClass={
          ["lg", "xl", "xxl"].includes(currentSize) == false
            ? "center-round-modal auto-height stack-modal vw90"
            : "center-round-modal auto-height stack-modal"
        }
        onDidDismiss={() => {
          setIsShowEmailModal(false);
        }}
        mode="ios"
        swipeToClose
      >
        <Row
          style={{ width: "100%", padding: 20, height: "auto" }}
          className="inner-content"
        >
          {/* Header */}
          <Row
            justify="space-between"
            style={{ width: "100%", marginBottom: 20 }}
          >
            <Col style={{ fontSize: 22 }}></Col>
            <Col
              onClick={() => {
                setIsShowEmailModal(false);
              }}
            >
              <IonIcon
                style={{ fontSize: 28, cursor: "pointer" }}
                icon={closeOutline}
              ></IonIcon>
            </Col>
          </Row>

          <Row justify="center" style={{ width: "100%" }}>
            <img
              src="/android-chrome-192x192.png"
              style={{ width: "7.5rem", height: "7.5rem" }}
            />
          </Row>

          <Row
            justify="center"
            style={{
              width: "100%",
              marginTop: "2rem",
              fontSize: "1rem",
              color: "gray",
            }}
          >
            {auth?.email}
          </Row>

          <Button
            size="large"
            style={{
              width: "100%",
              marginTop: "1rem",
              fontSize: "1rem",
              backgroundColor: "black",
            }}
            type="primary"
            onClick={() => {
              setIsShowEmailModal(false);
              updateLoginEmail(null);
            }}
          >
            Logout
          </Button>
        </Row>
      </IonModal>
    );
  };

  return [auth, Login];
};

export default useLogin;
