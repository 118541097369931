import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { IonPage, IonContent } from "@ionic/react";
import { Row, Col, Button, Form, Input, notification } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { motion } from "framer-motion";

import useBreakPoint from "../hooks/useBreakPoint";

//utils
import log from "../utils/log";
import { parseQueryToJSON } from "../utils/general";

import axios from "axios";
import moment from "moment";

import "../styles/global.css";

const T = () => {
  const history = useHistory();
  const location = useLocation();
  const [id, setId] = useState();
  const [notificationAPI, contextHolder] = notification.useNotification();
  const [currentSize, BreakPoint] = useBreakPoint();
  const [order, setOrder] = useState();

  const openNotification = (type, title, msg) => {
    notificationAPI[type]({
      message: title,
      description: msg,
      duration: 5,
    });
  };

  const getOrder = async (id) => {
    try {
      let res = await axios.get(
        process.env.REACT_APP_SERVER_URL + "api/orders?id=" + id
      );
      log.debug(res.data);
      setOrder(res.data);
      return res.data;
    } catch (error) {
      log.error(error);
      setOrder(false);
      return null;
    }
  };

  useEffect(() => {
    const queryParams = parseQueryToJSON(location.search);
    const { orderId } = queryParams;
    console.log(orderId);
    setId(orderId);
    getOrder(orderId);
  }, []);

  return (
    <IonPage>
      <IonContent
        style={
          ["lg", "xl", "xxl"].includes(currentSize)
            ? {
                "--ion-background-color": "white",
                width: "$width",
                height: "$height",
                "--padding-start": "10%",
                "--padding-end": "10%",
              }
            : {
                "--ion-background-color": "white",
                width: "100%",
                height: "100vh",
              }
        }
      >
        {contextHolder}
        <div style={{ width: "100%", height: "100%", padding: "2rem" }}>
          <Row justify="start" align="middle" style={{ width: "100%" }}>
            <Col span={6} lg={2}>
              <img
                style={{ width: "100%", cursor: "pointer" }}
                src={`${process.env.REACT_APP_S3_PREFIX}/other/logo/logo-black-wide.png`}
                onClick={() => {
                  history.push("/market");
                }}
              />
            </Col>

            <Col style={{ fontSize: "2rem", marginLeft: "2rem" }}>
              <Col span={24}>Your Order</Col>
              <Col span={24} style={{ fontSize: "2rem" }} onClick={() => {}}>
                {id}
              </Col>
            </Col>
          </Row>

          <Row justify="center" style={{ width: "100%" }}>
            <Col>
              <img
                style={{ width: "15rem", height: "15rem" }}
                src={
                  process.env.REACT_APP_S3_PREFIX + "/other/box-transparent.png"
                }
              />
            </Col>
          </Row>

          {order == null ? null : order?.payment == null ? (
            <NotSupport />
          ) : order?.redeemlink?.expiry != null &&
            moment().isSameOrAfter(order?.redeemlink?.expiry) ? (
            <Expired />
          ) : order?.redeemlink?.address != null ? (
            <SuccesResult address={order?.redeemlink?.address} />
          ) : (
            <AddressForm
              order={order}
              openNotification={openNotification}
              onSuccess={(order) => {
                setOrder(order);
              }}
            />
          )}

          {/* Back Btn */}
          <Row justify="end">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{
                backgroundColor: "black",
                color: "white",
                padding: "0.75rem 1rem 0.75rem 1rem",
                borderRadius: "0.5rem",
                marginTop: "3rem",
              }}
              onClick={() => {
                history.goBack();
              }}
            >
              <Row
                style={{
                  fontSize: "1rem",
                  padding: "0.5rem",
                }}
              >
                {`Back >`}
              </Row>
            </motion.button>
          </Row>

          <Row style={{ height: "7rem" }}></Row>
        </div>
      </IonContent>
    </IonPage>
  );
};

const AddressForm = ({ order, openNotification, onSuccess }) => {
  const [currentSize, BreakPoint] = useBreakPoint();
  const [addressForm] = Form.useForm();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const submitWalletAddress = async () => {
    setIsLoadingSubmit(true);
    try {
      let fields = await addressForm.getFieldsValue();
      let walletAddress = fields.walletAddress.trim();
      if (walletAddress.length != 42) {
        throw new Error("invalid wallet address");
      }
      let update = {
        redeemlink: { ...order.redeemlink, address: walletAddress },
      };

      let res = await axios.patch(
        process.env.REACT_APP_SERVER_URL + "api/orders?id=" + order?.id,
        update
      );
      setIsLoadingSubmit(false);
      console.log(res);
      onSuccess(res.data);
    } catch (error) {
      setIsLoadingSubmit(false);
      console.log(error);
      openNotification("error", error.response?.data?.error || error.message);
    }
  };
  return (
    <>
      <Row justify="center" style={{ width: "100%" }}>
        <Col
          style={{
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "2rem"
              : "1.5rem",
          }}
        >
          Mint Your NFT
        </Col>
        <Col
          span={24}
          style={{
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "1.25rem"
              : "1rem",
            color: "gray",
            marginTop: "1rem",
            textAlign: "center",
          }}
        >
          Enter your BASE wallet address to mint your NFT
        </Col>
        <Col
          span={24}
          style={{
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "1.25rem"
              : "1rem",
            color: "gray",
            marginTop: "1rem",
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          This link expires at {moment().format("YYYY-MM-DD")}
        </Col>
      </Row>

      <Row justify="center" style={{ width: "100%" }}>
        <Form
          form={addressForm}
          style={{
            width: ["lg", "xl", "xxl"].includes(currentSize) ? "50%" : "100%",
          }}
        >
          <Form.Item
            name="walletAddress"
            rules={[
              {
                pattern: /^(?!\s*$).+/,
                message: "Cannot be empty",
              },
            ]}
          >
            <Input
              size="large"
              placeholder=""
              style={{
                heigth: 150,
                border: "1px solid black",
                fontFamily: "BlinkMacSystemFont, sans-serif",
              }}
            />
          </Form.Item>
        </Form>
      </Row>

      <Row justify="center" style={{ width: "100%" }}>
        <Button
          size="large"
          loading={isLoadingSubmit}
          style={{
            padding: "0 2rem 0 2rem",
            marginTop: "1rem",
            fontSize: "1rem",
          }}
          type="primary"
          onClick={async () => {
            submitWalletAddress();
          }}
        >
          confirm
        </Button>
      </Row>
    </>
  );
};

const SuccesResult = ({ address }) => {
  const [currentSize, BreakPoint] = useBreakPoint();

  return (
    <>
      <Row justify="center" style={{ width: "100%" }}>
        <Col
          style={{
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "2rem"
              : "1.5rem",
          }}
        >
          Yeah!
        </Col>
        <Col
          span={24}
          style={{
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "1.25rem"
              : "1rem",
            marginTop: "1rem",
            marginBottom: "1rem",
            textAlign: "center",
            fontFamily: "BlinkMacSystemFont, sans-serif",
          }}
        >
          {address}
        </Col>
        <Col
          span={24}
          style={{
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "1.25rem"
              : "1rem",
            color: "gray",
            marginTop: "1rem",
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          You have successfully provided your wallet address to us.
        </Col>
        <Col
          span={24}
          style={{
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "1.25rem"
              : "1rem",
            color: "gray",
            marginTop: "1rem",
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          The NFT will be minted to your wallet address once the order is
          delivered.
        </Col>
      </Row>
    </>
  );
};

const Expired = () => {
  const [currentSize, BreakPoint] = useBreakPoint();

  return (
    <>
      <Row justify="center" style={{ width: "100%" }}>
        <Col
          style={{
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "2rem"
              : "1.5rem",
          }}
        >
          Opps!
        </Col>
        <Col
          span={24}
          style={{
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "1.25rem"
              : "1rem",
            color: "gray",
            marginTop: "1rem",
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          This link has expired. Please contact us for further assistance.
        </Col>

        <Row justify="center" align="middle" style={{ width: "100%" }}>
          <Col
            style={{
              fontFamily: "BlinkMacSystemFont, sans-serif",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              window.location = "mailto:info@mixnoggle.xyz";
            }}
          >
            info@mixnoggle.xyz
            <MailOutlined
              style={{
                fontSize: "2rem",
                padding: "1rem",
                margin: "1rem",
                backgroundColor: "#f7f7f7",
                borderRadius: 1000,
              }}
            />
          </Col>
        </Row>
      </Row>
    </>
  );
};

const NotSupport = () => {
  const [currentSize, BreakPoint] = useBreakPoint();

  return (
    <>
      <Row justify="center" style={{ width: "100%" }}>
        <Col
          span={24}
          style={{
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "1.25rem"
              : "1rem",
            color: "gray",
            marginTop: "1rem",
            marginBottom: "1rem",
            textAlign: "center",
          }}
        >
          This order does not support the NFT redemption feature
        </Col>
      </Row>
    </>
  );
};

export default T;
