import { setupIonicReact, IonRouterOutlet, IonApp } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route } from "react-router-dom";

//Pages
import Home from "./pages/Home";
import Market from "./pages/Market";
import Design from "./pages/Design";
import Cart from "./pages/Cart";
import MintSuccess from "./pages/MintSuccess";
import OrderStatus from "./pages/OrderStatus";
import Redeemlink from "./pages/Redeemlink";
import Report from "./pages/Report";
setupIonicReact();

const App = () => {
  return (
    <IonApp>
      <IonReactRouter>
        {process.env.REACT_APP_NODE_ENV == "production" ? (
          <IonRouterOutlet>
            <Route path="/market" component={Market} />
            {/* <Route path="/home" component={Home} /> */}
            <Route path="/design" component={Design} />
            <Route path="/cart" component={Cart} />
            <Route path="/mint/success" component={MintSuccess} />
            <Route path="/order" component={OrderStatus} />
            <Route path="/redeemlink" component={Redeemlink} />
            <Redirect exact from="/" to="/market" />
          </IonRouterOutlet>
        ) : (
          <IonRouterOutlet>
            <Route path="/market" component={Market} />
            {/* <Route path="/home" component={Home} /> */}
            <Route path="/design" component={Design} />
            <Route path="/cart" component={Cart} />
            <Route path="/mint/success" component={MintSuccess} />
            <Route path="/order" component={OrderStatus} />
            <Route path="/redeemlink" component={Redeemlink} />
            <Route path="/report" component={Report} />
            <Redirect exact from="/" to="/market" />
          </IonRouterOutlet>
        )}
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
