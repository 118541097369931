import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

const useStore = create(
  persist(
    immer((set, get) => ({
      items: [],
      whitelist: null,
      loginEmail: null,
      paymentMethod: null,
      config: null,
      referral: null,
      addItem: (item) =>
        set((state) => {
          // Add new item if it doesn't exist
          if (state.items.some((e) => e.id == item.id)) {
            //update existing item
            return {
              ...state,
              items: state.items.map((i) => {
                if (i.id === item.id) {
                  return item;
                }
                return i;
              }),
            };
          } else {
            if (
              state.items.length >=
              parseInt(process.env.REACT_APP_MAX_SET_PER_ORDER)
            ) {
              //exceed max, no change apply
              return { ...state, items: state.items };
            } else {
              //add new item
              return {
                ...state,
                items: [...state.items, item],
              };
            }
          }
        }),
      deleteItem: (itemId) =>
        set((state) => {
          return {
            ...state,
            items: state.items.filter((e) => e.id != itemId),
          };
        }),
      clearItems: () => set((state) => ({ ...state, items: [] })),
      updateWhitelist: (whitelist) => set((state) => ({ ...state, whitelist })),
      updateLoginEmail: (loginEmail) =>
        set((state) => ({ ...state, loginEmail })),
      updatePaymentMethod: (paymentMethod) =>
        set((state) => {
          return { ...state, paymentMethod };
        }),
      updateConfig: (config) =>
        set((state) => {
          return { ...state, config: { ...state.config, ...config } };
        }),
      updateReferral: (referral) => set((state) => ({ ...state, referral })),
    })),
    {
      name: "noggles", // name of the item in the storage (must be unique)
      //storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useStore;
