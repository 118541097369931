import { useState, useMemo, useRef, useEffect } from "react";
import {
  //hook
  useAddress,
} from "@thirdweb-dev/react";
import { useHistory } from "react-router-dom";

import { IonPage, IonContent, useIonToast, IonIcon } from "@ionic/react";
import { Row, Col, Flex, Badge, Button } from "antd";
import { TwitterOutlined, MailOutlined } from "@ant-design/icons";
import { cartSharp, arrowForwardCircle } from "ionicons/icons";

import { motion } from "framer-motion";

import EmblaCarouselLazyLoad from "../components/EmblaCarousel/LazyLoad";
import EmblaCarouselThumb from "../components/EmblaCarousel/Thumb";
import useLogin from "../hooks/useLogin";
import useBreakPoint from "../hooks/useBreakPoint";

import "../styles/global.css";

import axios from "axios";
import Spline from "@splinetool/react-spline";

//global state
import useStore from "../states/useStore";

//utils
import { uuid } from "../utils/id";
import log from "../utils/log";
import { shortenAddress, sleep } from "../utils/general";

import moment from "moment";

const Market = () => {
  const history = useHistory();

  const address = useAddress();
  const [present] = useIonToast();

  const [currentSize, BreakPoint] = useBreakPoint();
  const [auth] = useLogin();
  const [isShow3DModal, setIsShow3DModal] = useState(false);

  const [animationIntervalId, setAnimationIntervalId] = useState();

  const modalRef = useRef();
  const trackOrderRef = useRef();

  const loopAnimation = async (skipR1, modalId) => {
    log.debug(`loop start ${modalId} | ${modalRef?.current?._scene?.uuid}`);

    if (!skipR1) {
      if (modalRef?.current?._scene?.uuid != modalId) {
        log.debug("early exit r1");
        return;
      }
      log.debug(`r1`);
      modalRef?.current?.setVariables({
        round_1: uuid(),
      });
      await sleep(1000 * 2);
      modalRef?.current?.setVariables({
        lense_black: uuid(),
      });

      await sleep(1000 * 10);
    }

    if (modalRef?.current?._scene?.uuid != modalId) {
      log.debug("early exit r2");
      return;
    }
    log.debug(`r2`);
    modalRef?.current?.setVariables({
      round_2: uuid(),
    });
    await sleep(1000 * 2);
    modalRef?.current?.setVariables({
      lense_red: uuid(),
    });

    await sleep(1000 * 10);

    if (modalRef?.current?._scene?.uuid != modalId) {
      log.debug("early exit r3");
      return;
    }
    log.debug(`r3`);
    modalRef?.current?.setVariables({
      round_3: uuid(),
    });
    await sleep(1000 * 2);
    modalRef?.current?.setVariables({
      lense_rgb: uuid(),
      gold_clip_opacity: 100,
      color_clip_opacity: 0,
    });

    await sleep(1000 * 10);

    if (modalRef?.current?._scene?.uuid != modalId) {
      log.debug("early exit r4");
      return;
    }
    log.debug(`r4`);
    modalRef?.current?.setVariables({
      round_4: uuid(),
    });
    await sleep(1000 * 2);
    modalRef?.current?.setVariables({
      lense_black: uuid(),
      gold_clip_opacity: 0,
      color_clip_opacity: 100,
    });

    await sleep(1000 * 10);

    if (modalRef?.current?._scene?.uuid != modalId) {
      log.debug("early exit r5");
      return;
    }
    log.debug(`r5`);
    modalRef?.current?.setVariables({
      round_5: uuid(),
    });
    await sleep(1000 * 2);
    modalRef?.current?.setVariables({
      lense_rgb: uuid(),
      gold_clip_opacity: 100,
      color_clip_opacity: 0,
    });

    await sleep(1000 * 10);

    if (modalRef?.current?._scene?.uuid != modalId) {
      log.debug("early exit r6");
      return;
    }
    log.debug(`r6`);
    modalRef?.current?.setVariables({
      round_6: uuid(),
    });
    await sleep(1000 * 2);
    modalRef?.current?.setVariables({
      lense_red: uuid(),
      gold_clip_opacity: 0,
      color_clip_opacity: 100,
    });

    await sleep(1000 * 10);
  };

  useEffect(() => {
    const scrollToOrder = async () => {
      if (history.location?.state?.scrollToOrder && trackOrderRef?.current) {
        log.debug("scroll into view...");
        log.debug(trackOrderRef);
        await sleep(1000);
        trackOrderRef?.current?.scrollIntoView({ behavior: "smooth" });
      }
    };
    scrollToOrder();

    if (history?.location?.pathname == "/market") {
      setIsShow3DModal(true);
    } else {
      setIsShow3DModal(false);
      clearInterval(animationIntervalId);
    }
  }, [history?.location?.pathname]);

  return (
    <IonPage>
      <IonContent style={{ "--ion-background-color": "white" }}>
        <Flex
          justify="space-between"
          style={{
            height: "100vh", // Set min-height to 100vh
          }}
          vertical
        >
          <Header currentSize={currentSize} />

          <Row justify="center" style={{ width: "100%" }}>
            {isShow3DModal ? (
              <ModalPreviewer
                modalRef={modalRef}
                currentSize={currentSize}
                onStartAnimation={async () => {
                  log.debug(modalRef);

                  modalRef?.current?.setVariables({
                    round_0: uuid(),
                  });
                  await sleep(1000 * 10);
                  loopAnimation(true, modalRef?.current?._scene?.uuid);

                  let intervalId = setInterval(() => {
                    loopAnimation(false, modalRef?.current?._scene?.uuid);
                  }, 1000 * 12 * 6);
                  setAnimationIntervalId(intervalId);
                }}
              />
            ) : null}
          </Row>

          {["lg", "xl", "xxl"].includes(currentSize) == false ? (
            <Row justify="center">
              <Col>
                <img
                  style={{ width: "7.5rem" }}
                  src={`${process.env.REACT_APP_S3_PREFIX}/other/scroll-down.gif`}
                />
              </Col>
            </Row>
          ) : null}

          {["lg", "xl", "xxl"].includes(currentSize) ? (
            <Row
              justify="space-between"
              align="bottom"
              className="ion-padding"
              gutter={[0, 16]}
              style={{
                width: "100%",
              }}
            >
              <Col span={8}></Col>

              <Col>
                <img
                  style={{ width: "7.5rem" }}
                  src={`${process.env.REACT_APP_S3_PREFIX}/other/scroll-down.gif`}
                />
              </Col>
              <Col span={8} style={{ textAlign: "end" }}></Col>
            </Row>
          ) : (
            <Row
              justify="space-between"
              align="bottom"
              className="ion-padding"
              gutter={[0, 16]}
              style={{
                width: "100%",
              }}
            >
              <Col span={24} md={8}>
                <Row
                  style={{
                    fontSize: "2.5rem",
                  }}
                >
                  on sale
                </Row>
                <Row
                  style={{
                    fontSize: "1.5rem",
                    color: "gray",
                  }}
                >
                  {/* {process.env.REACT_APP_TOKEN_PRICE}Ξ / nft */}
                  MixNoggle
                </Row>
              </Col>
              <Col span={24} md={8}>
                <Row justify="end" align="middle">
                  <StartButton />
                </Row>
              </Col>
            </Row>
          )}
        </Flex>

        {/* <img
          src={`${process.env.REACT_APP_S3_PREFIX}/other/hero.gif`}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: -1,
            width: "100%",
            height: "100%",
            opacity: "0.2",
            objectFit: "cover",
            WebkitFilter: "grayscale(100%)",
            filter: "grayscale(100%)",
          }}
        /> */}

        <Row
          style={{
            backgroundColor: "white",
          }}
        >
          <NoggleList
            currentSize={currentSize}
            onRedirect={() => {
              history.push({
                pathname: "/design",
                state: {
                  id: uuid(),
                },
              });
            }}
          />

          <ModelList currentSize={currentSize} />

          <Clip currentSize={currentSize} />

          <TrackOrder
            trackOrderRef={trackOrderRef}
            currentSize={currentSize}
            address={address}
          />

          <MeetOurTeam />

          <Social />
        </Row>
      </IonContent>
    </IonPage>
  );
};

const Dot = ({ index }) => {
  return (
    <Row
      justify="center"
      style={{
        padding: "0.5rem",
        width: "3rem",
        height: "3rem",
        fontSize: "1.5rem",
        borderRadius: 1000,
        backgroundColor: "#0958D9",
        color: "white",
        marginRight: "1rem",
      }}
    >
      {index}
    </Row>
  );
};

const NoggleList = ({ currentSize, onRedirect }) => {
  const OPTIONS = {
    srcPrefix: `${process.env.REACT_APP_S3_PREFIX}/noggleList/`,
    extension: "jpeg",
    // thumbnailPrefix: `${process.env.REACT_APP_S3_PREFIX}/noggleListThumbnail/`,
    loop: true,
  };
  const SLIDE_COUNT = 27;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

  return (
    <Row
      justify="center"
      style={{ width: "100%", margin: "3rem 0rem 3rem 0rem" }}
    >
      <Col>
        <Row
          style={{
            backgroundColor: "#f7f7f7",
            color: "black",
            borderRadius: "1rem",
            padding: "0.5rem 1rem 0.5rem 1rem",
            fontSize: "0.85rem",
          }}
        >
          How it works
        </Row>
      </Col>
      <Col span={24} style={{ marginTop: "1rem" }}>
        <Row
          justify="center"
          className="fancy-text"
          style={{
            width: "100%",
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "2.5rem"
              : "1.5rem",
          }}
        >
          Mix the perfect Noggle
        </Row>
        <Row
          justify="center"
          className="fancy-text"
          style={{
            width: "100%",
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "2.5rem"
              : "1.5rem",
          }}
        >
          yourself
        </Row>
      </Col>

      <Row justify="space-between" align="middle" style={{ width: "100%" }}>
        <Col
          span={24}
          lg={14}
          style={{
            textAlign: "center",
            padding: ["lg", "xl", "xxl"].includes(currentSize)
              ? "3rem"
              : "3rem 1rem 3rem 1rem",
          }}
        >
          <EmblaCarouselThumb slides={SLIDES} options={OPTIONS} />
        </Col>
        <Col span={24} lg={10} style={{ padding: "2rem" }}>
          <Row align="middle" style={{ marginBottom: "1rem" }}>
            <Dot index={1} />
            <div
              style={{
                fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                  ? "1rem"
                  : "1rem",
              }}
            >
              Design your MixNoggle
            </div>
          </Row>
          <Row align="middle" style={{ marginBottom: "1rem" }}>
            <Dot index={2} />
            <div
              style={{
                fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                  ? "1rem"
                  : "1rem",
              }}
            >
              Connect Wallet / Email
            </div>
          </Row>
          <Row align="middle" style={{ marginBottom: "1rem" }}>
            <Dot index={3} />
            <div
              style={{
                fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                  ? "1rem"
                  : "1rem",
              }}
            >
              Mint NFT
            </div>
          </Row>
          <Row align="middle" style={{ marginBottom: "1rem" }}>
            <Dot index={4} />
            <div
              style={{
                fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                  ? "0.9rem"
                  : "0.85rem",
              }}
            >
              MixNoggle shipped to you
            </div>
          </Row>

          <Row align="middle" style={{ marginBottom: "1rem" }}>
            <StartButton hideCart />
          </Row>
        </Col>
      </Row>
    </Row>
  );
};

const TrackOrder = ({ trackOrderRef, currentSize, address }) => {
  const history = useHistory();
  const [orders, setOrders] = useState();
  const [isShowEmptyOrderText, setIsShowEmptyOrderText] = useState(false);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [auth, LoginModal] = useLogin();

  const getOrders = async () => {
    try {
      setIsShowEmptyOrderText(false);
      setIsLoadingOrder(true);

      let url =
        auth?.email == null
          ? process.env.REACT_APP_SERVER_URL +
            "api/orders?buyer.address=" +
            address
          : process.env.REACT_APP_SERVER_URL +
            "api/orders?buyer.email=" +
            auth?.email;

      log.debug(url);
      let res = await axios.get(url);
      setIsLoadingOrder(false);

      res.data = res.data.filter((e) => e.status != "pending");

      if (res.data?.length == 0) {
        setIsShowEmptyOrderText(true);
      }
      //desc
      res.data.sort((a, b) => b.time.place.localeCompare(a.time.place));
      log.debug(res.data);
      setOrders(res.data);
      return res.data;
    } catch (error) {
      log.error(error);
      setOrders([]);
      return [];
    }
  };

  return (
    <Row
      ref={trackOrderRef}
      justify="center"
      className="ion-margin-top"
      style={{
        width: "100%",
        margin: "3rem",
        backgroundColor: "#f7f7f7",
        borderRadius: "1rem",
        padding: "2rem 0 2rem 0",
      }}
    >
      <Row
        justify="center"
        style={{
          width: "100%",
          textAlign: "center",
          fontSize: "1.5rem",
          margin: "0 2rem 0 2rem",
        }}
      >
        Track Your Order
      </Row>
      <Row justify="center" style={{ width: "100%", marginTop: "1rem" }}>
        {auth?.isLoggedIn ? (
          <Button
            loading={isLoadingOrder}
            size="large"
            style={{
              marginTop: "1rem",
              fontSize: "1rem",
              backgroundColor: "black",
            }}
            type="primary"
            onClick={async () => {
              log.debug("get order by owner address");
              await getOrders();
              return;
            }}
          >
            Track now
          </Button>
        ) : (
          <LoginModal />
        )}

        {isShowEmptyOrderText ? (
          <Row justify="center" style={{ width: "100%", marginTop: "1rem" }}>
            no order found on this {auth?.email ? "email" : "address"}
          </Row>
        ) : null}
      </Row>

      {auth?.isLoggedIn && orders != null && orders.length > 0 ? (
        <Row
          style={{
            width: ["lg", "xl", "xxl"].includes(currentSize) ? "50%" : "100%",
            marginTop: "2rem",
          }}
        >
          <Row
            justify="space-between"
            align="middle"
            style={{
              width: "100%",
              margin: "0 1.5rem 1.5rem 1.5rem",
              textDecoration: "underline",
            }}
          >
            <Col>order id</Col>
          </Row>

          <Row
            gutter={[0, 16]}
            style={{
              width: "100%",
            }}
          >
            {orders.map((e, i) => (
              <Col
                key={i}
                span={24}
                style={{
                  padding: "1rem",
                  cursor: "pointer",
                  borderBottom: "1px solid lightgray",
                  // borderRadius: "1rem",
                }}
                onClick={() => {
                  history.push(`/order?id=${e.id}`);
                }}
              >
                <Row justify="space-between" align="middle">
                  {["lg", "xl", "xxl"].includes(currentSize) ? (
                    <Col style={{ fontSize: "1.5rem" }}>
                      {e.id}
                      {e.status == "delivered" ? (
                        <span
                          style={{
                            padding: "0.5rem",
                            marginLeft: "1rem",
                            borderRadius: 1000,
                            fontSize: "1rem",
                            background: "#55A3FF",
                            color: "white",
                          }}
                        >
                          {e.status}
                        </span>
                      ) : null}
                    </Col>
                  ) : (
                    <Col span={24} style={{ fontSize: "1.5rem" }}>
                      {e.id}
                      {e.status == "delivered" ? (
                        <span
                          style={{
                            padding: "0.5rem",
                            marginLeft: "1rem",
                            borderRadius: 1000,
                            fontSize: "1rem",
                            background: "#55A3FF",
                            color: "white",
                          }}
                        >
                          {e.status}
                        </span>
                      ) : null}
                    </Col>
                  )}
                  {["lg", "xl", "xxl"].includes(currentSize) ? (
                    <Col>
                      <Row align="middle">
                        {moment(e.time.place).format("YYYY-MM-DD HH:mm")}
                        <IonIcon
                          style={{ marginLeft: "1rem", fontSize: "2rem" }}
                          icon={arrowForwardCircle}
                        />
                      </Row>
                    </Col>
                  ) : (
                    <Col span={24} style={{ marginTop: "1rem" }}>
                      <Row align="middle">
                        {moment(e.time.place).format("YYYY-MM-DD HH:mm")}
                        <IonIcon
                          style={{ marginLeft: "1rem", fontSize: "2rem" }}
                          icon={arrowForwardCircle}
                        />{" "}
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            ))}
          </Row>
        </Row>
      ) : null}
    </Row>
  );
};

const Clip = ({ currentSize }) => {
  return (
    <Flex
      vertical={!["lg", "xl", "xxl"].includes(currentSize)}
      style={{ width: "100%", margin: "2rem 3rem 3rem 3rem" }}
    >
      <Flex
        vertical
        justify="center"
        style={{
          width: ["lg", "xl", "xxl"].includes(currentSize) ? "50%" : "100%",
        }}
      >
        <Flex
          style={{
            width: "100%",
            color: "#0958D9",
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "1.75rem"
              : "1.5rem",
          }}
        >
          Magnetic Clip
        </Flex>

        <Flex
          style={{
            width: "100%",
            marginTop: "1rem",
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "1.25rem"
              : "1rem",
            textDecoration: "",
          }}
        >
          Clip on MixNoggles, Easily Switch with different color style
        </Flex>

        <Flex
          style={{
            width: "100%",
            color: "#0958D9",
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "1.75rem"
              : "1.5rem",
            marginTop: "3rem",
          }}
        >
          HD UV400 protection lens
        </Flex>

        <Flex
          style={{
            width: "100%",
            marginTop: "1rem",
            fontSize: ["lg", "xl", "xxl"].includes(currentSize)
              ? "1.25rem"
              : "1rem",
            textDecoration: "",
          }}
        >
          Block 100% harmful UVA, UVB and UVC Rays, providing complete eye
          protection outdoors
        </Flex>
      </Flex>
      <Flex
        style={{
          width: ["lg", "xl", "xxl"].includes(currentSize) ? "50%" : "100%",
        }}
      >
        <img
          style={{ objectFit: "contain" }}
          src={`${process.env.REACT_APP_S3_PREFIX}/other/magnetic.png`}
        />
      </Flex>
    </Flex>
  );
};

const ModelList = ({ currentSize }) => {
  const OPTIONS = {
    srcPrefix: `${process.env.REACT_APP_S3_PREFIX}/modelList/`,
    extension: "png",
    loop: true,
    align: "start",
    slidesToScroll: "auto",
  };
  const SLIDE_COUNT = 7;
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

  return (
    <Row
      justify="center"
      style={{
        width: "100%",
        margin: ["lg", "xl", "xxl"].includes(currentSize)
          ? "0px"
          : "3rem 1rem 3rem 1rem",
      }}
    >
      <EmblaCarouselLazyLoad slides={SLIDES} options={OPTIONS} />
    </Row>
  );
};

const MeetOurTeam = () => {
  const cardVariants = {
    offscreen: {
      y: 400,
    },
    onscreen: {
      y: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 1,
      },
    },
  };

  return (
    <Row justify="center" style={{ width: "100%", marginTop: "5rem" }}>
      <Row style={{ fontSize: "2rem" }}>Meet Our Team</Row>
      <Row
        justify="center"
        style={{
          width: "100%",
        }}
      >
        <Row
          justify="center"
          gutter={[16, 64]}
          style={{ width: "100%", marginTop: "2rem" }}
        >
          {[
            {
              title: "web3steves.eth.9799.⌐◨-◨",
              description: "Project Manager",
            },
            {
              title: "7vin",
              description: "Dev",
            },
            {
              title: "louisli.eth. 6440.⌐◨-◨",
              description: "Web3 Consultant",
            },
            {
              title: "Calpa Liu",
              description: "Technical Consultant",
            },
            {
              title: "Tsuki Wong♥️$WELL",
              description: "UI Consultant",
            },
            {
              title: "heavenbeing",
              description: "UI Consultant",
            },
          ].map((e, i) => (
            <Col key={i} span={24} lg={8} style={{ textAlign: "center" }}>
              <motion.div
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
              >
                <motion.div variants={cardVariants}>
                  <div>{e.description}</div>
                  <img
                    src={`${process.env.REACT_APP_S3_PREFIX}/teamList/${i}.jpeg`}
                    style={{ width: "7.5rem", aspectRatio: 1 }}
                  />
                  <div>{e.title}</div>
                </motion.div>
              </motion.div>
            </Col>
          ))}
        </Row>
      </Row>
    </Row>
  );
};

const Social = () => {
  return (
    <Row style={{ width: "100%", margin: "3rem" }}>
      <Row justify="center" align="middle" style={{ width: "100%" }}>
        <Col
          style={{ cursor: "pointer" }}
          onClick={() => {
            let url = "https://twitter.com/MixNoggle";
            window.open(url, "_blank", "noopener,noreferrer");
          }}
        >
          follow
          <TwitterOutlined
            style={{
              fontSize: "2rem",
              padding: "1rem",
              margin: "1rem",
              backgroundColor: "#f7f7f7",
              borderRadius: 1000,
            }}
          />
        </Col>
      </Row>

      <Row justify="center" align="middle" style={{ width: "100%" }}>
        <Col
          style={{
            fontFamily: "BlinkMacSystemFont, sans-serif",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location = "mailto:info@mixnoggle.xyz";
          }}
        >
          info@mixnoggle.xyz
          <MailOutlined
            style={{
              fontSize: "2rem",
              padding: "1rem",
              margin: "1rem",
              backgroundColor: "#f7f7f7",
              borderRadius: 1000,
            }}
          />
        </Col>
      </Row>
    </Row>
  );
};

const StartButton = ({ hideCart }) => {
  const { items } = useStore();
  const history = useHistory();

  return (
    <Row align="middle">
      {!hideCart && items.length > 0 ? (
        <motion.button
          className="ion-margin-top"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          style={{
            backgroundColor: "rgb(0,0,0,0)",
            marginRight: "2rem",
          }}
          onClick={() => {
            history.push({
              pathname: "/cart",
            });
          }}
        >
          <Badge count={items.length}>
            <IonIcon style={{ fontSize: "2.5rem" }} icon={cartSharp} />
          </Badge>
        </motion.button>
      ) : null}

      {items.length < parseInt(process.env.REACT_APP_MAX_SET_PER_ORDER) ? (
        <motion.button
          className="design-btn"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "1rem 0.5rem 1rem 0.5rem",
          }}
          onClick={() => {
            history.push({
              pathname: "/design",
              state: {
                id: uuid(),
              },
            });
          }}
        >
          <Row
            style={{
              fontSize: "1.5rem",
              padding: "0.5rem",
            }}
          >
            Start&nbsp;{`>`}
          </Row>
        </motion.button>
      ) : null}
    </Row>
  );
};

const Header = ({ currentSize }) => {
  const [auth, LoginModal] = useLogin();
  return (
    <Row
      className="ion-padding-horizontal"
      style={{
        width: "100%",
      }}
    >
      <Row justify="space-between" align="middle" style={{ width: "100%" }}>
        <Col span={8} md={3}>
          <img
            src={`${process.env.REACT_APP_S3_PREFIX}/other/logo/logo-black-wide.png`}
            style={{
              objectFit: "contain",
              width: "100%",
              aspectRatio: 16 / 9,
            }}
          />
        </Col>
        <Col>
          {auth?.isLoggedIn ? (
            <LoginModal />
          ) : ["lg", "xl", "xxl"].includes(currentSize) ? (
            <StartButton />
          ) : null}
        </Col>
      </Row>

      {["lg", "xl", "xxl"].includes(currentSize) ? (
        <Row justify="space-between" align="middle" style={{ width: "100%" }}>
          <Col span={24} md={8}>
            <Row
              style={{
                fontSize: "2.5rem",
              }}
            >
              on sale
            </Row>
            <Row
              style={{
                fontSize: "1.5rem",
                color: "gray",
              }}
            >
              MixNoggle
            </Row>
          </Col>
          <Col span={24} md={8}>
            <Row justify="end" align="middle">
              {auth?.isLoggedIn ? <StartButton /> : null}
            </Row>
          </Col>
        </Row>
      ) : null}
    </Row>
  );
};

const ModalPreviewer = ({ modalRef, currentSize, onStartAnimation }) => {
  return (
    <Row justify="center">
      <div
        style={
          currentSize == "xs"
            ? {
                height: 250,
                width: "100vw",
                overflow: "hidden",
              }
            : currentSize == "md"
            ? {
                height: 300,
                width: "100vw",
                overflow: "hidden",
              }
            : {
                height: 450,
                width: "100vw",
                overflow: "hidden",
              }
        }
      >
        <Spline
          //scene="https://prod.spline.design/TOEce9qYkr7eGlHQ/scene.splinecode" // honey
          scene="https://prod.spline.design/PfvCDhif-UBFPLV2/scene.splinecode" // gold
          // style={{ cursor: "grab" }}
          onLoad={async (modal) => {
            log.debug("loaded...");
            modalRef.current = modal;
            onStartAnimation();
          }}
        />
      </div>

      {/* <Row style={{backgroundColor: "gray", color: "white", padding: "0.5rem", borderRadius: 1000}}>drag or zoom</Row> */}
    </Row>
  );
};

export default Market;
