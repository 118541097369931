import {
  ConnectWallet,
  MediaRenderer,
  //wallet connect UI
  lightTheme,
  darkTheme,
  //hook
  useContract,
  useAddress,
  useActiveClaimCondition,
  useActiveClaimConditionForWallet,
  useUnclaimedNFTSupply,
  useClaimIneligibilityReasons,
  useLazyMint,
  useClaimNFT,
  //
  useWallet,
  useClaimConditions,
  //
  Web3Button,
} from "@thirdweb-dev/react";

import { ThirdwebSDK } from "@thirdweb-dev/sdk";
import { OpSepoliaTestnet, OptimismGoerli } from "@thirdweb-dev/chains";

import { ethers } from "ethers";
import { useState } from "react";

import { IonButton, IonApp, IonPage, IonContent } from "@ionic/react";

//utils
import log from "../utils/log";

// const signer = new ethers.Wallet(
//   `${process.env.REACT_APP_CONTRACT_OWNER_PRIVATEKEY}`
// );
// const sdk = ThirdwebSDK.fromSigner(signer, OpSepoliaTestnet, {
//   clientId: process.env.REACT_APP_THIRDWEB_CLIENT_ID, // Use client id if using on the client side, get it from dashboard settings
// });

// //can use write API using contract creator's private key
// const sdk = ThirdwebSDK.fromPrivateKey(
//   process.env.REACT_APP_CONTRACT_OWNER_PRIVATEKEY,
//   OpSepoliaTestnet,
//   {
//     clientId: process.env.REACT_APP_THIRDWEB_CLIENT_ID, // Use client id if using on the client side, get it from dashboard settings
//   }
// );

// can only use read API
const sdk = new ThirdwebSDK(OpSepoliaTestnet, {
  clientId: process.env.REACT_APP_THIRDWEB_CLIENT_ID,
});

// const contract2 = await sdk.getContract(process.env.REACT_APP_CONTRACT_ADDRESS);
// log.debug(contract2)

const Home = () => {
  const address = useAddress();
  const wallet = useWallet();
  const { contract } = useContract(process.env.REACT_APP_CONTRACT_ADDRESS);

  //hook for isLoading
  const {
    mutate: claim,
    isLoading: isLoadingClaim,
    error: claimError,
  } = useClaimNFT(contract);

  if (claimError) {
    console.error("failed to claim nft", claimError);
  }

  const updateNFTMetadata = async () => {
    // const metadata = await contract.metadata.get();
    // log.debug(metadata);

    // The token ID of the NFT whose metadata you want to update
    const tokenId = 0;
    // The new metadata
    // const metadata = { name: "My NFT", description: "My NFT description" };
    const metadata = {
      name: "Noggles #1",
      description: "0",
      image:
        "ipfs://QmU7psMVrrbStVKcxde6xR3APvbP5pL7Q1KUCmib8gQ468/black/image.png",
      animation_url:
        "ipfs://QmU7psMVrrbStVKcxde6xR3APvbP5pL7Q1KUCmib8gQ468/black/modal.glb",
      //"ipfs://QmWgsXjU7pLviiMwibwqDVq92drhgXjLbE1V32Drsq7SwH/character.glb",
      //"https://cdn.maketafi.com/nft/coca-cola/masterpiece/v3/vangogh.html",
      external_url:
        "ipfs://QmU7psMVrrbStVKcxde6xR3APvbP5pL7Q1KUCmib8gQ468/black/modal.glb",
      //"ipfs://QmWgsXjU7pLviiMwibwqDVq92drhgXjLbE1V32Drsq7SwH/character.glb",
      //"https://cdn.maketafi.com/nft/coca-cola/masterpiece/v3/vangogh.html",
      background_color: "FF0000",
      attributes: [
        { trait_type: "Frame", value: "black" },
        { trait_type: "Accessory", value: "black" },
        { trait_type: "Lense", value: "black" },
      ],
    };
    await contract.erc721.update(tokenId, metadata);
  };

  const getNFTMetadata = async () => {
    // const metadata = await contract.metadata.get();
    // log.debug(metadata);

    // The token ID of the NFT whose metadata you want to update
    const tokenId = 0;
    // The new metadata
    const nft = await contract.erc721.get(tokenId);

    log.debug(nft);
  };

  const verify = async () => {
    const explorerAPIUrl = "https://api-sepolia-optimism.etherscan.io/api"; // e.g. https://api.etherscan.io/api
    const explorerAPIKey = "DHAG6DG5HF3FNC6XXAQJ4DKUHB9V6NVXVU"; // Generate API key on the explorer

    await sdk.verifier.verifyThirdwebContract(
      "DropERC721", // "DropERC721" | "TokenERC721" search contract name in github: https://github.com/thirdweb-dev/contracts/tree/main/contracts/prebuilts
      explorerAPIUrl,
      explorerAPIKey
    );
  };

  const mint = async () => {
    try {
      const metadatas = [];
      for (let i = 0; i < 10; i++) {
        metadatas.push({
          name: "Noggles #" + i,
          description: "This is a cool NFT",
          image:
            "ipfs://QmU7psMVrrbStVKcxde6xR3APvbP5pL7Q1KUCmib8gQ468/black/image.png",
          animation_url:
            "ipfs://QmU7psMVrrbStVKcxde6xR3APvbP5pL7Q1KUCmib8gQ468/black/modal.glb",
          external_url:
            "ipfs://QmU7psMVrrbStVKcxde6xR3APvbP5pL7Q1KUCmib8gQ468/black/modal.glb",
          background_color: "FF0000",
          attributes: [
            { trait_type: "Frame", value: "black" },
            { trait_type: "Accessory", value: "black" },
            { trait_type: "Lense", value: "black" },
          ],
        });
      }

      // const metadatas = [
      //   {
      //     name: "Noggles #0",
      //     description: "This is a cool NFT",
      //   }
      // ];

      const results = await contract.erc721.lazyMint(metadatas); // uploads and creates the NFTs on chain
      const firstTokenId = results[0].id; // token id of the first created NFT
      log.debug(firstTokenId);

      const firstNFT = await results[0].data(); // (optional) fetch details of the first created NFT
      log.debug(firstNFT);
    } catch (error) {
      log.debug(error);
    }
  };

  //error since sdk use ERC721SignatureMintV2 by default, but contract is v1
  // const mintWithSig = async () => {
  //   try {
  //     const metadata = {
  //       metadata: {
  //         name: "Mint With Sig #0",
  //         description: "ok",
  //         image:
  //           "ipfs://QmU7psMVrrbStVKcxde6xR3APvbP5pL7Q1KUCmib8gQ468/black/image.png",
  //         animation_url:
  //           "ipfs://QmU7psMVrrbStVKcxde6xR3APvbP5pL7Q1KUCmib8gQ468/black/modal.glb",
  //         external_url:
  //           "ipfs://QmU7psMVrrbStVKcxde6xR3APvbP5pL7Q1KUCmib8gQ468/black/modal.glb",
  //         background_color: "FF0000",
  //         attributes: [
  //           { trait_type: "Frame", value: "black" },
  //           { trait_type: "Accessory", value: "black" },
  //           { trait_type: "Lense", value: "black" },
  //         ],
  //       },
  //       price: 0.00001,
  //       to: address,
  //     };

  //     const signature = await contract.erc721.signature.generateBatch(metadata);
  //     log.debug(signature);

  //     const tx = await contract.erc721.signature.mint(signature);
  //     const receipt = tx.receipt; // the mint transaction receipt
  //     const mintedId = tx.id; // the id of the NFT minted
  //     log.debug("NFT successfully minted: " + mintedId);
  //   } catch (error) {
  //     log.debug(error);
  //   }
  // };

  const updateBatchMeta = async () => {
    try {
      const metadata = [
        {
          name: "Enrico Degen",
          description:
            "Degen NFT of Enrico. Symbol of loyal supporter and great animator proven by holding this NFT",
          image: "ipfs://QmcA52ttLXhwnmYwHXs8kuTY32RmNVpShFHYCXXskRVJoy/1.png",
          external_url: "",
          background_color: "",
          attributes: [{ trait_type: "Adobe AE", value: "ok" }],
          customImage: "",
          customAnimationUrl: "",
        },
      ];

      //batch 0
      // const uri = "ipfs://QmVEBynYc2C8tuoUSU87EQquuPNiJNp24KX4k32WTrjZzH/";

      //batch 1
      //onst uri = "ipfs://QmYBgJyaxDUVp1rdhYruZgvp6hhSFAqXtLr5Jj1obMc1yB/";

      //test 0
      //const uri = "ipfs://QmXMQvTGQ3q3Cj544MTJqTP8ihefPpgYVY72HpJaAaF8qG/";

      //test 1
      const uri = "ipfs://QmbmP3cxc461SxyE7pHLTKbQiwb13vWL9Fpi5GtRrpPK3U/";

      const updatedNFT = await contract.call("updateBatchBaseURI", [0, uri]);
      log.debug(updatedNFT);
    } catch (error) {
      log.debug(error);
    }
  };

  return (
    <IonPage>
      <IonContent>
        <div>
          <IonButton
            mode="ios"
            onClick={() => {
              updateNFTMetadata();
            }}
          >
            update nft metadata
          </IonButton>
          <IonButton
            mode="ios"
            onClick={() => {
              getNFTMetadata();
            }}
          >
            get nft metadata
          </IonButton>
          <IonButton
            mode="ios"
            onClick={() => {
              verify();
            }}
          >
            verify
          </IonButton>
          <IonButton
            mode="ios"
            onClick={async () => {
              let balance = await wallet.getBalance();
              balance = parseFloat(balance.displayValue);
              log.debug(balance);
            }}
          >
            get balance
          </IonButton>

          <div>
            <IonButton
              mode="ios"
              onClick={() => {
                mint();
              }}
            >
              mint
            </IonButton>
            {/* <IonButton mode="ios"
          disabled={isLoadingLazyMint}
          onClick={() => lazyMint({ metadatas: [{ name: "My NFT!" }] })}
        >
          mint with hook
        </IonButton> */}
            {/* <IonButton mode="ios"
          onClick={() => {
            mintWithSig();
          }}
        >
          mint with sig
        </IonButton> */}
            <IonButton
              mode="ios"
              disabled={isLoadingClaim}
              onClick={() => {
                claim({ to: address, quantity: 1 });
              }}
            >
              claim
            </IonButton>

            <Web3Button
              contractAddress={process.env.REACT_APP_CONTRACT_ADDRESS}
              onSubmit={() => {
                log.debug("submit tx ed");
              }}
              onSuccess={(tx) => {
                log.debug("tx success");
                log.debug(tx);
              }}
              onError={(error) => {
                log.debug("tx error");
                log.debug(error.reason);
              }}
              action={(contract) => contract.erc721.claim(1)}
            >
              claim
            </Web3Button>

            <IonButton
              mode="ios"
              disabled={isLoadingClaim}
              onClick={() => {
                updateBatchMeta();
              }}
            >
              update batch metadata
            </IonButton>
          </div>

          <IonButton mode="ios">test</IonButton>
          <MediaRenderer src="ipfs://QmU7psMVrrbStVKcxde6xR3APvbP5pL7Q1KUCmib8gQ468/black/modal.glb" />
          <MediaRenderer src="ipfs://QmU7psMVrrbStVKcxde6xR3APvbP5pL7Q1KUCmib8gQ468/black/image.png" />

          <WalletV1 />
          <WalletV2 />
          <WalletV3 />

          <MintInfo contract={contract} address={address} />
        </div>

        <div style={{ marginTop: "100rem" }}></div>
      </IonContent>
    </IonPage>
  );
};

const MintInfo = ({ contract, address }) => {
  const { data: activeClaimPhase, isLoading: isActiveClaimPhaseLoading } =
    useActiveClaimConditionForWallet(contract, address);

  const {
    data: claimIneligibilityReasons,
    isLoading: isClaimIneligibilityReasonsLoading,
  } = useClaimIneligibilityReasons(contract, {
    walletAddress: address || "",
    quantity: 2,
  });

  const {
    data: unclaimedNFTSupply,
    isLoading: isLoadingUnclaimedNFTSupply,
    error: unclaimedNFTSupplyError,
  } = useUnclaimedNFTSupply(contract);
  if (unclaimedNFTSupplyError) {
    console.error("failed to unclaimedNFTSupplyError", unclaimedNFTSupplyError);
  }

  return (
    <>
      {!isActiveClaimPhaseLoading ? (
        <div>
          <p>Claim Phase: {activeClaimPhase?.metadata?.name}</p>
          <p>Price: {ethers.utils.formatUnits(activeClaimPhase?.price)}</p>
          <p>{`unclaimedNFTSupply: ${unclaimedNFTSupply}`}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}

      {!isClaimIneligibilityReasonsLoading ? (
        claimIneligibilityReasons?.length > 0 ? (
          claimIneligibilityReasons?.map((reason, index) => (
            <p key={index}>{reason}</p>
          ))
        ) : (
          <div>
            <p>Eligible to claim</p>
          </div>
        )
      ) : (
        <p>Checking Eligibility...</p>
      )}
    </>
  );
};

const WalletV1 = () => {
  return (
    <ConnectWallet
      theme={lightTheme({
        colors: {
          accentText: "#dd55cb",
          accentButtonBg: "#dd55cb",
          separatorLine: "#EEEEEE",
          borderColor: "#EEEEEE",
        },
      })}
      modalTitle={"Welcome"}
      switchToActiveChain={true}
      modalSize={"wide"}
      welcomeScreen={{
        title: "Noggles",
        subtitle: "Connect to mint now!",
      }}
      modalTitleIconUrl={null}
      // termsOfServiceUrl={"d"}
      // privacyPolicyUrl={"xc"}
    />
  );
};

const WalletV2 = () => {
  return (
    <ConnectWallet
      theme={lightTheme({
        colors: {
          accentText: "#dd55cb",
          accentButtonBg: "#dd55cb",
          separatorLine: "#EEEEEE",
          borderColor: "#EEEEEE",
        },
      })}
      modalTitle={"Welcome"}
      switchToActiveChain={true}
      modalSize={"wide"}
      welcomeScreen={{
        title: "Noggles",
        subtitle: "Connect to mint now!",
      }}
      modalTitleIconUrl={null}
      // termsOfServiceUrl={"d"}
      // privacyPolicyUrl={"xc"}
    />
  );
};

const WalletV3 = () => {
  return (
    <ConnectWallet
      theme={darkTheme({
        colors: {
          accentText: "#dd55cb",
          accentButtonBg: "#dd55cb",
          separatorLine: "#151515",
          borderColor: "#000000",
        },
      })}
      modalTitle={"Welcome to Noggles"}
      switchToActiveChain={true}
      modalSize={"wide"}
      welcomeScreen={() => {
        return (
          <div style={{ width: "100%", height: "100%" }}>
          </div>
        );
      }}
      modalTitleIconUrl={null}
    />
  );
};
export default Home;
