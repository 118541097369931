import { useState, useEffect } from "react";
import { getSetTotalPrice, getOrderTotalPrice } from "../utils/price";

const usePrice = (
  target,
  data,
  contract,
  paymentMethod,
  whitelist,
  referral,
  config
) => {
  const [usd, setUsd] = useState();
  const [eth, setEth] = useState();
  const [nog, setNog] = useState();

  const updatePrice = async (params) => {
    const update = async (
      target,
      data,
      contract,
      paymentMethod,
      whitelist,
      referral,
      config
    ) => {
      const getPrice = target === "set" ? getSetTotalPrice : getOrderTotalPrice;

      //get discount if any
      let discount;
      //not credit card
      if (paymentMethod != "CARD") {
        //use whitelist first
        if (whitelist != null) {
          discount = whitelist?.discount;
        } else if (referral != null) {
          discount = referral?.discount;
        }
      }
      
      const { usd, eth, nog } = await getPrice(
        data,
        contract,
        discount,
        config
      );
      setUsd(usd);
      setEth(eth);
      setNog(nog);
      return { usd, eth, nog };
    };

    if (params != null) {
      // trigger manually
      let {
        target,
        data,
        contract,
        paymentMethod,
        whitelist,
        referral,
        config,
      } = params;
      return await update(
        target,
        data,
        contract,
        paymentMethod,
        whitelist,
        referral,
        config
      );
    } else {
      // trigger by useEffect
      return await update(
        target,
        data,
        contract,
        paymentMethod,
        whitelist,
        referral,
        config
      );
    }
  };

  useEffect(() => {
    updatePrice();
  }, [target, data, contract, paymentMethod, whitelist, referral, config]);

  return [usd, eth, nog, updatePrice];
};

export default usePrice;
