import { ethers } from "ethers";
import log from "./log";

const getSetTotalPrice = async (set, contract, discount, config) => {
  if (contract == null) {
    return 0;
  }

  log.debug("getSetTotalPrice...");

  let totalFrame = 0;
  let totalAccessory = 0;
  let totalLense = 0;

  for (let part of set.parts || []) {
    if (part.type == "frame") {
      totalFrame++;
    }
    if (part.type == "accessory") {
      totalAccessory++;
    }
    if (part.type == "lense") {
      totalLense++;
    }
  }

  //first lense for free for every set
  if (totalLense > 0) {
    totalLense--;
  }

  let {
    ethusd, // 1e0
    nogusd, // 1e0
  } = config || {};
  // let ethusd = await contract.call("getLatestETHInUSD", []);
  // ethusd = parseFloat(ethers.utils.formatEther(ethusd)) * 1e10;
  log.debug(ethusd);

  let total = 0;
  total = await contract.call("getTotalPrice", [
    {
      noggleQty: 1,
      frameQty: totalFrame,
      clipQty: totalAccessory,
      lenseQty: totalLense,
    },
    0,
  ]);
  total = parseFloat(ethers.utils.formatEther(total)) * 1e10;
  //apply discount if any
  if (discount != null) {
    total = total * discount;
  }
  log.debug(total);

  let eth = 0;
  eth = total / ethusd;
  eth = eth.toFixed(process.env.REACT_APP_TOFIXED);
  log.debug(eth);

  let nog = nogusd != null ? parseInt(total / nogusd) : 0;
  log.debug(nog);

  return { usd: Math.ceil(total), eth: parseFloat(eth), nog };
};

const getOrderTotalPrice = async (sets, contract, discount, config) => {
  if (contract == null) {
    return 0;
  }

  console.log("getOrderTotalPrice...");

  let totalNoggle = 0;
  let totalFrame = 0;
  let totalAccessory = 0;
  let totalLense = 0;

  for (let set of sets || []) {
    totalNoggle++;
    for (let part of set.parts) {
      if (part.type == "frame") {
        totalFrame++;
      }
      if (part.type == "accessory") {
        totalAccessory++;
      }
      if (part.type == "lense") {
        totalLense++;
      }
    }

    //first lense for free for every set
    if (totalLense > 0) {
      totalLense--;
    }
  }

  let {
    ethusd, // 1e0
    nogusd, // 1e0
  } = config || {};
  // let ethusd = await contract.call("getLatestETHInUSD", []);
  // ethusd = parseFloat(ethers.utils.formatEther(ethusd)) * 1e10;
  log.debug(ethusd);

  let total = 0;
  total = await contract.call("getTotalPrice", [
    {
      noggleQty: totalNoggle,
      frameQty: totalFrame,
      clipQty: totalAccessory,
      lenseQty: totalLense,
    },
    0,
  ]);
  total = parseFloat(ethers.utils.formatEther(total)) * 1e10;
  //apply discount if any
  if (discount != null) {
    total = total * discount;
  }
  log.debug(total);

  let eth = 0;
  eth = total / ethusd;
  eth = eth.toFixed(process.env.REACT_APP_TOFIXED);
  log.debug(eth);

  let nog = nogusd != null ? parseInt(total / nogusd) : 0;
  log.debug(nog);

  return { usd: Math.ceil(total), eth: parseFloat(eth), nog };
};

export { getSetTotalPrice, getOrderTotalPrice };
