import { useEffect, useMemo, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  useContract,
  useWallet,
  Web3Button,
  lightTheme,
} from "@thirdweb-dev/react";
import Spline from "@splinetool/react-spline";

import { IonPage, IonContent, IonIcon } from "@ionic/react";
import {
  checkmarkCircle,
  cardOutline,
  informationCircleOutline,
} from "ionicons/icons";

import {
  Row,
  Col,
  Form,
  Input,
  Flex,
  Card,
  Drawer,
  Divider,
  Space,
  Button,
  Statistic,
  Steps,
  Checkbox,
  Typography,
  Tooltip,
  Spin,
} from "antd";
import {
  PlusOutlined,
  HomeOutlined,
  QuestionOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import BaseSVG from "../components/BaseSVG";
import { motion } from "framer-motion";
import PhoneInput from "react-phone-input-2";

//Hook
import useBreakPoint from "../hooks/useBreakPoint";
import useLogin from "../hooks/useLogin";
import usePrice from "../hooks/usePrice";
import useNotification from "../hooks/useNotification";

//Global State
import useStore from "../states/useStore";

//utils
import log from "../utils/log";
import { uuid } from "../utils/id";
import {
  getDiscountedPrice,
  getDisplayDiscount,
  parseQueryToJSON,
  parsePhoneFromObj,
  sleep,
} from "../utils/general";

import axios from "axios";
import moment from "moment";
import { ethers, BigNumber } from "ethers";
import deepcopy from "deepcopy";

import "../styles/global.css";
import "react-phone-input-2/lib/style.css";

const { Step } = Steps;
const { Title, Paragraph, Text, Link } = Typography;
const { Countdown } = Statistic;

const T = () => {
  const history = useHistory();
  const location = useLocation();

  const { contract } = useContract(process.env.REACT_APP_CONTRACT_ADDRESS);
  const [openNotification, contextHolder] = useNotification();
  const {
    items,
    paymentMethod,
    clearItems,
    updateWhitelist,
    updateReferral,
    updateConfig,
    updatePaymentMethod,
  } = useStore();

  const [currentSize, BreakPoint] = useBreakPoint();
  const [auth] = useLogin();
  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const [selectedItemId, setSelectItemId] = useState();

  const selectedItem = useMemo(() => {
    return items.find((e) => e.id == selectedItemId) || items[0];
  }, [selectedItemId, items]);

  const contentRef = useRef();
  const modalRef = useRef();

  const onPreview = (id) => {
    setSelectItemId(id);
    log.debug(items.find((e) => e.id == id));
    contentRef?.current?.scrollToTop(500);
  };

  const onWalletConnect = async () => {
    //to use whitelist OR referral, must have connected wallet
    if (auth?.wallet != null) {
      let whitelist = await getWhitelist();
    }
  };

  const getWhitelist = async () => {
    try {
      let res = await axios.get(
        process.env.REACT_APP_SERVER_URL +
          "api/whitelists?address=" +
          auth?.wallet
      );
      log.debug(res.data);
      updateWhitelist(res.data);
      return res.data;
    } catch (error) {
      log.debug(error?.response?.data?.error?.message);
      updateWhitelist();
      return null;
    }
  };

  const getReferral = async (code) => {
    try {
      let res = await axios.get(
        process.env.REACT_APP_SERVER_URL + "api/referrals?code=" + code
      );
      log.debug(res.data);
      updateReferral(res.data);
      return res.data;
    } catch (error) {
      log.debug(error?.response?.data?.error?.message);
      updateReferral();
      return null;
    }
  };

  const getDBEthPrice = async () => {
    try {
      let res = await axios.get(
        process.env.REACT_APP_SERVER_URL + `api/configs/price`
      );
      log.debug(res.data);
      updateConfig(res.data);
      return res.data;
    } catch (error) {
      log.debug(error);
      return null;
    }
  };

  const getUIConfig = async () => {
    try {
      let res = await axios.get(
        process.env.REACT_APP_SERVER_URL + `api/configs/ui`
      );
      log.debug(res.data);
      updateConfig(res.data);
      //has disable option, set default to ETH
      if (res.data.hideNOG || res.data.hideEmailLogin) {
        updatePaymentMethod("ETH");
      }
      return res.data;
    } catch (error) {
      log.debug(error);
      return null;
    }
  };

  const getDBData = async () => {
    await getUIConfig();
    await getDBEthPrice();
  };

  useEffect(() => {
    getDBData();

    //only check referral if no whitelist AND url param exist
    const queryParams = parseQueryToJSON(location.search);
    const { referralCode } = queryParams;
    if (referralCode != null) {
      getReferral(referralCode);
    }
  }, []);

  useEffect(() => {
    log.debug(history.location?.state);
    let { id } = history.location?.state || {};
    log.debug(id);
    if (id != null) {
      //update local state with props on page changes
      setSelectItemId(id);
      contentRef?.current?.scrollToTop(500);
    }
  }, [history.location?.state]);

  useEffect(() => {
    if (selectedItem != null) {
      modalRef?.current?.setVariables({
        accessory_opacity: 100,
        basic_lense_opacity: 0,
        rgb_opacity: 0,
        logo_white_opacity: selectedItem.frame == "red" ? 100 : 0,
        logo_black_opacity: selectedItem.frame == "honey" ? 100 : 0,
        logo_honey_opacity: selectedItem.frame == "black" ? 100 : 0,
        [`frame_${selectedItem.frame}`]: uuid(),
        [`accessory_${selectedItem.accessory}`]: uuid(),
        color_clip_opacity: selectedItem.accessory == "honey" ? 0 : 100,
        gold_clip_opacity: selectedItem.accessory == "honey" ? 100 : 0,
        [`lense_${selectedItem.lense}`]: uuid(),
      });
    }
  }, [selectedItem]);

  useEffect(() => {
    onWalletConnect();
  }, [auth?.wallet]);

  return (
    <IonPage>
      {isShowDrawer && selectedItem != null ? (
        <DetailDrawer
          selectedItem={selectedItem}
          currentSize={currentSize}
          isShowDrawer={isShowDrawer}
          onClose={() => {
            setIsShowDrawer(false);
          }}
        />
      ) : null}

      <IonContent
        ref={contentRef}
        id="content"
        // className="show-scroll"
        style={{
          "--ion-background-color": "#f8f8f8",
          width: "100%",
          height: "100vh",
        }}
      >
        {contextHolder}
        <Row style={{ width: "100%" }}>
          {/* ModalPreviewer */}
          <Col span={24} lg={12}>
            {items?.length > 0 ? (
              <Row
                justify="center"
                align="middle"
                style={{
                  position: "sticky",
                  top: 0,
                  height: ["lg", "xl", "xxl"].includes(currentSize)
                    ? "100vh"
                    : "40vh",
                }}
              >
                {/* Header */}
                <Row
                  justify="space-between"
                  align="middle"
                  style={{
                    position: "absolute",
                    top: 0,
                    zIndex: 1000,
                    width: "100%",
                    padding: "1rem",
                  }}
                >
                  <Col span={6}>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      style={{
                        fontSize: "2rem",
                        textAlign: "start",
                        backgroundColor: "rgb(0,0,0,0)",
                        borderRadius: "1rem",
                        border: "1px solid gray",
                        padding: "0.5rem",
                      }}
                      onClick={() => {
                        history.push("/market");
                      }}
                    >
                      <HomeOutlined />
                    </motion.button>
                  </Col>

                  <Col style={{ textAlign: "center" }}>
                    <Row
                      style={{
                        backgroundColor: "gray",
                        color: "white",
                        padding: "0.5rem",
                        borderRadius: 1000,
                        width: "auto",
                      }}
                    >
                      drag or zoom
                    </Row>
                  </Col>

                  <Col span={6}></Col>
                </Row>

                {/* Container */}
                <Row
                  justify="center"
                  style={
                    ["lg", "xl", "xxl"].includes(currentSize)
                      ? {
                          height: "100vh",
                          width: "100%",
                          overflow: "hidden",
                        }
                      : {
                          height: "40vh",
                          width: "100%",
                          overflow: "hidden",
                        }
                  }
                >
                  {/* <MediaRenderer
                    src={`ipfs://${process.env.REACT_APP_IPFS_BASE_URI}/${selectedItem.frame}/${selectedItem.accessory}/${selectedItem.lense}/modal.glb`}
                    style={{
                      width: "100%",
                      height: "100%",
                      marginTop: -10,
                    }}
                  /> */}
                  <Spline
                    //design
                    // scene="https://prod.spline.design/kpDguDvDOw4uxhr2/scene.splinecode"
                    scene="https://prod.spline.design/8-vTsAhi5nHvqPeA/scene.splinecode"
                    style={{ cursor: "grab" }}
                    onLoad={(modal) => {
                      modalRef.current = modal;
                      log.debug(selectedItem);

                      modalRef.current.setVariables({
                        accessory_opacity: 100,
                        basic_lense_opacity: 0,
                        rgb_opacity: 0,
                        logo_white_opacity:
                          selectedItem.frame == "red" ? 100 : 0,
                        logo_black_opacity:
                          selectedItem.frame == "honey" ? 100 : 0,
                        logo_honey_opacity:
                          selectedItem.frame == "black" ? 100 : 0,
                        [`frame_${selectedItem.frame}`]: uuid(),
                        [`accessory_${selectedItem.accessory}`]: uuid(),
                        color_clip_opacity:
                          selectedItem.accessory == "honey" ? 0 : 100,
                        gold_clip_opacity:
                          selectedItem.accessory == "honey" ? 100 : 0,
                        [`lense_${selectedItem.lense}`]: uuid(),
                      });

                      modalRef.current.emitEvent("keyDown", "glasses testing");
                    }}
                  />
                </Row>
              </Row>
            ) : (
              <Row justify="center" align="middle">
                <img
                  style={{
                    width: "100%",
                    height: ["lg", "xl", "xxl"].includes(currentSize)
                      ? "100vh"
                      : "50vh",
                    objectFit: "cover",
                  }}
                  src={`${process.env.REACT_APP_S3_PREFIX}/other/cartEmptyModel.png`}
                />
              </Row>
            )}
          </Col>

          {/* Panel */}
          <Col
            span={24}
            lg={12}
            style={{
              backgroundColor: "white",
              paddingLeft: ["lg", "xl", "xxl"].includes(currentSize)
                ? "3rem"
                : "2rem",
              paddingRight: ["lg", "xl", "xxl"].includes(currentSize)
                ? "3rem"
                : "2rem",
            }}
          >
            <Cart
              currentSize={currentSize}
              contentRef={contentRef}
              getDBEthPrice={getDBEthPrice}
              onChangePaymentMethod={(token) => {
                updatePaymentMethod(token);
              }}
              onPreview={(id) => {
                onPreview(id);
              }}
              onShowDrawer={() => {
                setIsShowDrawer(true);
              }}
              onClearCart={() => {
                clearItems();
                updateWhitelist();
                updateReferral();
                updatePaymentMethod();
              }}
              openNotification={openNotification}
              onApplyReferralCode={async (code) => {
                return await getReferral(code);
              }}
            />

            {/* Placeholder */}
            <Row style={{ marginTop: "7.5rem" }}></Row>
          </Col>
        </Row>
      </IonContent>
    </IonPage>
  );
};

const USDPrice = ({ usd }) => {
  let _usd = usd || "";
  return `${_usd} USD`;
};

const TokenPrice = ({ contract, paymentMethod, eth, nog }) => {
  const [currentSize, BreakPoint] = useBreakPoint();
  if (paymentMethod == "ETH") {
    return (
      <Row
        justify="end"
        style={{
          fontSize: ["lg", "xl", "xxl"].includes(currentSize)
            ? "1.25rem"
            : "1rem",
          color: "gray",
        }}
      >
        {contract == null || eth == null ? "..." : `≈ ${eth}Ξ`}
      </Row>
    );
  } else if (paymentMethod == "NOG") {
    return (
      <Row
        justify="end"
        align="middle"
        style={{
          fontSize: ["lg", "xl", "xxl"].includes(currentSize)
            ? "1.25rem"
            : "1rem",
          color: "gray",
        }}
      >
        ≈&nbsp;{contract == null || nog == null ? 0 : nog}
        &nbsp;
        <img
          style={{ width: "2rem", height: "2rem" }}
          src={`${process.env.REACT_APP_S3_PREFIX}/other/nogCoin.png`}
        />
        <div style={{ margin: "-1rem 0 0 -0.75rem" }}>
          <BaseSVG />
        </div>
      </Row>
    );
  }
};

const FreeLensePicker = ({ item, onSelect }) => {
  const { items, addItem } = useStore();
  return (
    <>
      <Row justify="center" style={{ fontSize: "0.85rem", width: "100%" }}>
        Additional Lenses for Free
      </Row>
      <Row
        justify="center"
        style={{ fontSize: "0.85rem", width: "100%", marginBottom: "0.75rem" }}
      >
        choose one
      </Row>
      <Row justify="center" align="center" style={{ width: "100%" }}>
        {["black", "red", "rgb"]
          .filter((e) => e != item?.lense)
          .map((e, i) => (
            <Row
              key={i}
              style={
                e == "rgb"
                  ? {
                      backgroundImage:
                        "linear-gradient(to right, red 33.33%, #228B22 33.33%, #228B22 66.66%, blue 66.66%)",
                      borderRadius: 1000,
                      border: "3px solid white",
                      outline: "3px solid blue",
                      color: "rgb(0,0,0,0)",
                      width: "2.5rem",
                      aspectRatio: 1,
                      cursor: "pointer",
                      marginRight: i == 0 ? "2rem" : "",
                      marginLeft: i == 0 ? "" : "2rem",
                    }
                  : {
                      backgroundImage: `linear-gradient(to right, #f7f7f7 50%, ${e} 50%)`,
                      borderRadius: 1000,
                      border: "3px solid white",
                      outline: "3px solid blue",
                      color: "rgb(0,0,0,0)",
                      width: "2.5rem",
                      aspectRatio: 1,
                      cursor: "pointer",
                      marginRight: i == 0 ? "2rem" : "",
                      marginLeft: i == 0 ? "" : "2rem",
                    }
              }
              onClick={() => {
                //update item
                let color = e;
                let updated = deepcopy(item);
                updated.parts.push({
                  type: "lense",
                  id: color,
                });
                addItem(updated);
                onSelect();
              }}
            >
              .
            </Row>
          ))}
      </Row>
    </>
  );
};

const DetailDrawer = ({ selectedItem, currentSize, onClose }) => {
  const history = useHistory();
  const { items, paymentMethod, whitelist, referral, config } = useStore();
  const { contract } = useContract(process.env.REACT_APP_CONTRACT_ADDRESS);
  const [usd, eth, nog] = usePrice(
    "set",
    selectedItem,
    contract,
    paymentMethod,
    whitelist,
    referral,
    config
  );
  const [auth] = useLogin();

  return (
    <Drawer
      placement={["lg", "xl", "xxl"].includes(currentSize) ? "right" : "bottom"}
      width="45%"
      height="80%"
      onClose={() => {
        onClose();
      }}
      open={true}
      key="drawer"
      // extra={
      //   <Space>
      //     <Button
      //       type="primary"
      //       color="black"
      //       onClick={() => {
      //         deleteItem(selectedItem.id);
      //         onClose();
      //       }}
      //     >
      //       delete set <DeleteFilled />
      //     </Button>
      //   </Space>
      // }
    >
      <Row>
        {/* Whitelist */}
        {whitelist != null ? (
          <Row
            align="middle"
            style={{ fontSize: "1rem", marginBottom: "1rem" }}
          >
            <IonIcon
              style={{
                color: "green",
                fontSize: "1.5rem",
                marginRight: "0.5rem",
              }}
              icon={checkmarkCircle}
            />
            {`Applied ${getDisplayDiscount(whitelist?.discount)}% off discount`}
          </Row>
        ) : null}

        {/* Referral */}
        {whitelist == null && referral != null ? (
          <Row
            align="middle"
            style={{ fontSize: "1rem", marginBottom: "1rem" }}
          >
            <IonIcon
              style={{
                color: "green",
                fontSize: "1.5rem",
                marginRight: "0.5rem",
              }}
              icon={checkmarkCircle}
            />
            {`Applied ${getDisplayDiscount(referral?.discount)}% off discount`}
          </Row>
        ) : null}

        <Row justify="space-between" style={{ width: "100%" }}>
          <Col span={24} lg={12}>
            <Row
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
              }}
            >
              <Col
                style={{
                  fontSize: "1.25rem",
                  backgroundColor: "black",
                  color: "white",
                  padding: "0.5rem",
                  borderRadius: "1rem 0 1rem 0",
                }}
              >
                Set {items.findIndex((e) => e.id == selectedItem.id) + 1}
              </Col>
            </Row>
            <img
              style={{
                border: "1px solid lightgray",
                borderRadius: "1rem",
              }}
              src={`${process.env.REACT_APP_S3_PREFIX}/noggles/${selectedItem?.frame}/${selectedItem?.accessory}/${selectedItem?.lense}/image.png`}
            />
          </Col>
        </Row>

        <Row
          justify="space-between"
          align="middle"
          style={{ width: "100%", marginTop: "1rem" }}
        >
          <Col style={{ textAlign: "end" }}>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{
                fontSize: "1rem",
                backgroundColor: "white",
                color: "#55A3FF",
                padding: "0.75rem",
                borderRadius: 1000,
              }}
              onClick={() => {
                onClose();
                let item = items.find((e) => e.id == selectedItem?.id);
                history.push({
                  pathname: "/design",
                  state:
                    item == null
                      ? {
                          editStep1: true,
                        }
                      : {
                          editStep1: true,
                          ...item,
                        },
                });
              }}
            >
              edit MixNoggle
            </motion.button>
          </Col>

          <Col
            style={{
              fontSize: "1.25rem",
              textAlign: "center",
            }}
          >
            {`$ ${getDiscountedPrice(
              paymentMethod,
              whitelist?.discount || referral?.discount,
              process.env.REACT_APP_TOKEN_PRICE
            )}`}
          </Col>
        </Row>

        <Divider />

        <Row
          justify="space-between"
          align="middle"
          style={{ fontSize: "1rem", width: "100%" }}
        >
          Accessories
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{
              fontSize: "1rem",
              backgroundColor: "#55A3FF",
              color: "white",
              padding: "0.75rem",
              borderRadius: 1000,
              marginLeft: "1rem",
            }}
            onClick={() => {
              onClose();
              history.push({
                pathname: "/design",
                state:
                  selectedItem == null
                    ? {
                        editStep2: true,
                      }
                    : {
                        editStep2: true,
                        ...selectedItem,
                      },
              });
            }}
          >
            add <PlusOutlined />
          </motion.button>
        </Row>

        {selectedItem?.parts?.length > 0 ? (
          <>
            {selectedItem.parts.map((e, i) => (
              <Row
                key={i}
                justify="space-between"
                align="middle"
                style={{ width: "100%", marginTop: "0.5rem" }}
              >
                <Col span={6}>
                  <img
                    style={{
                      border: "1px solid lightgray",
                      borderRadius: "1rem",
                    }}
                    src={`${process.env.REACT_APP_S3_PREFIX}/parts/${e.type}/${e.id}.png`}
                  />
                </Col>
                <Col
                  style={{
                    fontSize: "1.25rem",
                  }}
                >
                  {e.type == "frame"
                    ? `$ ${getDiscountedPrice(
                        paymentMethod,
                        whitelist?.discount || referral?.discount,
                        process.env.REACT_APP_FRAME_PRICE
                      )}`
                    : e.type == "lense"
                    ? i ==
                      selectedItem.parts.findIndex((e) => e.type == "lense")
                      ? "free"
                      : `$ ${getDiscountedPrice(
                          paymentMethod,
                          whitelist?.discount || referral?.discount,
                          process.env.REACT_APP_LENSE_PRICE
                        )}`
                    : e.type == "accessory"
                    ? `$ ${getDiscountedPrice(
                        paymentMethod,
                        whitelist?.discount || referral?.discount,
                        process.env.REACT_APP_ACCESSORY_PRICE
                      )}`
                    : ""}
                </Col>
              </Row>
            ))}
          </>
        ) : null}

        <Divider />

        <Row
          justify="space-between"
          align="middle"
          style={{ marginTop: "1rem", width: "100%" }}
        >
          <Col style={{ fontSize: "1.5rem" }}>Set Total</Col>
          <Col>
            <Row justify="end" style={{ fontSize: "1.5rem" }}>
              <USDPrice usd={usd} />
            </Row>
            <Row justify="end" style={{ fontSize: "1.25rem", color: "gray" }}>
              <TokenPrice
                contract={contract}
                paymentMethod={paymentMethod}
                eth={eth}
                nog={nog}
              />
            </Row>
          </Col>
        </Row>
      </Row>
    </Drawer>
  );
};

const CartPlaceholder = ({ urlReferralCode }) => {
  const history = useHistory();

  return (
    <Flex justify="center" align="center" style={{ height: "100%" }}>
      <Row
        style={{
          width: "100%",
          position: "absolute",
          top: 0,
        }}
      >
        <Col>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{
              fontSize: "1.25rem",
              backgroundColor: "rgb(0,0,0,0)",
              padding: "1.5rem",
            }}
            onClick={() => {
              history.push({
                pathname: "/market",
              });
            }}
          >{`< home`}</motion.button>
        </Col>
      </Row>
      <Row
        justify="center"
        style={{ width: "100%", fontSize: "2rem", marginTop: "2rem" }}
      >
        {urlReferralCode != null ? (
          <Row justify="center" style={{ marginBottom: "3rem" }}>
            <Row justify="center" style={{ width: "100%", color: "gray" }}>
              Referral Code
            </Row>
            <Card bodyStyle={{ padding: "1rem" }}>
              <Row align="middle">
                <span
                  style={{
                    fontSize: "1.25rem",
                  }}
                >
                  {urlReferralCode}
                </span>
              </Row>
            </Card>
          </Row>
        ) : null}

        <Col span={24} style={{ textAlign: "center", fontSize: "2rem" }}>
          Cart is Empty
        </Col>
        <Col
          span={24}
          style={{ textAlign: "center", fontSize: "1rem", color: "gray" }}
        >
          Design your own MixNoggle
        </Col>
        <Col span={24} style={{ textAlign: "center" }}>
          <motion.button
            className="ion-padding"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            style={{
              fontSize: "1rem",
              backgroundColor: "black",
              borderRadius: "0.5rem",
              color: "white",
              padding: "1.5rem",
              margin: "2rem",
            }}
            onClick={() => {
              history.push({
                pathname: "/design",
                state: {
                  id: uuid(),
                },
              });
            }}
          >
            Get Started
          </motion.button>
        </Col>
      </Row>
    </Flex>
  );
};

const Cart = ({
  currentSize,
  contentRef,
  getDBEthPrice,
  onChangePaymentMethod,
  onPreview,
  onShowDrawer,
  onClearCart,
  openNotification,
  onApplyReferralCode,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [shippingForm] = Form.useForm();
  const [phone, setPhone] = useState();
  const { items, whitelist, referral } = useStore();
  const [isMinting, setIsMinting] = useState();
  const [activeStep, setActiveStep] = useState(1);
  const titleRef = useRef();
  const [auth, LoginModal] = useLogin();
  const [urlReferralCode, setUrlReferralCode] = useState();

  useEffect(() => {
    const queryParams = parseQueryToJSON(location.search);
    const { referralCode, token, PayerID } = queryParams;
    console.log({ referralCode, token, PayerID });

    //save to local state for login
    if (referralCode != null) {
      setUrlReferralCode(referralCode);
    }

    //handling paypal redirect
    if (token != null) {
      if (PayerID != null) {
        // Handle the redirect and the token here
        console.log(`PayerID detected, paypal order ${token} is paid`);
        // Perform any necessary actions, such as verifying the payment or updating the UI

        //clear state
        shippingForm.resetFields();
        setActiveStep(1);
        setTimeout(() => {
          onClearCart();
        }, 2000);

        history.push({
          pathname: "/mint/success",
        });
      } else {
        console.log("User rejected payment");
        openNotification("error", "User cancelled payment");
      }
    }
  }, [location]);

  return (
    <>
      {/* {auth?.isLoggedIn == false ? (
        <Row
          justify="center"
          align={["lg", "xl", "xxl"].includes(currentSize) ? "middle" : "top"}
          style={{
            width: "100%",
            height: "100%",
            marginTop: "2rem",
            marginBottom: "30rem",
          }}
        >
          <Row justify="center" style={{ width: "100%" }}>
            {urlReferralCode != null ? (
              <Row justify="center" style={{ marginBottom: "3rem" }}>
                <Row justify="center" style={{ width: "100%", color: "gray" }}>
                  Referral Code
                </Row>
                <Card bodyStyle={{ padding: "1rem" }}>
                  <Row align="middle">
                    <span
                      style={{
                        fontSize: "1.25rem",
                      }}
                    >
                      {urlReferralCode}
                    </span>
                  </Row>
                </Card>
              </Row>
            ) : null}

            <Row
              justify="center"
              style={{
                width: "100%",
                fontSize: "1.25rem",
                marginBottom: "2rem",
                textAlign: "center",
              }}
            >
              {urlReferralCode == null && !config?.hideEmailLogin
                ? "Connect Wallet / Email to mint"
                : "Connect Wallet to mint"}
            </Row>
            <LoginModal isReferral={urlReferralCode != null} />
          </Row>
        </Row>
      ) : 
       */}

      {items?.length == 0 ? (
        <CartPlaceholder urlReferralCode={urlReferralCode} />
      ) : (
        <>
          {/* Title */}
          <Row
            justify="space-between"
            align="middle"
            className="ion-padding-vertical"
            style={{
              width: "100%",
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white",
            }}
          >
            <Steps
              current={activeStep - 1}
              direction="horizontal"
              responsive={false}
              // style={{ margin: "1rem 0px 0px -3rem", padding: 0 }}
            >
              <Step title="Cart" />
              <Step title="Info" />
              <Step title="Mint" />
            </Steps>
          </Row>

          {/* Whitelist */}
          {auth.wallet != null && whitelist != null && activeStep != 3 ? (
            <Row>
              <Card bodyStyle={{ padding: "1rem" }}>
                <Row align="middle">
                  <IonIcon
                    style={{
                      color: "green",
                      fontSize: "1.5rem",
                      marginRight: "1rem",
                    }}
                    icon={checkmarkCircle}
                  />
                  <span
                    style={{
                      fontSize: "1.25rem",
                    }}
                  >{`Eligible for Whitelist`}</span>
                </Row>
                enjoy&nbsp;
                <span
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                    fontFamily: "BlinkMacSystemFont, sans-serif",
                  }}
                >
                  {getDisplayDiscount(whitelist?.discount)}% off
                </span>
                &nbsp;discount until{" "}
                {moment(whitelist?.time?.end).toISOString().substring(0, 10)}{" "}
                UTC+0
              </Card>
            </Row>
          ) : null}

          {/* Referral */}
          {whitelist == null && referral != null && activeStep != 3 ? (
            <Row>
              <Card bodyStyle={{ padding: "1rem" }}>
                <Row align="middle">
                  <Row
                    style={{
                      width: "100%",
                      fontSize: "0.75rem",
                      color: "gray",
                    }}
                  >
                    Referral Code
                  </Row>
                  <span
                    style={{
                      fontSize: "1.25rem",
                    }}
                  >{`${referral.code}`}</span>
                  <IonIcon
                    style={{
                      color: "green",
                      fontSize: "1.5rem",
                    }}
                    icon={checkmarkCircle}
                  />
                </Row>
                enjoy&nbsp;
                <span
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: "bold",
                    fontFamily: "BlinkMacSystemFont, sans-serif",
                  }}
                >
                  {getDisplayDiscount(referral?.discount)}% off
                </span>
                &nbsp;discount until{" "}
                {moment(referral?.expiry).toISOString().substring(0, 10)} UTC+0
              </Card>
            </Row>
          ) : null}

          {activeStep == "1" ? (
            <>
              <Row
                ref={titleRef}
                gutter={[16, 16]}
                style={{
                  width: "100%",
                  paddingTop: "2rem",
                  marginLeft: "0.125rem",
                }}
              >
                {items.map((e, i) => (
                  <Col key={i} span={24} sm={12}>
                    <CartItem
                      key={i}
                      item={e}
                      index={i}
                      currentSize={currentSize}
                      onPreview={() => {
                        onPreview(e.id);
                      }}
                      onShowDrawer={() => {
                        onShowDrawer();
                      }}
                    />
                  </Col>
                ))}
              </Row>

              <Row
                justify="space-between"
                gutter={16}
                style={{ marginTop: "1rem" }}
              >
                {/* Add Btn */}
                {items.length >=
                parseInt(process.env.REACT_APP_MAX_SET_PER_ORDER) ? (
                  <Col span={24}>
                    <Row
                      justify="space-between"
                      align="middle"
                      gutter={[16, 16]}
                    >
                      <Col span={24} lg={20}>
                        <Card
                          bodyStyle={{
                            padding: "0rem",
                            width: "100%",
                            height: "5rem",
                            backgroundColor: "#f7f7f7",
                            textAlign: "center",
                            cursor: "no-drop",
                          }}
                        >
                          <Row
                            justify="center"
                            align="middle"
                            style={{
                              width: "100%",
                              height: "100%",
                              fontSize: "1rem",
                            }}
                          >
                            Maximum {process.env.REACT_APP_MAX_SET_PER_ORDER}{" "}
                            set per order
                          </Row>
                        </Card>
                      </Col>
                      <Col span={24} lg={4} style={{ textAlign: "end" }}>
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "1.25rem",
                            padding: "1.25rem",
                            borderRadius: "0.5rem",
                          }}
                          onClick={() => {
                            if (
                              items.every((item) =>
                                item.parts.some((part) => part.type == "lense")
                              ) == false
                            ) {
                              openNotification(
                                "warning",
                                "Please choose your free lenses"
                              );
                              return;
                            }
                            setActiveStep(2);
                            titleRef?.current?.scrollIntoView(500);
                          }}
                        >
                          Next
                        </motion.button>
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  <Col span={24}>
                    <Row
                      justify="space-between"
                      align="middle"
                      gutter={[16, 16]}
                    >
                      <Col span={24} lg={20}>
                        <Card
                          hoverable
                          bodyStyle={{
                            padding: "0rem",
                            width: "100%",
                            height: "4rem",
                            backgroundColor: "#f7f7f7",
                            textAlign: "center",
                          }}
                          onClick={() => {
                            history.push({
                              pathname: "/design",
                              state: {
                                id: uuid(),
                              },
                            });
                          }}
                        >
                          <Row
                            justify="center"
                            align="middle"
                            style={{
                              width: "100%",
                              height: "100%",
                              fontSize: "1rem",
                            }}
                          >
                            add new set
                            <img
                              style={{ marginLeft: "1rem", width: "2rem" }}
                              src={`${process.env.REACT_APP_S3_PREFIX}/other/icon-black-1600px.png`}
                            />
                          </Row>
                        </Card>
                      </Col>
                      <Col span={24} lg={4} style={{ textAlign: "end" }}>
                        <motion.button
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          style={{
                            backgroundColor: "black",
                            color: "white",
                            fontSize: "1.25rem",
                            padding: "1.25rem",
                            borderRadius: "0.5rem",
                          }}
                          onClick={() => {
                            if (
                              items.every((item) =>
                                item.parts.some((part) => part.type == "lense")
                              ) == false
                            ) {
                              openNotification(
                                "warning",
                                "Please choose your free lenses"
                              );
                              return;
                            }
                            setActiveStep(2);
                            titleRef?.current?.scrollIntoView(500);
                          }}
                        >
                          Next
                        </motion.button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </>
          ) : null}

          <Shipping
            activeStep={activeStep}
            form={shippingForm}
            phone={phone}
            isMinting={isMinting}
            openNotification={openNotification}
            onBack={() => {
              setActiveStep(1);
              contentRef?.current?.scrollToTop(500);
            }}
            onNext={() => {
              setActiveStep(3);
              titleRef?.current?.scrollIntoView(500);
            }}
            onChangePhone={(countryCode, number) => {
              setPhone({ countryCode, number });
            }}
          />

          {activeStep == "3" ? (
            <Checkout
              form={shippingForm}
              currentSize={currentSize}
              shippingForm={shippingForm}
              phone={phone}
              urlReferralCode={urlReferralCode}
              openNotification={openNotification}
              getDBEthPrice={getDBEthPrice}
              onChangePaymentMethod={onChangePaymentMethod}
              onMint={(minting) => {
                setIsMinting(minting);
              }}
              onClearCart={() => {
                setActiveStep(1);
                onClearCart();
              }}
              onPreview={(id) => {
                onPreview(id);
              }}
              onShowDrawer={() => onShowDrawer()}
              onBack={() => {
                setActiveStep(2);
                titleRef?.current?.scrollIntoView(500);
              }}
              onApplyReferralCode={onApplyReferralCode}
            />
          ) : null}
        </>
      )}
    </>
  );
};

const Shipping = ({
  activeStep,
  form,
  phone,
  isMinting,
  openNotification,
  onBack,
  onNext,
  onChangePhone,
}) => {
  const [auth] = useLogin();

  const [phoneInput, setPhoneInput] = useState();

  return (
    <div
      style={{
        display: activeStep == 2 ? "block" : "none",
      }}
    >
      <Row
        align="middle"
        className="ion-padding-vertical"
        style={{
          width: "100%",
          zIndex: 1,
          backgroundColor: "white",
        }}
      >
        <Col span={24} style={{ fontSize: "2rem" }}>
          Shipping
        </Col>
        <Col
          span={24}
          style={{
            fontSize: "1rem",
            color: "gray",
            marginTop: "0.5rem",
            fontFamily: "BlinkMacSystemFont, sans-serif",
          }}
        >
          Expected Delivery: within&nbsp;
          <span style={{ fontWeight: "bold", fontSize: "1.25rem" }}>
            {process.env.REACT_APP_EXPECTED_DELIVERY_DATE}
          </span>
          &nbsp; Days after successful order placement
        </Col>
      </Row>

      <Row>
        <Form
          form={form}
          layout="horizontal"
          className="ion-margin-top"
          disabled={isMinting}
          style={{ width: "100%" }}
        >
          {/* Name & Twitter ID */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["name"]}
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "Cannot be empty",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Name / Nickname"
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["twitterId"]}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Twitter ID (optional)"
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Email */}
          <Row>
            {auth?.email ? (
              <Input
                size="large"
                placeholder="Email"
                disabled
                value={auth.email}
                style={{
                  fontFamily: "BlinkMacSystemFont, sans-serif",
                  marginBottom: "1rem",
                }}
              />
            ) : (
              <Form.Item
                name={["email"]}
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    type: "email",
                    message: "Invalid email format",
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "Cannot be empty",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Email"
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
            )}
          </Row>

          {/* Phone */}
          <Row>
            <Form.Item
              name={["phone"]}
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: "Cannot be empty",
                },
              ]}
            >
              <Row align="middle">
                <Col>
                  <PhoneInput
                    country="us" // default country is us
                    countryCodeEditable={false}
                    enableSearch
                    inputStyle={{
                      height: "3rem",
                    }}
                    value={phoneInput}
                    onChange={(value, data) => {
                      setPhoneInput(value);
                      let { countryCode, number } = parsePhoneFromObj(
                        value,
                        data
                      );
                      onChangePhone(countryCode, number);
                    }}
                  />
                </Col>
                <Col style={{ marginLeft: "0.5rem" }}>
                  <Tooltip title="In case we need to contact you about your order">
                    <QuestionCircleOutlined
                      style={{ fontSize: "1rem", color: "gray" }}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Form.Item>
          </Row>

          {/* Street */}
          <Row>
            <Form.Item
              name={["street"]}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                },
                {
                  pattern: /^(?!\s*$).+/,
                  message: "Cannot be empty",
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Street Address"
                style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
              />
            </Form.Item>
          </Row>
          {/* Apartment */}
          <Row>
            <Form.Item
              name={["apartment"]}
              style={{ width: "100%" }}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                size="large"
                placeholder="Apt, Building (Optional)"
                style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
              />
            </Form.Item>
          </Row>
          {/* City & State */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["city"]}
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "Cannot be empty",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="City"
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["state"]}
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "Cannot be empty",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="State"
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
            </Col>
          </Row>
          {/* Postal Code & Country */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["zipcode"]}
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Zip Code (Optional)"
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["country"]}
                rules={[
                  {
                    required: true,
                  },
                  {
                    pattern: /^(?!\s*$).+/,
                    message: "Cannot be empty",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="Country"
                  style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Row>

      <Row justify="end" style={{ marginTop: "1rem" }}>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          style={{
            backgroundColor: "#f8f8f8",
            color: "gray",
            fontSize: "1.25rem",
            padding: "1.25rem",
            borderRadius: "0.5rem",
            marginRight: "1rem",
          }}
          onClick={() => {
            onBack();
          }}
        >
          Back
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          style={{
            backgroundColor: "black",
            color: "white",
            fontSize: "1.25rem",
            padding: "1.25rem",
            borderRadius: "0.5rem",
          }}
          onClick={async () => {
            try {
              //form check
              await form.validateFields();
              if (phone?.number?.length > 0 == false) {
                return openNotification("error", "Phone Incomplete");
              }

              onNext();
            } catch (error) {
              openNotification("error", "Form Incomplete");
            }
          }}
        >
          Next
        </motion.button>
      </Row>
    </div>
  );
};

const Checkout = ({
  form,
  phone,
  currentSize,
  openNotification,
  urlReferralCode,
  onChangePaymentMethod,
  onMint,
  onClearCart,
  onPreview,
  onShowDrawer,
  onBack,
  onApplyReferralCode,
}) => {
  const history = useHistory();
  const wallet = useWallet();
  const { contract } = useContract(process.env.REACT_APP_CONTRACT_ADDRESS);
  const { contract: NOGContract } = useContract(
    process.env.REACT_APP_NOG_CONTRACT_ADDRESS
  );
  const { items, paymentMethod, whitelist, referral, config, updateConfig } =
    useStore();
  const [usd, eth, nog, updatePrice] = usePrice(
    "order",
    items,
    contract,
    paymentMethod,
    whitelist,
    referral,
    config
  );
  const [auth] = useLogin();

  const [isCheckedTerms, setIsCheckedTerms] = useState(false);
  const [isCheckedTerms2, setIsCheckedTerms2] = useState(false);
  const [isShowTermsDrawer, setIsShowTermsDrawer] = useState(false);
  const [isShowTermsWarning, setIsShowTermsWarning] = useState(false);
  const [isLoadingCreateOrder, setIsLoadingCreateOrder] = useState(false);

  const [nogMintProgress, setNogMintProgress] = useState();
  const [processingOrderId, setProcessingOrderId] = useState();

  const createOrder = async () => {
    setIsLoadingCreateOrder(true);
    log.debug("createOrder...");
    //create order
    let fields = await form.getFieldsValue();
    // log.debug(fields);

    //format
    for (let key in fields) {
      if (fields[key] != null) {
        fields[key] = fields[key].trim();
      }
    }

    let data = {
      buyer: {
        name: fields.name,
        email: auth?.email == null ? fields.email : auth?.email,
        address: auth?.wallet,
        phone: phone,
        twitterId: fields.twitterId,
      },
      sets: items,
      address: {
        street: fields.street,
        apartment: fields.apartment,
        city: fields.city,
        state: fields.state,
        zipcode: fields.zipcode,
        country: fields.country,
      },
    };

    if (paymentMethod == "CARD") {
      //set field 'payment' for server to create paypal order
      data.payment = { method: "paypal", usd: usd };
    } else if (paymentMethod == "ETH") {
      data.totalEth = eth;
      if (referral != null) {
        data.referral = referral;
      }
    } else if (paymentMethod == "NOG") {
      data.totalNOG = nog;
      if (referral != null) {
        data.referral = referral;
      }
    }

    log.debug(data);

    try {
      let res = await axios.post(
        process.env.REACT_APP_SERVER_URL + "api/orders",
        data
      );
      setIsLoadingCreateOrder(false);

      log.debug(res.data);
      log.debug("order created");

      setProcessingOrderId(res.data.id);
      return res.data;
    } catch (error) {
      setIsLoadingCreateOrder(false);
      log.debug(error);
      let customError = new Error();
      customError.message = error?.response?.data?.error?.message;
      customError.data = error?.response?.data?.error?.data;
      throw customError;
    }
  };

  const onTxMined = async (txHash) => {
    let query = `?id=${processingOrderId}`;

    console.log(usd, eth, nog);

    let data =
      paymentMethod == "ETH"
        ? {
            totalEth: eth,
            totalNOG: null,
          }
        : {
            totalEth: null,
            totalNOG: nog,
          };

    let res = await axios.patch(
      process.env.REACT_APP_SERVER_URL + "api/orders" + query,
      data
    );
    log.debug(res.data);

    let fields = await form.getFieldsValue();
    log.debug(fields);

    //format
    for (let key in fields) {
      if (fields[key] != null) {
        fields[key] = fields[key].trim();
      }
    }

    //clear state
    form.resetFields();
    setNogMintProgress();
    setProcessingOrderId();
    setTimeout(() => {
      onClearCart();
    }, 2000);

    history.push({
      pathname: "/mint/success",
      state: { email: fields.email, txHash: txHash },
    });
  };

  const checkCanMint = async () => {
    if (paymentMethod == null) {
      throw new Error("Please select payment");
    }

    if (auth.wallet != null && paymentMethod == "ETH") {
      //balance check
      let balance = await wallet.getBalance();
      balance = parseFloat(balance.displayValue);

      if (eth > balance) {
        throw new Error("Insufficient ETH");
      }
    }

    if (isCheckedTerms == false || isCheckedTerms2 == false) {
      setIsShowTermsWarning(true);
      throw new Error("terms not checked");
    } else if (
      items.every((item) => item.parts.some((part) => part.type == "lense")) ==
      false
    ) {
      throw new Error("should choose free lenses");
    }
  };

  const emailMint = (url) => {
    //redirect to paypal checkout
    window.open(url, "_self");
  };

  const walletMint = async (orderId, sets) => {
    if (orderId == null || sets?.length > 0 == false) {
      throw new Error("function params invalid");
    }

    let noggleQty = 0;
    let frameQty = 0;
    let accessoryQty = 0;
    let lenseQty = 0;

    for (let set of sets) {
      noggleQty++;
      for (let part of set.parts) {
        if (part.type == "frame") {
          frameQty++;
        }
        if (part.type == "accessory") {
          accessoryQty++;
        }
        if (part.type == "lense") {
          lenseQty++;
        }
      }
      //first lense for free for every set
      if (lenseQty > 0) {
        lenseQty--;
      }
    }

    log.debug({ noggleQty, frameQty, accessoryQty, lenseQty });

    let contractFunctionName;
    let contractParams;
    let contractFunctionOptions;

    if (paymentMethod == "ETH") {
      if (whitelist == null && referral == null) {
        //publicMint
        let {
          rate,
          orderId: _orderId,
          signature,
        } = await getPublicMintSignature(orderId);

        // calculate eth using latest rate from signature
        // NOT using rate from UI hook
        let { eth } = await updatePrice({
          target: "order",
          data: items,
          paymentMethod,
          contract,
          whitelist,
          referral,
          config: { ...config, ethusd: rate / 1e8 },
        });

        //update UI by updating "config" in global state
        updateConfig({ ...config, ethusd: rate / 1e8 });

        //
        contractFunctionName = "publicMint";
        contractParams = [
          auth?.wallet,
          {
            noggleQty,
            frameQty,
            clipQty: accessoryQty,
            lenseQty,
          },
          rate,
          _orderId,
          signature,
        ];
        contractFunctionOptions = {
          value: ethers.utils.parseEther(String(eth)),
        };
      } else {
        //discountMint
        let {
          discount,
          rate,
          commission,
          commissionAddr,
          orderId: _orderId,
          blockNumber,
          signature,
        } = await getDiscountMintSignature(orderId);

        // calculate eth using latest rate from signature
        // NOT using rate from UI hook
        let { eth } = await updatePrice({
          target: "order",
          data: items,
          contract,
          paymentMethod,
          whitelist,
          referral,
          config: { ...config, ethusd: rate / 1e8 },
        });
        
        //update UI by updating "config" in global state
        updateConfig({ ...config, ethusd: rate / 1e8 });
        console.log(eth);

        //
        contractFunctionName = "discountMint";
        contractParams = [
          auth?.wallet,
          {
            noggleQty,
            frameQty,
            clipQty: accessoryQty,
            lenseQty,
          },
          discount,
          rate,
          commission,
          commissionAddr,
          _orderId,
          blockNumber,
          signature,
        ];
        contractFunctionOptions = {
          value: ethers.utils.parseEther(String(eth)),
        };
      }
    } else if (paymentMethod == "NOG") {
      setNogMintProgress("approvePending");

      let {
        nog,
        nogusd,
        commission,
        commissionAddr,
        orderId: _orderId,
        blockNumber,
        signature,
      } = await getNOGSignature(usd, orderId);

      //update UI by updating "config" in global state
      updateConfig({ ...config, nogusd });

      // check address has sufficient nog
      let balanceOf = await NOGContract.call("balanceOf", [auth?.wallet]);
      balanceOf = parseFloat(ethers.utils.formatEther(balanceOf));
      console.log(balanceOf);
      if (nog > balanceOf) {
        throw new Error("Insufficient NOGS");
      }

      // check allowance
      let allowance = await NOGContract.call("allowance", [
        auth?.wallet,
        process.env.REACT_APP_CONTRACT_ADDRESS,
      ]);
      allowance = parseFloat(ethers.utils.formatEther(allowance));
      console.log(nog, allowance);

      // approve if needed
      if (nog > allowance) {
        let nogBigNumber = BigNumber.from(nog).mul(BigNumber.from(10).pow(18));
        let approveResult = await NOGContract.call("approve", [
          process.env.REACT_APP_CONTRACT_ADDRESS,
          nogBigNumber,
        ]);
        console.log(approveResult);
      }

      // NOGMint
      contractFunctionName = "NOGMint";
      contractParams = [
        nog,
        auth?.wallet,
        commission,
        commissionAddr,
        _orderId,
        blockNumber,
        signature,
      ];
      setNogMintProgress("mintPending");
    }

    log.debug(contractParams);

    return contract.call(
      contractFunctionName,
      contractParams,
      contractFunctionOptions
    );
  };

  const onMintError = async (error) => {
    onMint(false);
    setNogMintProgress();

    if (error.message == "terms not checked") {
      //do not show error msg
      return;
    } else if (error.message == "should choose free lenses") {
      openNotification("warning", "Please choose your free lenses");
      return;
    } else if (error.reason == "user rejected transaction") {
      log.debug("user rejected transaction");
    }

    let title = error.reason != null ? error.reason : error.message;
    let description = "";
    openNotification("error", title, description);
    log.debug("tx error");
    log.debug(error.reason || error.message);
  };

  const getPublicMintSignature = async (orderId) => {
    try {
      let data = {
        address: auth?.wallet,
        orderId,
      };

      let res = await axios.post(
        process.env.REACT_APP_SERVER_URL + "api/signatures/publicMint",
        data
      );

      log.debug(res.data);
      return res.data;
    } catch (error) {
      log.debug(error);
      let customError = new Error();
      customError.message = error?.response?.data?.error?.message;
      customError.data = error?.response?.data?.error?.data;
      throw customError;
    }
  };

  const getDiscountMintSignature = async (orderId) => {
    try {
      let data = {
        address: auth?.wallet,
        orderId,
      };

      if (whitelist != null) {
        data.whitelist = {
          discount: whitelist.discount,
        };
      } else if (referral != null) {
        data.referral = {
          discount: referral.discount,
          commissionRate: referral.commission,
          commissionAddr: referral.commissionAddr,
          usd: usd / referral.discount,
        };
      }

      let res = await axios.post(
        process.env.REACT_APP_SERVER_URL + "api/signatures/discountMint",
        data
      );

      log.debug(res.data);
      return res.data;
    } catch (error) {
      log.debug(error);
      let customError = new Error();
      customError.message = error?.response?.data?.error?.message;
      customError.data = error?.response?.data?.error?.data;
      throw customError;
    }
  };

  const getNOGSignature = async (usd, orderId) => {
    try {
      let data = {
        address: auth?.wallet,
        usd,
        orderId,
      };

      if (referral != null) {
        data.usd = data.usd / referral.discount;
        data.referral = {
          discount: referral.discount,
          commissionRate: referral.commission,
          commissionAddr: referral.commissionAddr,
        };
      }

      let res = await axios.post(
        process.env.REACT_APP_SERVER_URL + "api/signatures/nogMint",
        data
      );

      log.debug(res.data);
      return res.data;
    } catch (error) {
      log.debug(error);
      let customError = new Error();
      customError.message = error?.response?.data?.error?.message;
      customError.data = error?.response?.data?.error?.data;
      throw customError;
    }
  };

  return (
    <>
      <Drawer
        placement={
          ["lg", "xl", "xxl"].includes(currentSize) ? "right" : "bottom"
        }
        width="45%"
        height="80%"
        onClose={() => {
          setIsShowTermsDrawer(false);
        }}
        open={isShowTermsDrawer}
        key="termsDrawer"
        extra={
          <Space>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setIsShowTermsDrawer(false);
              }}
            >
              OK
            </Button>
          </Space>
        }
      >
        <Row>
          <Row justify="start" style={{ width: "100%" }}>
            <Terms />
          </Row>
        </Row>
      </Drawer>

      <Row
        justify="start"
        align="middle"
        className="ion-padding-vertical"
        style={{
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Col span={24} style={{ fontSize: "1.5rem" }}>
          Your NFT appearance
        </Col>
        <Col span={24} style={{ fontSize: "1rem", color: "gray" }}>
          Upon delivery, your NFT will be revealed as a random 3D MixNoggle
        </Col>

        <Col style={{ marginTop: "1rem" }}>
          <img
            style={{
              border: "1px lightgray solid",
              borderRadius: "0.5rem",
              height: "30vh",
            }}
            src={process.env.REACT_APP_S3_PREFIX + "/other/box-transparent.png"}
          />
        </Col>
      </Row>
      <OrderSummary
        isPending={false}
        sets={items}
        urlReferralCode={urlReferralCode}
        onPreview={(id) => {
          onPreview(id);
        }}
        onShowDrawer={() => onShowDrawer()}
        onApplyReferralCode={onApplyReferralCode}
        onChangePaymentMethod={onChangePaymentMethod}
        openNotification={openNotification}
      />
      <Checkbox
        checked={isCheckedTerms}
        style={{
          fontFamily: "BlinkMacSystemFont, sans-serif",
          marginBottom: "1rem",
        }}
        onChange={(e) => {
          if (e.target.checked) {
            setIsShowTermsWarning(false);
          }
          setIsCheckedTerms(e.target.checked);
        }}
      >
        I agree to
        <span
          style={{
            color: "#1677FF",
            cursor: "pointer",
            textDecoration: "underline",
            marginLeft: "0.5rem",
            fontFamily: "BlinkMacSystemFont, sans-serif",
          }}
          onClick={() => {
            setIsShowTermsDrawer(true);
          }}
        >
          Terms And Condition.
        </span>
      </Checkbox>
      <Checkbox
        checked={isCheckedTerms2}
        style={{
          fontFamily: "BlinkMacSystemFont, sans-serif",
          fontWeight: "bold",
        }}
        onChange={(e) => {
          if (e.target.checked) {
            setIsShowTermsWarning(false);
          }
          setIsCheckedTerms2(e.target.checked);
        }}
      >
        Once an order is placed, the list is confirmed. Only the shipping
        address can be updated later.
      </Checkbox>

      {isShowTermsWarning ? (
        <Row style={{ color: "red" }}>Should accept terms</Row>
      ) : null}

      {paymentMethod == "CARD" ? (
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          style={{
            width: "100%",
            marginTop: "3rem",
            fontSize: "1rem",
            borderRadius: "0.5rem",
            marginTop: "3rem",
            backgroundColor: "black",
            color: "white",
          }}
        >
          <Row
            justify="center"
            style={{
              padding: "1rem",
              width: "100%",
            }}
            onClick={async () => {
              try {
                await checkCanMint();

                let order = await createOrder();

                emailMint(order?.payment?.checkoutURL);
              } catch (error) {
                log.debug(error);
                onMintError(error);
              }
            }}
          >
            {isLoadingCreateOrder ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: "1rem", color: "white" }}
                    spin
                  />
                }
              />
            ) : (
              <>
                place order
                <span
                  style={{
                    fontFamily: "BlinkMacSystemFont, sans-serif",
                    marginLeft: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                >
                  &
                </span>
                mint NFT
              </>
            )}
          </Row>
        </motion.button>
      ) : (
        <MintButton
          nogMintProgress={nogMintProgress}
          onMint={onMint}
          onTxMined={onTxMined}
          onAction={async (contract) => {
            log.debug("action start...");
            await checkCanMint();

            let order = await createOrder();

            return await walletMint(order?.id, items);
          }}
          onMintError={onMintError}
          openNotification={openNotification}
        />
      )}

      <NOGMintProgress nogMintProgress={nogMintProgress} />

      <Row
        style={{
          // textDecoration: "underline",
          cursor: "pointer",
          marginTop: "2rem",
          color: "#1677FF",
          textDecoration: "underline",
          fontSize: "1rem",
          textAlign: "center",
        }}
        onClick={() => {
          let url = process.env.REACT_APP_BRIDGE_URL;
          window.open(url, "_blank", "noopener,noreferrer");
        }}
      >
        not enough eth? go to bridge
      </Row>
      <Row justify="start" style={{ marginTop: "2rem" }}>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          style={{
            backgroundColor: "#f8f8f8",
            color: "gray",
            fontSize: "1.25rem",
            padding: "1.25rem",
            borderRadius: "0.5rem",
            marginRight: "1rem",
          }}
          onClick={() => {
            onBack();
          }}
        >
          Back
        </motion.button>
      </Row>
    </>
  );
};

const NOGMintProgress = ({ nogMintProgress, hideDivider }) => {
  return nogMintProgress != null ? (
    <>
      <Row
        justify="space-evenly"
        align="middle"
        style={{ width: "100%", marginTop: "2rem" }}
      >
        {nogMintProgress == "approvePending" ? (
          <span>
            <span style={{ fontSize: "1.25rem" }}>
              Step
              <span
                style={{
                  color: "#1677FF",
                  margin: "0 1rem 0 1rem",
                }}
              >
                1
              </span>
            </span>
            Approve Tx pending
          </span>
        ) : (
          <span>
            <span style={{ fontSize: "1.25rem" }}>
              Step
              <span
                style={{
                  color: "#1677FF",
                  margin: "0 1rem 0 1rem",
                }}
              >
                2
              </span>
            </span>
            Mint Tx pending
          </span>
        )}
      </Row>
      {hideDivider ? null : <Divider />}
    </>
  ) : null;
};

const PaymentMethod = ({ onChangePaymentMethod }) => {
  const { paymentMethod, config } = useStore();
  return (
    <Row
      className="ion-padding-vertical"
      style={{
        width: "100%",
      }}
    >
      <Row style={{ width: "100%", fontSize: "1.5rem" }}>Payment Method</Row>
      <Row
        style={{
          width: "100%",
          fontSize: "1rem",
          color: "gray",
          marginBottom: "1rem",
        }}
      >
        select one
      </Row>

      <Row
        justify="space-evenly"
        align="middle"
        gutter={[16, 16]}
        style={{ width: "100%" }}
      >
        <PaymentMethodOption
          tokenId={"ETH"}
          imgSrc={`${process.env.REACT_APP_S3_PREFIX}/other/ethIcon.png`}
          paymentMethod={paymentMethod}
          onChangePaymentMethod={onChangePaymentMethod}
        />
        {config.hideNOG == null || config.hideNOG == true ? null : (
          <PaymentMethodOption
            tokenId={"NOG"}
            imgSrc={`${process.env.REACT_APP_S3_PREFIX}/other/nogCoin.png`}
            paymentMethod={paymentMethod}
            onChangePaymentMethod={onChangePaymentMethod}
          />
        )}
        {config.hideEmailLogin == null ||
        config.hideEmailLogin == true ? null : (
          <PaymentMethodOption
            tokenId={"CARD"}
            paymentMethod={paymentMethod}
            onChangePaymentMethod={onChangePaymentMethod}
          />
        )}
        <Col span={11}></Col>
      </Row>
    </Row>
  );
};

const PaymentMethodOption = ({
  tokenId,
  imgSrc,
  paymentMethod,
  onChangePaymentMethod,
}) => {
  const [isHover, setIsHover] = useState(false);
  const [currentSize, BreakPoint] = useBreakPoint();

  return (
    <Col
      span={11}
      style={{
        fontSize: "0.75rem",
        padding: "1rem",
        cursor: "pointer",
        borderRadius: "1rem",
        border:
          paymentMethod === tokenId
            ? "1.5px solid black"
            : isHover
            ? "1px solid gray"
            : "1px solid transparent",
      }}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => {
        onChangePaymentMethod(tokenId);
      }}
    >
      <Row
        justify="space-between"
        align="middle"
        style={{
          fontSize: "1rem",
        }}
      >
        <Col>
          <Row align="middle">
            {tokenId == "CARD" ? (
              <IonIcon
                style={{
                  fontSize: "2rem",
                  marginRight: "0.5rem",
                }}
                icon={cardOutline}
              />
            ) : (
              <>
                <img style={{ width: "2rem", height: "2rem" }} src={imgSrc} />
                <div style={{ margin: "-1rem 0 0 -0.75rem" }}>
                  <BaseSVG />
                </div>
              </>
            )}
            &nbsp;{tokenId == "NOG" ? "NOGS" : tokenId}
          </Row>
        </Col>

        <Col style={{ textAlign: "end" }}>
          {["lg", "xl", "xxl"].includes(currentSize) ? (
            <IonIcon
              style={{
                color: paymentMethod == tokenId ? "black" : "transparent",
                fontSize: "1.5rem",
              }}
              icon={checkmarkCircle}
            />
          ) : null}
        </Col>
      </Row>
    </Col>
  );
};

const MintButton = ({
  onMint,
  onTxMined,
  onAction,
  onMintError,
  openNotification,
}) => {
  const [auth, LoginModal] = useLogin();

  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      style={{
        width: "100%",
        fontSize: "1rem",
        borderRadius: "0.5rem",
        marginTop: "3rem",
      }}
    >
      <Web3Button
        connectWallet={{
          modalTitle: "Welcome to MixNoggle",
          modalTitleIconUrl: "/android-chrome-192x192.png",
          modalSize: "wide",
          welcomeScreen: () => {
            return (
              <div style={{ width: "100%", height: "100%" }}>
                <img
                  src={`${process.env.REACT_APP_S3_PREFIX}/other/walletModel.png`}
                  height="100%"
                  width="100%"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center top",
                  }}
                />
              </div>
            );
          },
        }}
        theme={lightTheme({
          colors: {
            accentText: "#dd55cb",
            accentButtonBg: "#dd55cb",
            separatorLine: "#EEEEEE",
            borderColor: "#EEEEEE",
          },
        })}
        contractAddress={process.env.REACT_APP_CONTRACT_ADDRESS}
        onSubmit={async () => {
          onMint(true);
        }}
        onSuccess={(tx) => {
          onMint(false);
          log.debug("tx success");
          log.debug(tx);

          let txHash = tx?.receipt?.transactionHash;
          if (txHash == null) {
            openNotification("error", "txHash missing", "");
          } else {
            onTxMined(txHash);
          }
        }}
        onError={(error) => {
          onMintError(error);
        }}
        action={async (contract) => {
          return await onAction(contract);
        }}
        style={{
          width: "100%",
          borderRadius: "0.5rem",
        }}
      >
        place order
        <span
          style={{
            fontFamily: "BlinkMacSystemFont, sans-serif",
            marginLeft: "0.5rem",
            marginRight: "0.5rem",
          }}
        >
          &
        </span>
        mint NFT
      </Web3Button>
    </motion.button>
  );
};

const OrderSummaryItem = ({ i, isPending, set, onPreview, onShowDrawer }) => {
  const history = useHistory();

  const { deleteItem, paymentMethod, whitelist, referral, config } = useStore();
  const { contract } = useContract(process.env.REACT_APP_CONTRACT_ADDRESS);
  const [currentSize, BreakPoint] = useBreakPoint();
  const [usd, eth, nog] = usePrice(
    "set",
    set,
    contract,
    paymentMethod,
    whitelist,
    referral,
    config
  );
  const hasAtLeastOneLense = useMemo(() => {
    for (let part of set.parts) {
      if (part.type == "lense") {
        return true;
      }
    }
    return false;
  }, [set]);

  return (
    <>
      <Row
        style={{
          border: "1px solid lightgray",
          borderRadius: "1rem",
          padding: "1rem",
        }}
      >
        <Row
          justify="start"
          align="middle"
          style={{
            width: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "-1rem",
              left: "-1rem",
              backgroundColor: "black",
              color: "white",
              fontSize: "1rem",
              borderRadius: "10px 0 10px 0",
              padding: "0.5rem",
            }}
          >
            set {i + 1}
          </div>

          {isPending ? null : (
            <div
              style={{
                position: "absolute",
                top: "-1rem",
                right: "-1rem",
                backgroundColor: "black",
                color: "white",
                fontSize: "1rem",
                borderRadius: "0 10px 0 10px",
                padding: "0.5rem 1rem 0.5rem 1rem",
                cursor: "pointer",
              }}
              onClick={(event) => {
                event.stopPropagation();
                deleteItem(set.id);
              }}
            >
              X
            </div>
          )}

          <Col span={12}>
            <Row justify="center" align="middle">
              <img
                src={`${process.env.REACT_APP_S3_PREFIX}/noggles/${set?.frame}/${set?.accessory}/${set?.lense}/image.png`}
              />
            </Row>
          </Col>
          <Col
            span={12}
            style={{
              fontSize: "1.25rem",
              textAlign: "end",
            }}
          >
            {`$ ${getDiscountedPrice(
              paymentMethod,
              whitelist?.discount || referral?.discount,
              process.env.REACT_APP_TOKEN_PRICE
            )}`}
          </Col>
        </Row>
        {isPending ? null : (
          <Row style={{ width: "100%" }}>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{
                fontSize: "1rem",
                color: "#55A3FF",
                backgroundColor: "white",
                borderRadius: 1000,
                padding: "0.75rem",
              }}
              onClick={() => {
                history.push({
                  pathname: "/design",
                  state:
                    set == null
                      ? {
                          editStep1: true,
                        }
                      : {
                          editStep1: true,
                          ...set,
                        },
                });

                onPreview(set.id);
              }}
            >
              edit MixNoggle
            </motion.button>
          </Row>
        )}

        {hasAtLeastOneLense == false ? (
          <>
            <Divider />
            <FreeLensePicker
              item={set}
              onSelect={() => {
                onPreview(set.id);
                onShowDrawer();
              }}
            />
          </>
        ) : (
          <>
            <Divider />
            <Row
              justify="space-between"
              align="middle"
              style={{
                width: "100%",
                fontSize: "1rem",
              }}
            >
              Accessories
              {isPending ? null : (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  style={{
                    fontSize: "1rem",
                    backgroundColor: "#55A3FF",
                    color: "white",
                    padding: "0.75rem",
                    borderRadius: "0.5rem",
                    marginLeft: "1rem",
                  }}
                  onClick={() => {
                    history.push({
                      pathname: "/design",
                      state:
                        set == null
                          ? {
                              editStep2: true,
                            }
                          : {
                              editStep2: true,
                              ...set,
                            },
                    });
                    onPreview(set.id);
                  }}
                >
                  add <PlusOutlined />
                </motion.button>
              )}
            </Row>
            {set.parts.map((e, i) => (
              <Row
                key={i}
                justify="space-between"
                align="middle"
                style={{ width: "100%", marginTop: "0.5rem" }}
              >
                <Col>
                  <Row justify="start" align="middle">
                    <Col>
                      <img
                        style={{
                          border: "1px solid lightgray",
                          maxWidth: "7.5rem",
                          borderRadius: "1rem",
                        }}
                        src={`${process.env.REACT_APP_S3_PREFIX}/parts/${e.type}/${e.id}.png`}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col
                  style={{
                    fontSize: "1.25rem",
                  }}
                >
                  {e.type == "frame"
                    ? `$ ${getDiscountedPrice(
                        paymentMethod,
                        whitelist?.discount || referral?.discount,
                        process.env.REACT_APP_FRAME_PRICE
                      )}`
                    : e.type == "lense"
                    ? i == set.parts.findIndex((e) => e.type == "lense")
                      ? "free"
                      : `$ ${getDiscountedPrice(
                          paymentMethod,
                          whitelist?.discount || referral?.discount,
                          process.env.REACT_APP_LENSE_PRICE
                        )}`
                    : e.type == "accessory"
                    ? `$ ${getDiscountedPrice(
                        paymentMethod,
                        whitelist?.discount || referral?.discount,
                        process.env.REACT_APP_ACCESSORY_PRICE
                      )}`
                    : ""}
                </Col>
              </Row>
            ))}
            <Divider style={{ margin: "1rem" }} />
            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%" }}
            >
              <Col
                style={{
                  fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                    ? "1.5rem"
                    : "1rem",
                }}
              >
                Set {i + 1} Total
              </Col>
              <Col>
                <Row
                  justify="end"
                  style={{
                    fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                      ? "1.5rem"
                      : "1.25rem",
                  }}
                >
                  <USDPrice usd={usd} />
                </Row>
                <Row
                  justify="end"
                  style={{
                    fontSize: ["lg", "xl", "xxl"].includes(currentSize)
                      ? "1.25rem"
                      : "1rem",
                    color: "gray",
                  }}
                >
                  <TokenPrice
                    contract={contract}
                    paymentMethod={paymentMethod}
                    eth={eth}
                    nog={nog}
                  />
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Row>
    </>
  );
};

const OrderSummary = ({
  isPending,
  sets,
  urlReferralCode,
  onPreview,
  onShowDrawer,
  onApplyReferralCode,
  onChangePaymentMethod,
  openNotification,
}) => {
  const { contract } = useContract(process.env.REACT_APP_CONTRACT_ADDRESS);
  const { paymentMethod, whitelist, referral, config } = useStore();
  const [usd, eth, nog] = usePrice(
    "order",
    sets,
    contract,
    paymentMethod,
    whitelist,
    referral,
    config
  );
  const summary = useMemo(() => {
    let parts = [];
    let _sets = deepcopy(sets);
    for (let set of _sets || []) {
      let hasSkipFirstLense = false;
      for (let part of set.parts || []) {
        if (hasSkipFirstLense == false && part.type == "lense") {
          //add to summary.sets
          set.freeLense = part.id;
          //NOT add to summary.parts
          hasSkipFirstLense = true;
          continue;
        }

        let vIndex = parts.findIndex(
          (e) => e.type == part.type && e.id == part.id
        );
        if (vIndex == -1) {
          parts.push({ ...part, qty: 1 });
        } else {
          parts[vIndex].qty++;
        }
      }
    }
    log.debug(parts);
    return { sets: _sets, parts };
  }, [sets]);
  const [referralCodeInput, setReferralCodeInput] = useState();
  const [isLoadingReferralCode, setIsLoadingReferralCode] = useState(false);

  useEffect(() => {
    console.log(urlReferralCode);
    if (urlReferralCode != null) {
      //prefill code
      setReferralCodeInput(urlReferralCode);
    }
  }, [urlReferralCode]);

  return (
    <Row className="ion-padding-vertical" style={{ width: "100%" }}>
      <Row style={{ width: "100%", fontSize: "1.5rem" }}>Order Summary</Row>

      {summary?.sets?.length > 0 ? (
        <Row className="ion-padding-vertical">
          {summary.sets.map((set, i) => (
            <OrderSummaryItem
              key={i}
              i={i}
              isPending={isPending}
              set={set}
              onPreview={(id) => onPreview(id)}
              onShowDrawer={() => onShowDrawer()}
            />
          ))}
        </Row>
      ) : null}

      <PaymentMethod onChangePaymentMethod={onChangePaymentMethod} />

      {/* Whitelist */}
      {whitelist != null ? (
        <Row
          className="ion-padding-vertical"
          gutter={[16, 16]}
          style={{ width: "100%", marginTop: "1rem" }}
        >
          {paymentMethod == "CARD" ? (
            <Col span={24}>
              <Row align="middle">
                <span>
                  <IonIcon
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "0.5rem",
                    }}
                    icon={informationCircleOutline}
                  />
                </span>
                <span>
                  Whitelist does not support credit card payment for now
                </span>
              </Row>
            </Col>
          ) : (
            <Col span={24}>
              <Row align="middle">
                <IonIcon
                  style={{
                    color: "green",
                    fontSize: "1.5rem",
                    marginRight: "1rem",
                  }}
                  icon={checkmarkCircle}
                />
                <span
                  style={{
                    fontSize: "1.25rem",
                  }}
                >{`Eligible for Whitelist`}</span>
              </Row>
              enjoy&nbsp;
              <span
                style={{
                  fontSize: "1.25rem",
                  fontWeight: "bold",
                  fontFamily: "BlinkMacSystemFont, sans-serif",
                }}
              >
                {getDisplayDiscount(whitelist?.discount)}% off
              </span>
              &nbsp;discount until&nbsp;
              {moment(whitelist?.time?.end).toISOString().substring(0, 10)}{" "}
              UTC+0
            </Col>
          )}
        </Row>
      ) : null}

      {/* Refferal Code */}
      {whitelist == null ? (
        <Row
          className="ion-padding-vertical"
          gutter={[16, 16]}
          style={{ width: "100%", marginTop: "2rem" }}
        >
          {paymentMethod == "CARD" ? (
            <Col span={24}>
              <Row align="middle">
                <span>
                  <IonIcon
                    style={{
                      fontSize: "1.5rem",
                      marginRight: "0.5rem",
                    }}
                    icon={informationCircleOutline}
                  />
                </span>
                <span>
                  Referral code does not support credit card payment for now
                </span>
              </Row>
            </Col>
          ) : (
            <>
              <Col span={24}>Referral Code</Col>
              <Col span={16} lg={18} style={{ fontSize: "1.5rem" }}>
                <Input
                  size="large"
                  placeholder="Referral Code"
                  maxLength={8}
                  suffix={
                    referral != null ? (
                      <IonIcon
                        style={{
                          color: "green",
                          fontSize: "1.5rem",
                        }}
                        icon={checkmarkCircle}
                      />
                    ) : null
                  }
                  style={{
                    width: "100%",
                    height: "50px",
                    fontFamily: "BlinkMacSystemFont, sans-serif",
                  }}
                  onChange={(e) => {
                    let value = e?.target?.value.toUpperCase();
                    setReferralCodeInput(value);
                  }}
                  value={referralCodeInput}
                />
              </Col>
              <Col
                span={8}
                lg={6}
                style={{ fontSize: "1.5rem", textAlign: "end" }}
              >
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  style={{
                    width: "6rem",
                    height: "50px",
                    fontSize: "1rem",
                    backgroundColor: "#55A3FF",
                    color: "white",
                    padding: "0.75rem",
                    borderRadius: "0.5rem",
                  }}
                  onClick={async () => {
                    setIsLoadingReferralCode(true);
                    let result = await onApplyReferralCode(referralCodeInput);
                    setIsLoadingReferralCode(false);
                    if (result == null) {
                      setReferralCodeInput();
                      openNotification("error", "invalid code");
                    } else {
                      openNotification("success", "applied code");
                    }
                  }}
                >
                  {isLoadingReferralCode ? (
                    <Spin
                      indicator={
                        <LoadingOutlined spin style={{ color: "white" }} />
                      }
                      size="small"
                    />
                  ) : (
                    "Apply"
                  )}
                </motion.button>
              </Col>
              {referral != null ? (
                <Col span={24}>
                  this order enjoy&nbsp;
                  <span
                    style={{
                      fontSize: "1.25rem",
                      fontWeight: "bold",
                      fontFamily: "BlinkMacSystemFont, sans-serif",
                    }}
                  >
                    {getDisplayDiscount(referral?.discount)}% off
                  </span>
                </Col>
              ) : null}
            </>
          )}
        </Row>
      ) : null}

      <Divider style={{ margin: "1rem" }} />

      <Row
        justify="space-between"
        align="middle"
        style={{ marginTop: "2rem", width: "100%" }}
      >
        <Col style={{ fontSize: "1.5rem" }}>Total</Col>
        <Col style={{ fontSize: "1.5rem" }}>
          <Row justify="end" style={{ fontSize: "1.5rem" }}>
            <USDPrice usd={usd} />
          </Row>
          <Row justify="end" style={{ fontSize: "1.25rem", color: "gray" }}>
            <TokenPrice
              contract={contract}
              paymentMethod={paymentMethod}
              eth={eth}
              nog={nog}
            />
          </Row>
        </Col>
      </Row>
      <Row
        justify="end"
        style={{
          marginTop: "0.5rem",
          width: "100%",
          marginBottom: "2rem",
          color: "gray",
        }}
      >
        <Col
          style={{
            fontSize: "1rem",
            fontFamily: "BlinkMacSystemFont, sans-serif",
          }}
        >
          shipping cost is included
        </Col>
      </Row>
    </Row>
  );
};

const Terms = () => {
  return (
    <Typography>
      <Title
        style={{
          marginTop: 0,
          fontFamily: "BlinkMacSystemFont, sans-serif",
        }}
      >
        Terms & Conditions
      </Title>

      <Paragraph style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}>
        PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. BY USING THIS
        SITE, YOU AGREE TO ABIDE BY THESE TERMS AND CONDITIONS, WHICH WILL
        CONSTITUTE OUR AGREEMENT (“AGREEMENT”). THESE TERMS AND CONDITIONS WERE
        LAST UPDATED ON Feb 28, 2024. WE MAY AMEND THESE TERMS AND CONDITIONS
        FROM TIME TO TIME WITH REASONABLE NOTICE TO YOU, INCLUDING WITHOUT
        LIMITATION BY POSTING REVISED TERMS ONLINE. Welcome to the MixNoggle®
        website, which is owned and operated by MixNoggle. (“MixNoggle” or
        “we”). This Agreement describes the terms and conditions applicable to
        the services available through this website along with MixNoggle’s
        applications (the “Site”). This Site is offered to you conditioned upon
        your acceptance without modification of all the terms, conditions, and
        notices set forth below. By accessing or using this Site in any manner,
        you agree to be bound by this Agreement. Please read the Agreement
        carefully. These terms and conditions of use may be changed by MixNoggle
        in the future without further notice. Your continued use of this Site
        after any such changes constitutes your acceptance of the new or
        modified terms. Further, these terms and conditions apply exclusively to
        your access to, and use of, this Site and do not alter in any way the
        terms or conditions of any other agreement you may have with MixNoggle
        for products, services or otherwise.
      </Paragraph>

      <Text strong style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}>
        Privacy Policy
      </Text>
      <Paragraph style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}>
        MixNoggle believes strongly in protecting user privacy. Users of this
        Site should refer to our Privacy Policy for information about how
        MixNoggle uses and collects information. By using our Site, you consent
        to the use of your personal information by MixNoggle in accordance with
        the terms of the Privacy Policy, which terms are incorporated herein.
      </Paragraph>

      <Text strong style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}>
        Information Collection and Use
      </Text>
      <Paragraph style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}>
        MixNoggle collects personal information about you when you: Visit and
        use our Services; Purchase products or services from or through
        MixNoggle; Send or receive email communications to or from MixNoggle; We
        use your personal information to: Provide you with the products or
        services you have requested from us or through our Services.
      </Paragraph>

      <Text strong style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}>
        Sharing and Disclosing Personal Information
      </Text>
      <Paragraph style={{ fontFamily: "BlinkMacSystemFont, sans-serif" }}>
        MixNoggle does not rent or sell personal information about you to third
        parties, however, we reserve the right to share the personal information
        we collect about you through our Services with third parties when we
        have your permission or under the following circumstances: We may also
        provide data to our third party analytics partners, such as Google
        Analytics, for the purposes of tracking usage information. We will share
        your personal information only as necessary for the third party to
        provide that service. To opt-out of Google Analytics use of your
        information, please visit: tools.google.com/dlpage/gaoptout.We may also
        disclose your personal information to: Provide personal information,
        when required by law, in connection with security-related or law
        enforcement investigations; Comply with legal requirements, orders, or
        proceedings; We may transfer, share, or disclose your personal
        information to third parties as part of any corporate reorganization
        process such as a merger, acquisition, and/or a sale of all,
        substantially all, or part of our assets, and/or a licensing agreement
        with third parties. MixNoggle will provide you with notice before your
        personal information becomes subject to a different privacy policy
        unless the privacy policy remains tied to MixNoggle’s own practices;
        Investigate, prevent, or take action regarding illegal activities,
        suspected fraud, situations involving potential threats to the physical
        safety of any person, violations of MixNoggle’s Terms & Conditions, or
        as otherwise required by law; and Perform credit checks, report or
        collect debts owed, or to protect the rights or property of MixNoggle,
        our employees, our members, and our Services.
      </Paragraph>
    </Typography>
  );
};

const CartItem = ({ item, index, currentSize, onPreview, onShowDrawer }) => {
  const history = useHistory();
  const { contract } = useContract(process.env.REACT_APP_CONTRACT_ADDRESS);
  // const [totalPrice, setTotalPrice] = useState("");
  const { whitelist, paymentMethod, referral, config, deleteItem } = useStore();
  const [usd, eth, nog] = usePrice(
    "set",
    item,
    contract,
    paymentMethod,
    whitelist,
    referral,
    config
  );

  const hasAtLeastOneLense = useMemo(() => {
    for (let part of item.parts) {
      if (part.type == "lense") {
        return true;
      }
    }
    return false;
  }, [item]);

  return (
    <Card
      hoverable={hasAtLeastOneLense}
      bordered
      bodyStyle={{
        borderRadius: 10,
        padding: "1rem",
        border: "1px solid gray",
      }}
      onClick={() => {
        if (hasAtLeastOneLense) {
          onPreview();
          onShowDrawer();
        }
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "black",
          color: "white",
          fontSize: "1rem",
          borderRadius: "10px 0 10px 0",
          padding: "0.5rem",
        }}
      >
        set {index + 1}
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          backgroundColor: "black",
          color: "white",
          fontSize: "1rem",
          borderRadius: "0 10px 0 10px",
          padding: "0.5rem 1rem 0.5rem 1rem",
          cursor: "pointer",
        }}
        onClick={(event) => {
          event.stopPropagation();
          deleteItem(item.id);
        }}
      >
        X
      </div>

      <Flex>
        <Flex>
          <img
            src={
              item
                ? `${process.env.REACT_APP_S3_PREFIX}/noggles/${item.frame}/${item.accessory}/${item.lense}/image.png`
                : null
            }
          />
        </Flex>
      </Flex>

      {hasAtLeastOneLense == false ? (
        <FreeLensePicker
          item={item}
          onSelect={() => {
            onPreview();
            onShowDrawer();
          }}
        />
      ) : (
        <>
          <Flex justify="space-between" align="center">
            <span style={{ fontSize: "1rem" }}>
              Accessories:
              <span style={{ fontSize: "1.25rem", marginLeft: "1rem" }}>
                {item.parts.length}
              </span>
            </span>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }} //55A3FF
              style={{
                fontSize: "1rem",
                backgroundColor: "#55A3FF",
                color: "white",
                padding: "0.75rem",
                borderRadius: 1000,
              }}
              onClick={(event) => {
                event.stopPropagation();
                //
                history.push({
                  pathname: "/design",
                  state:
                    item == null
                      ? {
                          editStep2: true,
                        }
                      : {
                          editStep2: true,
                          ...item,
                        },
                });
              }}
            >
              <PlusOutlined />
              {["lg", "xl", "xxl"].includes(currentSize) ? "" : "add"}
            </motion.button>
          </Flex>

          <Flex
            justify="space-between"
            align="center"
            style={{ marginTop: "1rem" }}
          >
            <span style={{ fontSize: "1.25rem" }}>
              <Row justify="end" style={{ fontSize: "1.5rem" }}>
                <USDPrice usd={usd} />
              </Row>
            </span>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }} //55A3FF
              style={{
                fontSize: "1rem",
                backgroundColor: "#55A3FF",
                color: "white",
                padding: "0.75rem",
                borderRadius: 1000,
              }}
            >
              detail
            </motion.button>
          </Flex>
        </>
      )}
    </Card>
  );
};

export default T;
