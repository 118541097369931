import { notification } from "antd";

const useNotification = () => {
  const [notificationAPI, contextHolder] = notification.useNotification();
  const openNotification = (type, title, msg) => {
    notificationAPI[type]({
      message: title,
      description: msg,
      duration: 5,
    });
  };
  return [openNotification, contextHolder];
};

export default useNotification;