import { useState, useMemo, useEffect } from "react";

import {
  //hook
  Web3Button,
  useWallet,
  useAddress,
  //
  lightTheme,
} from "@thirdweb-dev/react";

import { Row, Col, Input, Button, Divider, Form } from "antd";

import useBreakPoint from "./useBreakPoint";
import axios from "axios";

//utils
import log from "../utils/log";
import { isAddressSame, generateOTP } from "../utils/general";

//const
import { signMessage as defaultSignMsg } from "../const";

import { motion } from "framer-motion";

const useVerify = (target, openNotification) => {
  const wallet = useWallet();
  const address = useAddress();
  const [isVerified, setIsVerified] = useState(false);
  const [currentSize, BreakPoint] = useBreakPoint();
  const [otpForm] = Form.useForm();

  const signMessage = async () => {
    if (
      target?.walletAddress == null ||
      isAddressSame(target?.walletAddress, address) == false
    ) {
      openNotification(
        "error",
        "wallet address not match",
        "connect another wallet address and retry"
      );
      return false;
    }
    let signature = await wallet.signMessage(defaultSignMsg(address));
    // log.debug(signature);
    let isValid = await wallet.verifySignature(
      defaultSignMsg(address),
      signature,
      address
    );

    if (!isValid) {
      openNotification("error", "signature invalid");
      return false;
    }

    return true;
  };

  const Verify = () => {
    const [isSentOTP, setIsSentOTP] = useState(false);
    const [otp, setOtp] = useState();

    const sendOTP = async (otp) => {
      try {
        let data = {
          email: target?.email,
          otp,
        };

        //log.debug(data);

        let res = await axios.post(
          process.env.REACT_APP_SERVER_URL + "api/send/OTP/email",
          data
        );

        log.debug(res.data);

        return res.data;
      } catch (error) {
        log.debug(error);
      }
    };

    return (
      <>
        {target == null || isVerified ? null : (
          <Row>
            <Row
              style={{
                width: "100%",
                fontSize: "1.5rem",
                marginBottom: "2rem",
              }}
            >
              Verify Identity
            </Row>

            <Row
              style={{ width: "100%", marginBottom: "0.5rem", color: "gray" }}
            >
              We need to make sure you are the owner of
            </Row>
            <Row
              style={{
                width: "100%",
                marginBottom: "2rem",
                fontSize: ["lg", "xl", "xxl"].includes(currentSize) ? "1.25rem" : "0.75rem",
                fontFamily: "BlinkMacSystemFont, sans-serif",
              }}
            >
              {target?.method == "wallet"
                ? target?.walletAddress
                : target?.email}
            </Row>

            {target?.method == "wallet" ? (
              <>
                <Web3Button
                  connectWallet={{
                    modalTitle: "Welcome to MixNoggle",
                    modalTitleIconUrl: "/android-chrome-192x192.png",
                    modalSize: "wide",
                    welcomeScreen: () => {
                      return (
                        <div style={{ width: "100%", height: "100%" }}>
                          <img
                            src={`${process.env.REACT_APP_S3_PREFIX}/other/walletModel.png`}
                            height="100%"
                            width="100%"
                            style={{
                              objectFit: "cover",
                              objectPosition: "center top",
                            }}
                          />
                        </div>
                      );
                    },
                  }}
                  theme={lightTheme({
                    colors: {
                      accentText: "#dd55cb",
                      accentButtonBg: "#dd55cb",
                      separatorLine: "#EEEEEE",
                      borderColor: "#EEEEEE",
                    },
                  })}
                  contractAddress={process.env.REACT_APP_CONTRACT_ADDRESS}
                  onSuccess={(pass) => {
                    setIsVerified(pass);
                  }}
                  onError={(error) => {
                    log.debug(error);
                  }}
                  action={async (contract) => {
                    return await signMessage();
                  }}
                  style={{
                    width: "100%",
                    borderRadius: "0.5rem",
                  }}
                >
                  Sign Message
                </Web3Button>

                <Button
                  size="large"
                  style={{ width: "100%", marginTop: "1rem" }}
                  onClick={() => {
                    wallet.disconnect();
                  }}
                >
                  Discount wallet
                </Button>
              </>
            ) : (
              <>
                <Row
                  justify="start"
                  style={{ width: "100%", marginBottom: "2rem" }}
                >
                  <Row>
                    <Col span={24}>
                      <Form form={otpForm}>
                        <Form.Item
                          name={["otp"]}
                          rules={[
                            {
                              validator: (_, value) => {
                                if (!value || value.length !== 6) {
                                  return Promise.reject(
                                    new Error("OTP must be 6 digit")
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Row gutter={[16, 16]}>
                            <Col>
                              <Input
                                size="large"
                                placeholder="Enter 6 digit OTP"
                                maxLength={6}
                                style={{
                                  width: "100%",
                                  fontFamily: "BlinkMacSystemFont, sans-serif",
                                }}
                              />
                            </Col>
                            <Col>
                              {isSentOTP ? (
                                "Sent! please check email for OTP"
                              ) : (
                                <motion.button
                                  whileHover={{ scale: 1.05 }}
                                  whileTap={{ scale: 0.95 }}
                                  style={{
                                    width: "100%",
                                    backgroundColor: "white",
                                    border: "1px solid gray",
                                    padding: "0.75rem 3rem 0.75rem 3rem",
                                    borderRadius: "0.5rem",
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    let _otp = generateOTP();
                                    setOtp(_otp);
                                    sendOTP(_otp);
                                    setIsSentOTP(true);
                                  }}
                                >
                                  send OTP
                                </motion.button>
                              )}
                            </Col>
                          </Row>
                        </Form.Item>
                      </Form>
                    </Col>
                  </Row>
                </Row>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  style={{
                    width: "100%",
                    backgroundColor: "black",
                    color: "white",
                    padding: "1rem 3rem 1rem 3rem",
                    borderRadius: "0.5rem",
                    fontSize: "1rem",
                  }}
                  onClick={async () => {
                    try {
                      await otpForm.validateFields();
                      let fields = await otpForm.getFieldsValue();
                      if (fields.otp == otp) {
                        setIsVerified(true);
                      } else {
                        openNotification("error", "Invalid OTP");
                      }
                      otpForm.resetFields();
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  Verify
                </motion.button>
              </>
            )}
          </Row>
        )}
      </>
    );
  };

  return [isVerified, Verify];
};

export default useVerify;
