import log from 'loglevel';

const env = process.env.REACT_APP_NODE_ENV || "dev";
console.log(process.env.REACT_APP_NODE_ENV)

// Decide logging level
// There are 5 levels, from lowest to highest: trace debug info warn error
// e.g., trace can view: trace debug info warn error
// e.g., info can view: info warn error

if (["dev", "uat"].includes(env)) {
  // Development and UAT use trace level
  log.setLevel("TRACE");
} else {
  // Default and production use warn level
  log.setLevel("WARN");
}

export default log;